import React, { useState } from 'react';
import STYLE from 'src/constants/style';
import {
  Radio, Button, toast, Base, Placeholder
} from 'src/components';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useCompany, updateCompanyQuery } from 'src/queries/company';
import { get } from 'lodash';

const { DIRECT_REVIEW_PERMISSIONS } = COMMON_CONSTANTS;

const defaultState = {
  directReviewPermissions: false,
  newDirectReviewPermissions: false
};

const DirectReviewSettings = () => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch
  } = useCompany();
  const { mutateAsync: updateCompany, isLoading: updateSettingsLoading } = updateCompanyQuery();
  const [state, setState] = useState({
    directReviewPermissions: get(company, 'settings.directReviewPermissions', defaultState.directReviewPermissions),
    newDirectReviewPermissions: get(company, 'settings.directReviewPermissions', defaultState.directReviewPermissions)
  });

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError && !updateSettingsLoading;

  if (!isReady) {
    return <Placeholder />;
  }

  const updateDirectReviewPermissions = async () => {
    const res = await updateCompany({
      settings: {
        ...company.settings,
        directReviewPermissions: state.newDirectReviewPermissions
      }
    });
    refetch();
    if (!res.success) {
      return toast.error(
        "We've encountered an issue. Please try again or contact us."
      );
    }
    toast.show('Updated!');
  };

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <h5 className='mb-2'>Direct Reviews</h5>
      <p className='mb-4'>
        Determines the ability of team members to review others directly in
        WorkStory
      </p>
      <div className='mb-4'>
        <Radio
          title='Can review everyone'
          checked={
            state.newDirectReviewPermissions === DIRECT_REVIEW_PERMISSIONS.OPEN
          }
          name='directReviewPermissions'
          onChange={(e) => {
            setState({
              ...state,
              newDirectReviewPermissions: DIRECT_REVIEW_PERMISSIONS.OPEN
            });
          }}
        />
      </div>

      <div className='mb-4'>
        <Radio
          title='Limited to review relationship rules'
          checked={
            state.newDirectReviewPermissions
            === DIRECT_REVIEW_PERMISSIONS.PER_REVIEW_RELATIONSHIPS
          }
          name='directReviewPermissions'
          onChange={(e) => {
            setState({
              ...state,
              newDirectReviewPermissions:
                DIRECT_REVIEW_PERMISSIONS.PER_REVIEW_RELATIONSHIPS
            });
          }}
        />
      </div>
      <div className='mb-1'>
        <Button
          variant='yellow'
          disabled={
            state.directReviewPermissions === state.newDirectReviewPermissions
          }
          onClick={updateDirectReviewPermissions}
        >
          Update
        </Button>
      </div>
    </Base>
  );
};

export default DirectReviewSettings;
