import React from 'react';
import commonDateUtils from 'common/commonDateUtils';
import CircleV2 from 'src/components/Circle/CircleV2';
import DOMPurify from 'dompurify';

const MobileFeedback = ({ feedback }) => {
  const userImage = feedback.revieweeImage && feedback.revieweeImage.imageUrl
    ? feedback.revieweeImage.imageUrl
    : '';
  return (
    <div className='text-left px-2 mb-2 mobile-border mb-5'>
      <div className='flex gap-4 mb-2'>
        <CircleV2 imageUrl={userImage} size='sm' />
        <div className='flex flex-col w-full justify-center align-middle'>
          <p className='bold mb-0'>{feedback.revieweeName}</p>
          <p className='mb-0 leading-4 text-sm'>
            {commonDateUtils.unixToMonthDayYearFormat(feedback.date).toString()}
          </p>
        </div>
      </div>
      <p
        className='mb-1 leading-4 text-sm mobile-paragraph'
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(feedback.text)
        }}
      />
    </div>
  );
};

export default MobileFeedback;
