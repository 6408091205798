import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { update, disconnectCommunicationQuery } from 'src/queries/user';
import {
  Input, Button, toast, Base
} from 'src/components/';
import COMMON_CONSTANTS from 'common/commonConstants';
import msTeamsUtils from 'src/containers/Settings/MSTeams/msTeamsUtils';
import STYLE from 'src/constants/style';
import { useAccount } from 'src/queries/account';

const { COMMUNICATION_TYPES } = COMMON_CONSTANTS;

const UserConnected = () => {
  let refetchAccountInterval;
  const {
    data: account,
    refetch: refetchAccount,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount(
    'me',
    {},
    {
      isUserConnected: true
    }
  );

  const isFetching = isFetchingAccount && !refetchAccountInterval;
  const isError = isErrorAccount;
  const isReady = account && account._id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const [editEmail, setEditEmail] = useState(false);
  const [msTeamsEmail, setMsTeamsEmail] = useState(account?.msTeamsEmail);
  const { mutateAsync: disconnectCommunication } = disconnectCommunicationQuery();
  const { mutateAsync: updateUser, isLoading: isUpdatingUser } = update();

  useEffect(() => {
    if (!isFetchingAccount && !editEmail) {
      setMsTeamsEmail(account?.msTeamsEmail);
    }
  }, [isFetchingAccount, editEmail, account]);

  const toggleEditEmail = () => {
    if (refetchAccountInterval) {
      clearInterval(refetchAccountInterval);
    }
    setEditEmail(!editEmail);
    if (!editEmail) {
      setMsTeamsEmail('');
      return;
    }
    setMsTeamsEmail(account.msTeamsEmail);
  };

  const saveMsTeamsEmail = async () => {
    await disconnectCommunication({
      communication: COMMUNICATION_TYPES.MS_TEAMS
    });
    const userUpdated = await updateUser({
      id: account._id,
      msTeamsEmail: msTeamsEmail.trim()
    });

    if (!userUpdated.success) {
      toast.error(userUpdated.error);
      return false;
    }

    toggleEditEmail();
    refetchAccount();
  };

  const openMsTeams = () => {
    window.open(
      msTeamsUtils.getMsTeamsOpenLink(),
      '_blank',
      'noopener noreferrer'
    );

    refetchAccountInterval = setInterval(() => {
      refetchAccount();
    }, 2500);
    setTimeout(() => {
      if (!refetchAccountInterval) {
        return;
      }
      clearInterval(refetchAccountInterval);
    }, 60000);
  };

  return (
    <Base
      variant='transparent'
      classes={`${STYLE.CONTAINER_WHITE} items-start`}
    >
      <p className='mb-4'>Setup your Microsoft Teams integration:</p>
      <div className='flex items-center'>
        <Input
          title=''
          placeholder='Microsoft Teams Email'
          onChange={(e) => setMsTeamsEmail(e.target.value)}
          value={msTeamsEmail}
          autocomplete='false'
          disabled={!editEmail}
        />
        <button
          type='button'
          className='ml-4 mb-auto mt-2'
          onClick={() => toggleEditEmail()}
        >
          {editEmail ? 'Cancel' : 'Edit'}
        </button>
      </div>

      {editEmail && (
        <Button
          classes='mb-3'
          variant='yellow'
          onClick={saveMsTeamsEmail}
          disabled={isUpdatingUser}
        >
          Next
        </Button>
      )}

      {!editEmail && !account.msTeamsAuth && (
        <Fragment>
          <p className='mb-2 text-lg'>
            <strong>Microsoft Teams Classic</strong>
          </p>
          <div className='mb-8'>
            <ul className='mb-0'>
              <li>
                1.
                {' '}
                <button
                  onClick={openMsTeams}
                  className='hover:text-purple focus:outline-none underline'
                >
                  <strong>Click</strong>
                  {' '}
                  to open Microsoft Teams Classic
                </button>
                {' '}
              </li>
              <li>
                2. Send a
                {' '}
                <strong>Hello</strong>
                {' '}
                message
              </li>
              <li>
                3.
                {' '}
                <strong>Refresh</strong>
                {' '}
                WorkStory webpage
              </li>
            </ul>
          </div>
          <p className='mb-2 text-lg'>
            <strong>Microsoft Teams (school or work)</strong>
          </p>
          <div className='mb-2'>
            <ul className='mb-0'>
              <li>1. Open Microsoft Teams (school or work) application</li>
              {' '}
              <li>
                2. Go to tab
                {' '}
                <strong>Apps</strong>
              </li>
              {' '}
              <li>
                3. Search for
                {' '}
                <strong>WorkStory</strong>
              </li>
              {' '}
              <li>
                4. On the right side, you should see a
                {' '}
                <strong>WorkStory</strong>
                {' '}
                box
              </li>
              {' '}
              <li>
                5. Click
                {' '}
                <strong>WorkStory</strong>
                {' '}
                box
              </li>
              {' '}
              <li>
                6. A
                {' '}
                <strong>modal</strong>
                {' '}
                will open, click the button
                {' '}
                <strong>Open</strong>
                {' '}
                or
                <strong>Add</strong>
                {' '}
              </li>
              {' '}
              <li>
                7. A
                {' '}
                <strong>chat window</strong>
                {' '}
                will open, send a
                {' '}
                <strong>Hello</strong>
                {' '}
                message
              </li>
              <li>
                8.
                {' '}
                <strong>Refresh</strong>
                {' '}
                WorkStory webpage
              </li>
              {' '}
            </ul>
          </div>
        </Fragment>
      )}
    </Base>
  );
};

export default UserConnected;
