import { Fragment, h } from 'preact';
import { appUtils, Base } from 'src/components/index';
import { CircleItems } from 'src/componentsTailwind/index';
import STYLE from 'src/constants/style';
import {
  ITEM_TYPES,
  INPUT_ACTION_TYPES
} from 'src/componentsTailwind/CircleItems/CircleItems';
import {
  giveFeedbackQuery,
  QUERY_KEYS as FEEDBACK_QUERY_KEYS
} from 'src/queries/feedback';
import { sharedReviewUtils } from 'src/common/index';
import { useCompany } from 'src/queries/company';
import { get, upperFirst } from 'lodash';
import { useState } from 'preact/hooks';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useQueryClient } from 'react-query';
import ThreeDotsBubbleSVG from 'src/assets/svg/three-dots-bubble.svg';
import CircledIcon from 'src/components/CircledIcon/CircledIcon';
import commonDateUtils from 'common/commonDateUtils';
import LabeledToggle from 'src/components/LabeledToggle/LabeledToggle';
import FeedbackFlyout from 'src/containers/UserProfile/FeedbackDashboard/components/FeedbackFlyout';

const { FEEDBACK_TYPE, SHARE_REVIEW_WITH } = COMMON_CONSTANTS;

const FeedbackFeed = ({
  feedbacks = [],
  isLoading = false,
  reviewee = {
    id: null
  },
  showToggle = false,
  viewerId = null,
  setViewerId = () => {},
  renderPagination = () => {}
}) => {
  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = loggedUser._id || loggedUser.id;
  const queryClient = useQueryClient();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;
  if (!isReady) {
    return null;
  }

  const [flyoutFeedback, setFlyoutFeedback] = useState(feedbacks[0]);
  const [isFeedbackFlyoutOpen, setIsFeedbackFlyoutOpen] = useState(false);

  const openFeedbackFlyout = (feedbackId) => {
    setFlyoutFeedback(() => {
      const feedback = feedbacks.find(
        (f) => f._id === feedbackId || f.id === feedbackId
      );
      if (feedback) {
        setIsFeedbackFlyoutOpen(true);
        return feedback;
      }
    });
  };

  const reviewVisibilityDefaultOption = get(
    company,
    'settings.reviewVisibilityDefaultOption',
    SHARE_REVIEW_WITH.ANONYMOUS
  );
  const reviewVisibilitySettings = get(
    company,
    'settings.reviewVisibilityOptions',
    {}
  );

  const { mutateAsync: giveFeedback, isLoading: isLoadingGiveFeedback } = giveFeedbackQuery();
  const [feedback, setFeedback] = useState({
    revieweeId: reviewee.id,
    text: '',
    visible: reviewVisibilityDefaultOption,
    type: FEEDBACK_TYPE.FEEDBACK
  });

  const visibilityOptions = sharedReviewUtils.getShareCommentOptions(
    reviewVisibilitySettings,
    sharedReviewUtils
      .renderShareCommentsVisibilityOptions(reviewee.name || 'this person')
      .map((o) => {
        const option = o;
        option.label = upperFirst(option.title);
        delete option.title;
        option.underlabel = option.comments;
        delete option.comments;
        return option;
      })
  );

  const isSelf = reviewee.id === loggedUserId;

  const selectTitle = visibilityOptions.find((o) => o.value === feedback.visible).label
    || 'Choose Visibility';

  const getFeedbackItems = () => {
    if (feedbacks.length) {
      return feedbacks.map((f) => ({
        type: ITEM_TYPES.TEXT,
        label: f.reviewerName,
        underlabel: (
          <span className='w-full flex justify-between items-center'>
            <p className='m-0 whitespace-nowrap'>{`Shared ${commonDateUtils.dateToTimeAgo(f.createdDate || f.reviewedDate)}`}</p>
            <button
              onClick={() => openFeedbackFlyout(f._id || f.id)}
              className='text-purple focus:outline-none whitespace-nowrap'
            >
              View Details
            </button>
          </span>
        ),
        text: f.text,
        imageSrc: appUtils.getProfilePictureSrc(f.reviewerImage)
      }));
    }

    if (!isLoading) {
      return [
        {
          type: ITEM_TYPES.TEXT,
          label: 'WorkStory',
          underlabel: <p className='italic'>Nothing here yet!</p>,
          text: `<p className="italic">Once ${reviewee.name || 'this person'} receives applicable feedback, it will be displayed here.</p>`,
          imageSrc: appUtils.getProfilePictureSrc()
        }
      ];
    }

    return [];
  };
  const items = [
    {
      type: ITEM_TYPES.INPUT,
      imageSrc: appUtils.getProfilePictureSrc(loggedUser.imageUrl),
      value: feedback.text,
      placeholder: 'Write your feedback here',
      disabled: isLoadingGiveFeedback,
      onChange: (value) => {
        setFeedback({
          ...feedback,
          text: value
        });
      },
      actions: [
        {
          type: INPUT_ACTION_TYPES.SELECT,
          label: selectTitle,
          options: visibilityOptions,
          disabled: isLoadingGiveFeedback,
          onChange: ({ value }) => {
            setFeedback({
              ...feedback,
              visible: value
            });
          }
        },
        {
          type: INPUT_ACTION_TYPES.SUBMIT,
          label: 'Submit',
          disabled:
            !feedback.text
            || feedback.text.length < 10
            || isLoadingGiveFeedback,
          onClick: async () => {
            await giveFeedback(feedback);

            queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK);
            queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK_FEED);
            queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK_COUNT);

            setFeedback({
              ...feedback,
              text: ''
            });
          }
        }
      ]
    },
    ...getFeedbackItems()
  ];

  return (
    <Fragment>
      <FeedbackFlyout
        width={40}
        feedback={flyoutFeedback}
        isOpen={isFeedbackFlyoutOpen}
        setIsOpen={setIsFeedbackFlyoutOpen}
        showXButton={false}
      />
      <Base
        classes={`${STYLE.CONTAINER_WHITE} !p-4`}
        loading={isLoading}
        relative
      >
        <CircledIcon
          svg={<ThreeDotsBubbleSVG className='w-4 h-4 text-purple' />}
        />
        <p className='text-xl text-black font-bold mb-0 mr-8'>Feedback Feed</p>
        <p className='m-0 text-sm font-normal mt-2 mb-4 leading-5 text-bluish-gray'>
          Real-time list of feedback comments shared for
          {isSelf ? ' you ' : ` ${reviewee.name || 'this person'} `}
          from performance reviews and feedback submissions.
        </p>
        {showToggle ? (
          <LabeledToggle
            labels={[
              {
                id: reviewee.id,
                text: `${reviewee.name}'s View`
              },
              {
                id: null,
                text: `Manager View`
              }
            ]}
            selectedId={viewerId}
            onChange={setViewerId}
          />
        ) : null}
        <div className='border-b border-mid-gray my-3 transform -translate-x-[16px] w-[calc(100%+32px)]' />
        <CircleItems items={items} />
        {renderPagination()}
      </Base>
    </Fragment>
  );
};

export default FeedbackFeed;
