import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { get } from 'lodash';
import { useAccount } from 'src/queries/account';
import { useCompany } from 'src/queries/company';
import {
  Base,
  ReviewAiSuggestions,
  Circle,
  Radio,
  Button,
  toast
} from 'src/components';
import FeedbackQuill from 'src/components/Quill/FeedbackQuill';
import { formatNoteText } from 'src/pagesDashboard/NewUserReport/utils';
import commonReviewUtils from 'common/commonReviewUtils';
import appUtils from 'src/components/appUtils';
import STYLE from 'src/constants/style';
import MobileFeedbackForm from 'src/components/FeedbackForm/mobile/FeedbackForm';

import { sharedReviewUtils } from 'src/common';

const FeedbackForm = ({ revieweeId, loading, submit }) => {
  const { data: revieweeAccount } = useAccount(
    revieweeId,
    {},
    { staleTime: Infinity, cacheTime: Infinity }
  );

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const loggedUserId = appUtils.getLoggedUserId();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;
  if (!isReady) {
    return null;
  }

  const companySettings = get(company, 'settings');
  const reviewVisibilitySettings = get(
    company,
    'settings.reviewVisibilityOptions',
    {}
  );

  const [shareCommentOptions, setShareCommentOptions] = useState([]);

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    defaultValues: {
      feedbackText: '',
      commentVisible: companySettings.reviewVisibilityDefaultOption
    }
  });

  const saveFeedback = async (formValues) => {
    try {
      const { feedbackText, commentVisible } = formValues;

      if (!company.active) {
        return toast.error('Company is not active.');
      }

      await submit({ text: feedbackText, visible: commentVisible });
    } catch (error) {
      toast.error(error);
      return false;
    }
  };

  const {
    name: revieweeName,
    imageUrl: revieweeImageUrl,
    title: revieweeTitle
  } = revieweeAccount;

  const feedbackText = watch('feedbackText');

  useEffect(() => {
    if (
      companySettings
      && revieweeName
      && Object.values(reviewVisibilitySettings).length > 0
    ) {
      const visibilityOptions = sharedReviewUtils.renderShareCommentsVisibilityOptions(revieweeName);

      const options = sharedReviewUtils.getShareCommentOptions(
        reviewVisibilitySettings,
        visibilityOptions
      );

      setShareCommentOptions(options);
    }
  }, [companySettings, revieweeName, reviewVisibilitySettings]);

  const isMobile = appUtils.getMobileSize();

  return (
    <div>
      {isMobile ? (
        <form onSubmit={handleSubmit(saveFeedback)} className='mt-0'>
          <MobileFeedbackForm
            control={control}
            formatNoteText={formatNoteText}
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </form>
      ) : (
        <Base classes={STYLE.CONTAINER_WHITE_PADDINGLESS} loading={loading}>
          <form
            className='flex flex-col items-center'
            onSubmit={handleSubmit(saveFeedback)}
          >
            <div>
              <div className='h-32 -mb-18 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-t-md w-0' />
              <div className='mb-10 pb-6 flex flex-col items-center'>
                <Circle
                  size='xxl'
                  imageUrl={revieweeImageUrl}
                  classes='mb-4 user-setting-profile-image relative h-131 w-131'
                />

                <div className='text-center'>
                  <div className='text-2xl bold'>{revieweeName}</div>
                  <div className='text-base text-gray-400'>{revieweeTitle}</div>
                </div>
              </div>
            </div>

            <div className='mx-auto w-5/6'>
              <div className='mb-3 flex flex-col md:flex-row'>
                <div className='text-gray-500 mb-2 md:w-2/5'>
                  <div className='uppercase font-bold text-xl mb-1'>
                    Feedback
                  </div>
                  <div className='text-base pr-6'>
                    Give an example of what you think
                    {' '}
                    <br />
                    (máx: 2000 characters)
                  </div>
                </div>
                <div className='flex flex-col md:w-3/5 '>
                  <Controller
                    name='feedbackText'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message:
                          'Please provide a longer comment to answer this question.'
                      },
                      validate: (value) => {
                        // min text length === 10
                        if (!value) return false;
                        const plainText = commonReviewUtils.getPlainTextFromHTML(value);
                        const plainTextWithNoSpaces = plainText.replaceAll(
                          ' ',
                          ''
                        );
                        if (plainTextWithNoSpaces.length < 10) return false;
                        return true;
                      }
                    }}
                    render={({ field }) => (
                      <FeedbackQuill
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(formatNoteText(value));
                        }}
                      />
                    )}
                  />
                  <div className='text-red whitespace-nowrap flex items-center'>
                    {errors?.feedbackText?.message}
                  </div>
                </div>
              </div>

              <ReviewAiSuggestions
                comments={feedbackText}
                userId={revieweeId}
                revieweeName={revieweeName}
                companyid={company.id}
                reviewerId={loggedUserId}
              />

              <div className='flex flex-col mb-4 md:flex-row'>
                <div className='uppercase text-gray-500 font-bold text-xl mb-2 md:w-2/5'>
                  Comment visibility
                </div>
                <div className='md:w-3/5'>
                  {shareCommentOptions.length > 1
                    ? shareCommentOptions.map((option) => (
                      <div className='font-semibold' key={option.title}>
                        <Controller
                          name='commentVisible'
                          control={control}
                          rules={{ required: false }}
                          render={({ field: { value, onChange } }) => (
                            <Radio
                              name='commentVisible'
                              value={value}
                              title={option.title}
                              checked={
                                  !value
                                    ? option.value
                                      === companySettings.reviewVisibilityDefaultOption
                                    : value === option.value
                                }
                              onChange={() => onChange(option.value)}
                            />
                          )}
                        />
                        <div>
                          <p className='text-dark-grey'>{option.comments}</p>
                        </div>
                      </div>
                    ))
                    : shareCommentOptions.length && (
                    <>
                      <p className='fontSize16 mb-0 pd-0 bold'>
                        {shareCommentOptions[0].title}
                      </p>

                      <div className='ml-0'>
                        <p className='mb-0 text-dark-grey'>
                          {shareCommentOptions[0].comments}
                        </p>
                      </div>
                    </>
                    )}
                </div>
              </div>
              <div className='flex w-full justify-end pb-8'>
                <Button
                  classes='w-32'
                  disabled={!isValid || isSubmitting}
                  variant='yellow'
                  type='submit'
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </Base>
      )}
    </div>
  );
};

export default FeedbackForm;
