import React from 'react';

const PreviewCategory = ({ review, category }) => {
  const { answer, comments } = review;
  const { name } = category;

  return (
    <div className='mb-4'>
      <h3 className='text-lg font-bold text-purple'>{name}</h3>
      <div className='w-full flex py-1 flex-col justify-center text-left border border-slate-300 px-6 bg-gray-200 min-h-16'>
        <span className='text-sm'>
          <strong>{answer.label}</strong>
          {comments ? ` - ${comments}` : ''}
        </span>
      </div>
    </div>
  );
};

export default PreviewCategory;
