import React, { useState } from 'react';
import { uniqBy } from 'lodash';
import { appUtils } from 'src/components';
import GeneralFilter from 'src/containers/UserProfile/FilterView/GeneralFilter';
import FilterByDate from 'src/containers/UserProfile/FilterView/FilterByDate';
import { useCompanyAsDataset } from 'src/queries/company';
import { useBuilderDataset } from 'src/queries/builder';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonDateUtils from 'common/commonDateUtils';

const { DEFAULT_ROLE } = COMMON_CONSTANTS;

const BuilderDashboardFilters = ({
  filters,
  setFilters,
  individualClasses = 'w-60'
}) => {
  const { roleIds } = filters;

  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = appUtils.getLoggedUserId();
  const { companyid } = loggedUser;

  const {
    data: builderDataset,
    isFetching: isFetchingBuilderDataset,
    isError: isErrorBuilderDataset
  } = useBuilderDataset();

  const {
    data: companyData,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompanyAsDataset(companyid, loggedUserId);

  const [roleSearchText, setRoleSearchText] = useState('');

  const isFetching = isFetchingCompany || isFetchingBuilderDataset;
  const isError = isErrorCompany || isErrorBuilderDataset;
  const isReady = !isFetching && !isError;

  if (!isReady) return null;

  const roleOptions = uniqBy(
    [...companyData.dataset, ...builderDataset]
      .map((industry) => industry.roles.map(({ roleId, name }) => ({
        id: roleId,
        label: name,
        classes: industry.industryId === companyid ? '!underline' : '',
        checked: roleIds.includes(roleId),
        disabled: roleId === DEFAULT_ROLE.id
      })))
      .flat()
      .concat({
        id: 'null',
        label: 'N/A',
        classes: '',
        checked: roleIds.includes('null'),
        disabled: false
      })
      .filter((role) => role.label.toLowerCase().includes(roleSearchText.toLowerCase()))
      .sort((a, b) => (a.label > b.label ? 1 : -1)),
    'id'
  );

  const getTitle = (field) => {
    if (field === 'roles') {
      const count = roleIds.length;
      return count ? `${count} roles selected` : 'Filter by Roles';
    }
  };

  return (
    <div className='flex justify-between gap-2 mt-1 mb-2'>
      <div className='flex flex-wrap full:flex-nowrap justify-end gap-2 w-full'>
        <div className='tooltip relative'>
          <span className='tooltip-text whitespace-nowrap bg-black text-white -top-[35px]'>
            Underlined options are available in your company
          </span>
          <GeneralFilter
            multiselect
            wrapperClasses={individualClasses}
            title={getTitle('roles')}
            options={roleOptions}
            onChange={(option) => {
              setFilters({
                ...filters,
                roleIds: option.checked
                  ? roleIds.filter((id) => id !== option.id)
                  : [...roleIds, option.id]
              });
            }}
            onClear={() => {
              setFilters({
                ...filters,
                roleIds: []
              });
            }}
            onSearch={(value) => setRoleSearchText(value)}
            onDropdownClose={() => setRoleSearchText('')}
          />
        </div>
        <FilterByDate
          classes={individualClasses}
          dateRangeSelected={filters.createdDate}
          onSelectDateRange={(value, start, end) => {
            const startValue = commonDateUtils.getFirstUnixOfDateFromUnix(
              start || commonDateUtils.dateToUnix('2020-01-01')
            );
            const endValue = commonDateUtils.getFirstUnixOfDateFromUnix(
              end || commonDateUtils.dateToUnix(new Date())
            );

            const isCustom = value === 'custom';
            setFilters({
              ...filters,
              createdDate: {
                value,
                start: startValue,
                ...(isCustom && { end: endValue })
              }
            });
          }}
        />
      </div>
    </div>
  );
};

export default BuilderDashboardFilters;
