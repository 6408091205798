import React, { useContext } from 'react';
import { isNil } from 'lodash';
import Category from 'src/pages/PerformanceBuilder/BuilderComponents/Category';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';

const PerformanceCategories = () => {
  const {
    data: { dataset, reviewMode, prevDataRef }
  } = useContext(PerformanceBuilderContext);

  const prevData = prevDataRef.current;
  const { industry, role } = prevData;

  const industryData = dataset.find((data) => data.industryId === industry.id);
  const roleData = industryData.roles.find((data) => data.roleId === role.id);
  let { categories } = roleData;

  // specific order for the categories
  if (role.id === '70196e3b-6e41-4521-883b-70823b9a6e14') {
    const sortedCategories = [
      categories[10],
      categories[0],
      categories[11],
      categories[1],
      categories[2],
      categories[12],
      categories[3],
      categories[4],
      categories[5],
      categories[6],
      categories[7],
      categories[8],
      categories[9]
    ].filter(category => !isNil(category));
    categories = sortedCategories;
  }

  return (
    <>
      <div
        id='performance-data-section'
        className='mb-8 border-b rounded border-[#E2E8F0]'
      />
      <h2>Performance Categories</h2>
      {categories.map((category, index) => (
        <Category
          category={category}
          nextCategoryId={categories[index + 1]?.categoryId || null}
          reviewMode={reviewMode}
          key={category.categoryId}
        />
      ))}
    </>
  );
};

export default PerformanceCategories;
