import React, { useEffect } from 'react';
import { Base, ConnectToSlack, toast } from 'src/components/';
import { useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';
import COMMON_CONSTANTS from 'common/commonConstants';
import { connectSlackQuery } from 'src/queries/user';
import STYLE from 'src/constants/style';
import { useAccount } from 'src/queries/account';
import { useSearchParams } from 'react-router-dom';
import { get } from 'lodash';

const { SLACK_REDIRECT_URI } = COMMON_CONSTANTS;

const SlackIntegration = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount,
    refetch: refetchAccount
  } = useAccount('me');
  const { mutateAsync: connectSlack, isLoading: connectSlackLoading } = connectSlackQuery();

  const handleSlackAuth = async ({ code, state }) => {
    toast.show('Connecting to Slack, please wait');
    const redirectUrl = SLACK_REDIRECT_URI.USER_SETTINGS;

    const result = await connectSlack({ code, state, redirectUrl });
    if (!result || !result.success) {
      toast.error(result.message);
      return;
    }
    toast.show('Slack connected');

    await refetchAccount();
  };

  useEffect(() => {
    const handleSlackAuthCallback = async () => {
      // this runs when slack redirects back to workstory
      const code = searchParams.get('code');
      const state = searchParams.get('state');
      if (code && state) {
        await handleSlackAuth({ code, state });
        setSearchParams();
      }
    };
    handleSlackAuthCallback();
  }, [searchParams]);

  const isFetching = isFetchingCompany || isFetchingAccount || isFetchingTree;
  const isError = isErrorAccount || isErrorCompany || isErrorTree;
  const isReady = myTreeRow
    && myTreeRow.id
    && company
    && company.id
    && !isFetching
    && !isError;

  if (!isReady) return null;

  const isConnected = Boolean(get(account, 'slackUserId'));
  return (
    <Base
      classes={STYLE.CONTAINER_WHITE}
      loading={isFetching || connectSlackLoading}
    >
      <h5>Slack Integration</h5>
      <p className='mb-4'>
        Receive review requests and other notifications from WorkStory directly
        through Slack
      </p>
      {isConnected ? (
        <p className='mb-3'>You're connected to Slack</p>
      ) : (
        <div className='mb-3 sign-in-with-slack-btn'>
          <ConnectToSlack redirectUri={SLACK_REDIRECT_URI.USER_SETTINGS} iconSize='small' />
        </div>
      )}
    </Base>
  );
};

export default SlackIntegration;
