import React, { useContext } from 'react';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import commonUtils from 'common/commonUtils';
import DOMPurify from 'dompurify';
import './PreviewSummary.scss';

const PreviewSummary = () => {
  const {
    data: { form }
  } = useContext(PerformanceBuilderContext);
  const { watch } = form;
  const { summary } = watch();

  const strippedSummary = commonUtils.stripHTMLFromString(summary);
  if (!strippedSummary) return null;

  return (
    <>
      <h2 className='mb-2'>Performance Summary</h2>
      <div className='preview-summary'>
        <div className=' w-full flex flex-col justify-center text-left border border-slate-300 px-6 bg-gray-200 min-h-20 py-2'>
          <div className='text-wrap'>
            <div
              className='mb-0'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(summary)
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewSummary;
