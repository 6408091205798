import React from 'react';
import { Switch } from '@headlessui/react';

const LabeledToggle = ({
  labels = [
    { id: 'id1', text: 'text1' },
    { id: 'id2', text: 'text2' }
  ],
  selectedId = 'id1',
  onChange = () => {}
}) => (
  <Switch
    checked={selectedId === labels[1].id}
    onChange={() => {
      if (selectedId === labels[1].id) {
        onChange(labels[0].id);
      } else {
        onChange(labels[1].id);
      }
    }}
    className='group p-1 w-64 h-10 relative inline-flex rounded-md bg-mid-gray transition-colors duration-200 ease-in-out data-[checked]:bg-mid-white'
  >
    <p className='absolute left-0 top-[25%] z-[2] w-1/2 m-0 truncate leading-5 font-medium text-sm px-2'>
      {labels[0].text}
    </p>
    <span className='z-[1] p-1 pointer-events-none w-1/2 inline-block transform rounded-md bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-[100%] h-full' />
    <p className='absolute right-0 top-[25%] z-[2] w-1/2 m-0 truncate leading-5 font-medium text-sm px-2'>
      {labels[1].text}
    </p>
  </Switch>
);

export default LabeledToggle;
