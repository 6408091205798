import React, { useState, useEffect, forwardRef } from 'react';
import Litepicker from 'litepicker';
import CalendarSVG from '../../assets/calendar.svg';
import appUtils from '../appUtils';
import './DatePicker.scss';

const DatePicker = forwardRef(
  (
    {
      date, onSelect, maxDate, minDate, classes, placeholder, properties
    },
    ref
  ) => {
    properties = properties || {};
    const [picker, setPicker] = useState({
      id: `picker_${appUtils.getRandomString(35)}`,
      rendered: false
    });

    useEffect(() => {
      if (!picker.rendered) {
        const pickerProps = {
          element: document.getElementById(picker.id),
          singleMode: true,
          onSelect,
          setup: (picker) => {
            picker.on('selected', (date1) => onSelect(date1.dateInstance));
          },
          ...properties
        };

        if (minDate) {
          const minimumDate = minDate - 86400 * 1; // -1 day;
          pickerProps.minDate = new Date(minimumDate * 1000);
        }
        if (maxDate) {
          const maximumDate = parseInt(maxDate) + 86400 * 7; // +7 day
          pickerProps.maxDate = new Date(maximumDate * 1000);
        }
        window.myChart = new Litepicker(pickerProps);

        setPicker({
          ...picker,
          rendered: true
        });
      }
    });

    const textPlaceholder = placeholder || 'All Time';

    return (
      <div>
        <input
          className={`bg-white cursor-pointer rounded ${classes}`}
          placeholder='Select Date'
          type='text'
          id={picker.id}
          value={`${date ? date.toDateString() : ''}`}
          onChange={() => {}}
          {...(ref ? { ref } : {})}
        />
      </div>
    );
  }
);

export default DatePicker;
