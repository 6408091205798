import React, { useEffect, useState } from 'react';
import PersonCircleSVG from 'src/assets/svg/person-circle.svg';
import OrganizationSVG from 'src/assets/svg/organization.svg';
import { useCompany } from 'src/queries/company';
import SidebarButton from 'src/containers/Sidebar/SidebarButton';
import SidebarNavButton from 'src/containers/Sidebar/SidebarNavButton';
import { appUtils } from 'src/components/index';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useNavigate } from 'react-router-dom';

const {
  USER_STATE,
  SIDEBAR_PAGES: { PROFILE, ORGANIZATION }
} = COMMON_CONSTANTS;

const SidebarSettings = ({
  page,
  permissions = {
    isSuperUser: false,
    isAdmin: false,
    isManager: false
  },
  routeCallback = () => {}
}) => {
  const [isProfileExpanded, setIsProfileExpanded] = useState(
    Object.values(PROFILE).includes(page)
  );
  const [isOrganizationExpanded, setIsOrganizationExpanded] = useState(
    Object.values(ORGANIZATION).includes(page)
  );
  useEffect(() => {
    if (Object.values(PROFILE).includes(page)) setIsProfileExpanded(true);
    if (Object.values(ORGANIZATION).includes(page)) setIsOrganizationExpanded(true);
  }, [page]);

  const navigate = useNavigate();

  const loggedUser = appUtils.getLoggedUser();
  const isActive = loggedUser.status === USER_STATE.ACTIVE;
  // const {
  //   data: company,
  //   isFetching: isFetchingCompany,
  //   isError: isErrorCompany
  // } = useCompany();
  // const isFetching = isFetchingCompany;
  // const isError = isErrorCompany;
  // const isReady = company && company.id && !isFetching && !isError;
  // if (!isReady) return null;

  // const showOrganization = company.settings.viewPermissions[loggedUser.access].canViewOrganizationPage;
  const showOrganization = true;

  return (
    <div className='w-full flex flex-col'>
      <SidebarButton
        text='Profile'
        icon={<PersonCircleSVG />}
        onClick={() => setIsProfileExpanded(!isProfileExpanded)}
        expanded={isProfileExpanded}
      />
      {isProfileExpanded ? (
        <>
          <SidebarNavButton
            text='Personal Information'
            onClick={() => {
              navigate('/dashboard/settings');
              routeCallback();
            }}
            selected={page === PROFILE.MY_SETTINGS}
          />
          {/* <SidebarNavButton
            text='Integrations'
            onClick={() => {}}
            selected={page === '---'}
          /> */}
          {isActive ? (
            <SidebarNavButton
              text='Notifications'
              onClick={() => {
                navigate('/dashboard/settings/notifications');
                routeCallback();
              }}
              selected={page === PROFILE.MY_NOTIFICATIONS}
            />
          ) : null}
        </>
      ) : null}

      {showOrganization && (
        <>
          <SidebarButton
            text='Organization'
            icon={<OrganizationSVG />}
            onClick={() => setIsOrganizationExpanded(!isOrganizationExpanded)}
            expanded={isOrganizationExpanded}
          />
          {isOrganizationExpanded ? (
            <>
              <SidebarNavButton
                // text='Scheduling'
                text='Chart'
                onClick={() => {
                  navigate('/dashboard/organization/chart');
                  routeCallback();
                }}
                selected={page === ORGANIZATION.ORGANIZATION_CHART}
              />
              {permissions.isAdmin && (
                <>
                  <SidebarNavButton
                    text='Questions'
                    onClick={() => {
                      navigate('/dashboard/organization/questions');
                    }}
                    selected={page === ORGANIZATION.ORGANIZATION_QUESTIONS}
                  />
                  {/* <SidebarNavButton
                    text='Scoring'
                    onClick={() => {}}
                    selected={page === '---'}
                  /> */}
                  <SidebarNavButton
                    text='Setup'
                    onClick={() => {
                      navigate('/dashboard/organization/settings');
                      routeCallback();
                    }}
                    selected={page === ORGANIZATION.ORGANIZATION_SETTINGS}
                  />
                  <SidebarNavButton
                    text='Events'
                    onClick={() => {
                      navigate('/dashboard/organization/events');
                      routeCallback();
                    }}
                    selected={page === ORGANIZATION.ORGANIZATION_EVENTS}
                  />
                  {/* <SidebarNavButton
                    text='Permissions'
                    onClick={() => {}}
                    selected={page === '---'}
                  /> */}
                  {/* <SidebarNavButton
                    text='Group'
                    onClick={() => {}}
                    selected={page === '---'}
                  /> */}
                </>
              )}
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default SidebarSettings;
