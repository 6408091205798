/* eslint-disable camelcase */
import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import WorkStoryTitle from 'src/assets/svg/workstory-title.svg';
import { Button, appUtils, toast } from 'src/components';
import Builder from 'src/pages/PerformanceBuilder/Builder';
import ReportPreview from 'src/pages/PerformanceBuilder/ReportPreview';
import {
  useBuilderReport,
  useBuilderForm,
  useBuilderDataset
} from 'src/queries/builder';
import { STRIPE_UTM_SOURCES } from 'src/pages/PerformanceBuilder/BuilderComponents/PricingFlyout';
import BuilderHeader from 'src/pages/PerformanceBuilder/BuilderComponents/BuilderHeader';
import { useCompanyAsDataset } from 'src/queries/company';
import builderUtils from 'src/pages/PerformanceBuilder/builderUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import BuilderDashboard from 'src/pages/PerformanceBuilder/BuilderDashboard';
import SpinnerSVG from 'src/assets/svg/spinner.svg';
import { useNavigate, useParams } from 'react-router-dom';

const { DIRECT_REVIEW_PERMISSIONS } = COMMON_CONSTANTS;

const renderLoadingComponent = () => (
  <div className='flex flex-col w-full min-h-screen overflow-hidden'>
    <BuilderHeader />
    <div className='flex items-center justify-center h-full w-full mt-14'>
      <SpinnerSVG className='w-8 h-8' />
    </div>
  </div>
);

const MobileFallback = () => (
  <div className='h-[100vh] w-[100vw] overflow-hidden bg-black flex flex-col justify-start p-10 text-white'>
    <WorkStoryTitle className='mx-auto w-[90%] h-fit' />
    <p className='text-xl font-bold mt-10 mb-0'>
      WorkStory Performance Review Builder
    </p>
    <p className='mt-2 mb-1 text-lg'>
      Building a performance review is much easier on a desktop.
    </p>
    <p className='mt-1 mb-2 text-lg'>
      Please use your personal computer to access this feature.
    </p>
    <Button
      variant='purple'
      classes='rounded text-lg mt-4'
      onClick={() => {
        const landingPageUrl = appUtils.getLandingPageUrl();
        window.location.href = landingPageUrl;
      }}
    >
      Go to Home
    </Button>
  </div>
);

const PerformanceBuilder = ({ page, mode, utm_source }) => {
  const { editToken: editTokenParam, reportId: reportIdParam } = useParams();
  const navigate = useNavigate();
  const appSizeIndex = appUtils.getAppSizeIndex();
  const [isReady, setIsReady] = useState(false);

  const isTabletOrLess = appSizeIndex <= 1;

  const params = new URLSearchParams(window.location.search);

  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = appUtils.getLoggedUserId();

  const {
    data: { isInitialized },
    resetData
  } = useContext(PerformanceBuilderContext);

  const isDashboardPage = page === 'dashboard';
  const isViewPage = page === 'view';
  const isEditPage = page === 'edit';
  const isBuilderPage = page === 'builder';

  let reportIdToFetch = null;
  if (isEditPage) reportIdToFetch = editTokenParam;
  else if (isViewPage) reportIdToFetch = reportIdParam;

  const {
    data: reportData,
    isFetching: isFetchingPerformanceReview,
    isError: isErrorPerformanceReview,
    isRefetching: isRefetchingPerformanceReview
  } = useBuilderReport(
    reportIdToFetch,
    {},
    { keepPreviousData: Boolean(reportIdToFetch) }
  );

  const {
    data: builderDataset,
    isFetching: isFetchingBuilderDataset,
    isError: isErrorBuilderDataset
  } = useBuilderDataset();

  let companyIdToFetch = null;
  if (isBuilderPage) companyIdToFetch = params.get('companyid');
  if (isEditPage || isViewPage) companyIdToFetch = reportData.companyid;

  const {
    data: companyData,
    success: successCompanyData,
    isFetching: isFetchingCompanyData,
    isError: isErrorCompanyData
  } = useCompanyAsDataset(companyIdToFetch, loggedUserId);

  const prevDataRef = useRef({});
  const { form, defaultValues } = useBuilderForm(
    reportData,
    companyData,
    builderDataset
  );
  prevDataRef.current = { ...defaultValues };

  useEffect(() => {
    const isLoggedIn = appUtils.isLoggedIn();
    if (isDashboardPage) {
      if (!isLoggedIn) {
        toast.show('Please log in to view this page');
        return navigate('/builder');
      }
      const { companyid } = loggedUser;

      return resetData({ companyid, page, isInitialized: false });
    }

    if (isReady) {
      if (companyIdToFetch && !successCompanyData) {
        navigate('/builder/new');
      } else {
        form.reset(defaultValues);
        let companyMode = null;
        let reviewMode = null;
        let canReviewAnyone = null;

        if (isBuilderPage) {
          companyMode = companyIdToFetch ? 'company' : 'public';
          if (companyMode === 'company') {
            canReviewAnyone = companyData.settings.directReviewPermissions
              === DIRECT_REVIEW_PERMISSIONS.OPEN;
          }
        } else {
          if (reportData.companyMode === 'company') {
            canReviewAnyone = companyData.settings.directReviewPermissions
              === DIRECT_REVIEW_PERMISSIONS.OPEN;
          }
          companyMode = reportData.companyMode;
          reviewMode = reportData.reviewMode;
        }

        return resetData({
          reportId: reportData._id || null,
          editToken: reportData.editToken || null,
          companyid: companyIdToFetch,
          form,
          prevDataRef,
          dataset: builderUtils.getDatasetByCompanyMode(companyMode, {
            builderDataset,
            companyDataset: companyData.dataset
          }),
          canReviewAnyone,
          companyMode,
          reviewMode,
          page,
          isInitialized: true,
          mode: mode || reportData.mode
        });
      }
    }
  }, [page, isReady, reportIdToFetch, companyIdToFetch]);

  useEffect(() => {
    if (!utm_source) {
      return;
    }
    if (utm_source === STRIPE_UTM_SOURCES.NO_REPORT_ID) {
      toast.show('Thank you for your payment! Check your email for details.');
      return navigate('/builder');
    }
    const reportId = utm_source.split('_').pop();
    toast.show('Thank you for your payment! Check your email for details.');
    navigate(`/builder/${reportId}/edit`);
  }, [utm_source]);

  if (isTabletOrLess) return <MobileFallback />;

  const isInitialFetchingReportData = isFetchingPerformanceReview && !isRefetchingPerformanceReview;
  const isReportDataReady = !isInitialFetchingReportData && !isErrorPerformanceReview;
  if (!isReportDataReady) return renderLoadingComponent();

  const isBuilderDatasetReady = !isFetchingBuilderDataset && !isErrorBuilderDataset;

  const isCompanyDataReady = !isFetchingCompanyData && !isErrorCompanyData;

  if (!isFetchingCompanyData && isErrorCompanyData) {
    return navigate('/');
  }

  if (
    isBuilderDatasetReady
    && isCompanyDataReady
    && isReportDataReady
    && !isReady
  ) {
    setIsReady(true);
  }
  if (!isReady) return renderLoadingComponent();

  return (
    <div className='flex flex-col w-full min-h-screen overflow-hidden'>
      <BuilderHeader />
      <div className='flex h-full mt-14'>
        {isDashboardPage ? <BuilderDashboard /> : null}
        {!isDashboardPage && isInitialized ? (
          <>
            {isEditPage || isBuilderPage ? <Builder /> : null}
            <ReportPreview />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default PerformanceBuilder;
