import COMMON_CONSTANTS from 'common/commonConstants';

const { DEFAULT_COMPANY_SCORE_LABELS, DEFAULT_COMPANY_TEMPLATES } = COMMON_CONSTANTS;

const utils = {};

utils.getDatasetByCompanyMode = (
  companyMode,
  { builderDataset, companyDataset }
) => {
  if (companyMode === 'company') return companyDataset || [];
  return builderDataset || [];
};

utils.getPopulatedReviews = (
  dataset,
  {
    industryId = null, roleId = null, reviews = [], reviewMode = 'questions'
  }
) => {
  if (!industryId || !roleId || !dataset.length) return [];

  const industryData = dataset.find(
    (industry) => industry.industryId === industryId
  );

  const { roles } = industryData;
  const roleData = roles.find((role) => role.roleId === roleId);
  const { categories } = roleData;

  const populatedReviews = [];
  const emptyAnswer = utils.getEmptyOption('answer');
  const answerOptions = utils.getAnswerOptions(industryId, dataset);

  if (reviewMode === 'questions') {
    categories.forEach((category) => {
      const { questions } = category;
      questions.forEach((question) => {
        const review = reviews.find(
          (r) => r.categoryId === category.categoryId
            && r.questionId === question.questionId
        );
        if (review) {
          const defaultReviewAnswer = answerOptions.find(
            (answer) => answer.label === review.answer
              || answer.label === review.answer?.label
          );
          populatedReviews.push({
            ...review,
            answer: defaultReviewAnswer
          });
        } else {
          populatedReviews.push({
            categoryId: category.categoryId,
            questionId: question.questionId,
            answer: emptyAnswer,
            comments: ''
          });
        }
      });
    });
  }

  if (reviewMode === 'categories') {
    categories.forEach((category) => {
      const review = reviews.find((r) => r.categoryId === category.categoryId);
      if (review) {
        const defaultReviewAnswer = answerOptions.find(
          (answer) => answer.label === review.answer
            || answer.label === review.answer?.label
        );
        populatedReviews.push({ ...review, answer: defaultReviewAnswer });
      } else {
        populatedReviews.push({
          categoryId: category.categoryId,
          questionId: null,
          answer: emptyAnswer,
          comments: ''
        });
      }
    });
  }

  return populatedReviews;
};

utils.getPopulatedIndustryAndRole = (dataset, { industryId, roleId }) => {
  let industryData = null;
  let roleData = null;

  if (industryId) {
    industryData = dataset.find(
      (industry) => industry.industryId === industryId
    );

    if (roleId) {
      roleData = industryData.roles.find((role) => role.roleId === roleId);
    } else {
      roleData = {
        roleId: null,
        name: null
      };
    }

    return {
      industry: {
        id: industryData.industryId,
        label: industryData.name
      },
      role: {
        id: roleData.roleId,
        label: roleData.name
      }
    };
  }
  return {
    industry: utils.getEmptyOption(),
    role: utils.getEmptyOption()
  };
};

utils.getEmptyOption = (type) => {
  if (type === 'answer') {
    return {
      id: '',
      label: '',
      score: null
    };
  }

  if (type === 'review') {
    return {
      answer: {
        id: '',
        label: '',
        score: null
      },
      comments: ''
    };
  }

  return {
    id: null,
    label: ''
  };
};

utils.getAnswerOptions = (industryId, dataset) => {
  let scoreLabels = [];

  if (industryId) {
    const industryData = dataset.find(
      (industry) => industry.industryId === industryId
    );
    scoreLabels = industryData.scoreLabels;
  }

  if (!scoreLabels || !scoreLabels.length) scoreLabels = DEFAULT_COMPANY_SCORE_LABELS;

  scoreLabels.sort((a, b) => a.max - b.max);
  const scoreLabelStep = 10 / scoreLabels.length;
  const answerOptions = scoreLabels.map(({ label }, index) => ({
    id: index,
    label,
    score: scoreLabelStep * (index + 1)
  }));

  return answerOptions;
};

utils.getTemplateOptions = (industryId, dataset) => {
  let templates = [];

  if (industryId) {
    const industryData = dataset.find(
      (industry) => industry.industryId === industryId
    );
    templates = industryData.templates;
  }

  if (!templates || !templates.length) templates = Object.values(DEFAULT_COMPANY_TEMPLATES);

  const templateOptions = templates.map(({ id, name, content }, index) => ({
    id: id || `${name.replace(/\s/g, '-').toLowerCase()}-${index}`,
    label: name,
    content
  }));

  return templateOptions;
};

utils.getIsJoeleFrank = (companyid) => companyid === '44c4c44a-fefc-466e-bf54-ef3a863b3db1';

utils.getIsPublicSchoolForum = (companyid) => companyid === '6413a9e6-4723-42db-8a48-ca5579d29925';

export default utils;
