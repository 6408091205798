import React, { useContext } from 'react';
import { isNil } from 'lodash';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import PreviewCategory from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewCategory';
import PreviewQuestion from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewQuestion';

const PreviewReviews = () => {
  const {
    data: { form, dataset, reviewMode }
  } = useContext(PerformanceBuilderContext);

  const { watch } = form;
  const { industry, role, reviews } = watch();
  const { id: industryId } = industry;
  const { id: roleId } = role;

  const hasAnyAnswers = reviews.some(
    (review) => review.answer && review.answer.label
  );
  if (!hasAnyAnswers || !industryId || !roleId) return null;

  const industryData = dataset.find((data) => data.industryId === industryId);
  const roleData = industryData.roles.find((data) => data.roleId === roleId);
  const { categories } = roleData;

  let displayedReviews = reviews;

  if (roleId === '70196e3b-6e41-4521-883b-70823b9a6e14') {
    const sortedReviews = [
      reviews.find(
        (r) => r.categoryId === 'f08359c3-212d-4bb5-8af9-ed2ecf313221'
      ),
      reviews.find(
        (r) => r.categoryId === '3aca867d-8166-445e-b3ce-f0a372b72516'
      ),
      reviews.find(
        (r) => r.categoryId === '259918ce-1c62-4f8c-978e-10c3d63d1365'
      ),
      reviews.find(
        (r) => r.categoryId === '53459930-2e94-45ad-8fa3-fd0e226a9adb'
      ),
      reviews.find(
        (r) => r.categoryId === '8d5357c6-c6a5-4291-8548-8c02836f4979'
      ),
      reviews.find(
        (r) => r.categoryId === 'a64e8c7f-4892-42cd-adb3-70277f3b71ef'
      ),
      reviews.find(
        (r) => r.categoryId === '5b7010da-61fc-4410-9885-816704721168'
      ),
      reviews.find(
        (r) => r.categoryId === '672dba39-dbec-4a03-b971-64df3bdd15ff'
      ),
      reviews.find(
        (r) => r.categoryId === '304ef8b3-fdfb-44c0-ad55-0bf61cbdcb41'
      ),
      reviews.find(
        (r) => r.categoryId === '9d597349-61f4-4c9e-b250-17d79187625d'
      ),
      reviews.find(
        (r) => r.categoryId === '83984f34-6f75-4549-a608-aac09b7fcb7b'
      ),
      reviews.find(
        (r) => r.categoryId === '62cc83b5-aa3f-478b-8df1-6b0176d0c4fa'
      ),
      reviews.find(
        (r) => r.categoryId === 'a8e116d4-733a-447d-a37a-508f57ef6c1b'
      )
    ];
    displayedReviews = sortedReviews.filter((category) => !isNil(category));
  }
  if (reviewMode === 'categories') {
    return (
      <>
        <h2>Performance Categories</h2>
        {displayedReviews.map((review) => {
          const { answer, categoryId } = review;

          const isAnswered = answer && answer.label;
          if (!isAnswered) return null;

          const category = categories.find((c) => c.categoryId === categoryId);
          return (
            <PreviewCategory
              review={review}
              category={category}
              key={categoryId}
            />
          );
        })}
        <div className='my-8 border-b rounded border-[#E2E8F0]' />
      </>
    );
  }

  if (reviewMode === 'questions') {
    return (
      <>
        {categories.map((category) => {
          const hasAnswers = displayedReviews.some(
            (review) => review.answer
              && review.answer.label
              && review.categoryId === category.categoryId
          );
          if (!hasAnswers) return null;

          const { name, questions } = category;
          const categoryReviews = displayedReviews.filter(
            (review) => review.categoryId === category.categoryId
          );
          return (
            <>
              <h3 className='text-lg font-bold text-purple'>{name}</h3>
              {categoryReviews.map((review) => {
                const { answer, questionId } = review;

                const isAnswered = answer && answer.label;
                if (!isAnswered) return null;

                const question = questions.find(
                  (q) => q.questionId === questionId
                );
                return <PreviewQuestion review={review} question={question} />;
              })}
              <div className='my-4 border-b rounded border-[#E2E8F0]' />
            </>
          );
        })}
      </>
    );
  }
};

export default PreviewReviews;
