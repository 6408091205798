import { h } from 'preact';
import { route } from 'preact-router';
import DOMPurify from 'dompurify';
import { capitalize, get, isString } from 'lodash';
import { Button, Base } from 'src/components';
import appUtils from 'src/components/appUtils';
import commonDateUtils from 'common/commonDateUtils';
import { Flyout } from 'src/componentsTailwind/index';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonTreeUtils from 'common/commonTreeUtils';
import commonPermissions from 'common/commonPermissions';
import { useTree } from 'src/queries/tree';
import CircledIcon from 'src/components/CircledIcon/CircledIcon';
import ReviewOutlinedSVG from 'src/assets/svg/review-outlined.svg';
import commonQuestionsUtils from 'common/commonQuestionsUtils';
import { useCompany } from 'src/queries/company';

const { ACCESS } = COMMON_CONSTANTS;

const FeedbackFlyout = (props = {}) => {
  const { feedback = {}, setIsOpen = () => {} } = props;

  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = loggedUser._id || loggedUser.id;

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingTree || isFetchingCompany;
  const isError = isErrorTree || isErrorCompany;
  const isReady = tree && tree.id && company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const isAdmin = loggedUser.access === ACCESS.ADMIN;

  const {
    reviewee,
    reviewerName,
    revieweeName,
    text,
    sentiment,
    visibleText,
    roleId,
    categoryId,
    question,
    createdDate,
    reviewedDate,
    collection,
    answer
  } = feedback;
  const isReview = collection === 'reviews';
  const feedbackId = feedback._id || feedback.id;

  const editFeedback = () => {
    if (isReview) return route(`/dashboard/reviews/edit/${feedbackId}`);
    return route(`/dashboard/feedback/edit/${feedbackId}`);
  };

  const companyQuestions = company.questions;
  const roleLabel = get(
    isReview && commonQuestionsUtils.findRole(companyQuestions, roleId),
    'label',
    '-'
  );
  const categoryLabel = get(
    isReview && commonQuestionsUtils.findCategory(companyQuestions, categoryId),
    'label',
    '-'
  );

  const questionLabel = question || '-';

  const isAbove = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    reviewee,
    loggedUserId
  );
  const canManageAccount = commonPermissions.canManageAccounts(loggedUser, [
    reviewee
  ]);
  const canEditFeedback = isAbove || isAdmin || canManageAccount;

  const sentimentRange = get(sentiment, 'range', null);
  const sentimentRangeLabel = isString(sentimentRange)
    ? capitalize(sentimentRange)
    : '-';
  const visibilityLabel = isString(visibleText)
    ? visibleText.replace(/\.$/, '')
    : '-';

  const sanitizedText = () => ({
    __html: DOMPurify.sanitize(text)
  });

  return (
    <Flyout {...props}>
      <Base classes='h-[100%] w-full' relative>
        <CircledIcon
          svg={<ReviewOutlinedSVG className='w-4 h-4 text-purple' />}
        />
        <h2 className='text-xl font-bold h-[3%] mr-8'>Feedback Details</h2>
        <p className='text-sm font-normal mt-2 mb-4 leading-5 text-bluish-gray h-[3%]'>
          Information related to this feedback comment.
        </p>
        <div className='border-b border-mid-gray my-3 transform -translate-x-[32px] w-[calc(100%+64px)]' />
        <div className='flex flex-col gap-4 h-[80%]'>
          <div>
            <p className='text-black font-bold leading-5 text-md m-0'>
              Reviewer
            </p>
            <p className='text-bluish-gray font-normal leading-5 text-sm m-0'>
              {reviewerName}
            </p>
          </div>
          <div>
            <p className='text-black font-bold leading-5 text-md m-0'>
              Reviewee
            </p>
            <p className='text-bluish-gray font-normal leading-5 text-sm m-0'>
              {revieweeName}
            </p>
          </div>
          <div>
            <p className='text-black font-bold leading-5 text-md m-0'>Date</p>
            <p className='text-bluish-gray font-normal leading-5 text-sm m-0'>
              {commonDateUtils.dateToMonthDayYearTimeFormat(
                new Date(createdDate || reviewedDate)
              )}
            </p>
          </div>
          <div>
            <p className='text-black font-bold leading-5 text-md m-0'>
              Review Question
            </p>
            <p className='text-bluish-gray font-normal leading-5 text-sm m-0'>
              {questionLabel}
            </p>
          </div>
          <div>
            <p className='text-black font-bold leading-5 text-md m-0'>
              Feedback Comment
            </p>
            <div
              className='ql-editor p-0 m-0 text-bluish-gray font-normal leading-5 text-base'
              dangerouslySetInnerHTML={sanitizedText()}
            />
          </div>
          <div>
            <p className='text-black font-bold leading-5 text-md m-0'>Role</p>
            <p className='text-bluish-gray font-normal leading-5 text-sm m-0'>
              {roleLabel}
            </p>
          </div>
          <div>
            <p className='text-black font-bold leading-5 text-md m-0'>
              Category
            </p>
            <p className='text-bluish-gray font-normal leading-5 text-sm m-0'>
              {categoryLabel}
            </p>
          </div>
          {isReview && answer ? (
            <div>
              <p className='text-black font-bold leading-5 text-md m-0'>
                Review Answer
              </p>
              {answer}
            </div>
          ) : null}
          <div>
            <p className='text-black font-bold leading-5 text-md m-0'>
              Sentiment
            </p>
            <p className='text-bluish-gray font-normal leading-5 text-sm m-0'>
              {sentimentRangeLabel}
            </p>
          </div>
          <div>
            <p className='text-black font-bold leading-5 text-md m-0'>
              Feedback Visibility
            </p>
            <p className='text-bluish-gray font-normal leading-5 text-sm m-0'>
              {visibilityLabel}
            </p>
          </div>
        </div>
        <div className='flex w-full justify-end items-end gap-4 h-[10%]'>
          <Button
            classes='!h-11 !rounded-md !text-base !font-normal'
            variant='empty-with-border'
            onClick={() => setIsOpen(false)}
          >
            Close
          </Button>
          {canEditFeedback ? (
            <Button
              type='button'
              variant='custom'
              classes='bg-[#0F172A] text-white text-base px-4 py-2 rounded-md transition-colors duration-300 h-11'
              onClick={editFeedback}
            >
              Edit Feedback
            </Button>
          ) : null}
        </div>
      </Base>
    </Flyout>
  );
};

export default FeedbackFlyout;
