import React, { useEffect, useState } from 'react';
import { Button, Base } from 'src/components';
import { useEditGoal } from 'src/containers/UserProfile/Goals/queries';
import DeleteGoalModal from 'src/containers/UserProfile/Goals/components/DeleteGoalModal';
import GoalSetup from 'src/pagesDashboard/GoalSettings/GoalSetup';
import CrossSVG from 'src/assets/cross.svg';
import STYLE from 'src/constants/style';
import { useGoal } from 'src/queries/goal';
import { useAccount } from 'src/queries/account';
import commonQuestions from 'common/commonQuestions';
import { useCompany } from 'src/queries/company';
import {
  GOAL_LEVELS,
  GOAL_TYPES,
  getGoalStatuses,
  GOAL_UPDATE_FREQUENCIES
} from 'src/containers/UserProfile/Goals/constants';
import { get } from 'lodash';

const EditGoal = ({ goalId, userId }) => {
  const close = () => {
    window.history.back();
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const {
    data: goal,
    isFetching: isFetchingGoal,
    isError: isErrorGoal
  } = useGoal(goalId);

  const {
    data: assignee,
    isFetching: isFetchingAssignee,
    isError: isErrorAssignee
  } = useAccount(goal.assignee);

  const {
    data: parentGoal,
    isFetching: isFetchingParentGoal,
    isError: isErrorParentGoal
  } = useGoal(goal.parentId);

  const {
    data: parentGoalAssignee,
    isFetching: isFetchingParentGoalAssignee,
    isError: isErrorParentGoalAssignee
  } = useAccount(parentGoal.assignee);

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany({
    enabled: Boolean(goal.categoryId)
  });

  const isFetching = isFetchingGoal
    || isFetchingAssignee
    || (goal.parentId && isFetchingParentGoal)
    || (parentGoal.assignee && isFetchingParentGoalAssignee)
    || (goal.categoryId && isFetchingCompany);
  const isError = isErrorGoal
    || isErrorAssignee
    || (goal.parentId && isErrorParentGoal)
    || (parentGoal.assignee && isErrorParentGoalAssignee)
    || (goal.categoryId && isErrorCompany);
  const isReady = goal._id
    && (!goal.assignee || assignee._id)
    && (!goal.parentId || parentGoal._id)
    && (!parentGoal.assignee || parentGoalAssignee._id)
    && (!goal.categoryId || company.id)
    && !isFetching
    && !isError;

  const {
    control,
    register,
    watch,
    handleSubmit,
    save,
    setValue,
    error,
    isUpdating,
    isDirty,
    reset
  } = useEditGoal(goalId, close);

  useEffect(() => {
    if (!isReady) return;

    const GOAL_STATUSES = getGoalStatuses();
    const levelOption = GOAL_LEVELS.find((o) => o.value === goal.level);
    const progressTypeOption = GOAL_TYPES.find((o) => o.value === goal.type);
    const statusOption = GOAL_STATUSES.find((o) => o.value === goal.status);
    const frequencyOption = GOAL_UPDATE_FREQUENCIES.find(
      (o) => o.value === goal.frequency
    );
    let category;
    if (goal.categoryId) {
      category = commonQuestions.getCategory(
        goal.categoryId,
        company.questions.CATEGORIES
      );
    }
    const defaultValues = {
      ...goal,
      parentGoalAssignee: {
        value: goal.parentId ? parentGoalAssignee._id : null,
        label: goal.parentId ? parentGoalAssignee.name : 'No parent goal'
      },
      parentGoal: {
        value: goal.parentId ? parentGoal._id : null,
        label: goal.parentId ? parentGoal.title : 'Select Goal'
      },
      deadline: new Date(goal.deadline),
      level: levelOption,
      progress: goal.progress,
      progressType: progressTypeOption,
      status: statusOption,
      frequency: frequencyOption,
      assignee: {
        value: get(assignee, '_id', null),
        label: get(assignee, 'name', 'Unassigned')
      },
      category: {
        value: goal.categoryId ? category.id : null,
        label: goal.categoryId ? category.label : 'No category'
      }
    };
    reset(defaultValues);
  }, [isReady]);

  if (!isReady) {
    return null;
  }

  return (
    <Base classes={`${STYLE.CONTAINER_WHITE_PADDINGLESS} relative`}>
      {deleteModal ? (
        <DeleteGoalModal
          goalId={goalId}
          close={(doDelete) => {
            setDeleteModal(false);
            close();
          }}
        />
      ) : null}
      <div className='absolute right-0'>
        <button className='mr-2 mt-2' onClick={close}>
          <CrossSVG className='w-12 h-12 cursor-pointer' />
        </button>
      </div>
      <GoalSetup
        userId={userId}
        goalId={goalId}
        mode='edit'
        register={register}
        control={control}
        watch={watch}
        setValue={setValue}
        loading={isUpdating}
      />
      <div className='mt-4 p-4'>
        <div className='inline-block'>
          <div className='inline-block mr-3'>
            <Button onClick={() => setDeleteModal(goalId)} variant='purple'>
              <span className='text-lg font-bold'>Delete</span>
            </Button>
          </div>
        </div>
        <div className='inline-block float-right'>
          <Button
            onClick={handleSubmit(save, error)}
            variant='yellow'
            disabled={isUpdating || !isDirty}
          >
            Save
          </Button>
        </div>
      </div>
    </Base>
  );
};

export default EditGoal;
