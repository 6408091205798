import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Select, Button } from 'src/components/';
import SpeechBubbleOvalSVG from 'src/assets/svg/speech-bubble-oval.svg';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';

const MobileSelectGiveFeedback = ({
  revieweeOptions,
  selectedRevieweeData,
  onChangeReviewee,
  setSearchText,
  isFetchingRevieweeAccounts,
  revieweeId,
  goToReview,
  submitButtonTitle,
  isSubmitDisabled
}) => {
  const navigate = useNavigate();
  return (
    <div>
      <NavigationItem
        goBack={() => navigate('/dashboard/home')}
        title='Give Feedback'
      >
        <SpeechBubbleOvalSVG />
      </NavigationItem>
      <div className='px-3'>
        <p className='mb-1 mt-3'>Who would you like to give feedback to?</p>
        <Select
          placeholder='selectedReviewee'
          classes='w-full mt-4'
          options={revieweeOptions}
          title={
            selectedRevieweeData
              ? selectedRevieweeData.label
              : 'Select a Team Member'
          }
          onChange={(revieweeOption) => {
            onChangeReviewee(revieweeOption);
          }}
          loading={isFetchingRevieweeAccounts}
          showSearch
          onSearch={(value) => setSearchText(value)}
          onDropdownClose={() => setSearchText('')}
          disabled={revieweeId}
        />
        <div className='pr-6 fixed bottom-0 w-full pb-2'>
          <Button
            variant='black'
            classes='w-full'
            onClick={goToReview}
            disabled={isSubmitDisabled}
          >
            {submitButtonTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MobileSelectGiveFeedback;
