import { Fragment, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import { useQueryClient } from 'react-query';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import {
  useAccount,
  useAccountTasks,
  QUERY_KEYS as ACCOUNT_QUERY_KEYS
} from 'src/queries/account';
import { useReviewsDue } from 'src/queries/reviews';
import { usePulseReviewsDue } from 'src/queries/pulse';
import {
  updateReportQuery,
  QUERY_KEYS as REPORTS_QUERY_KEYS
} from 'src/queries/reports';
import GiveFeedbackModal from 'src/containers/UserProfile/UserFeedback/WriteNoteModal';
import {
  Base, Tooltip, toast, Button
} from 'src/components';
import { AlertModal } from 'src/componentsTailwind/';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import appUtils from 'src/components/appUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import commonPermissions from 'common/commonPermissions';
import commonDateUtils from 'common/commonDateUtils';
import commonUtils from 'common/commonUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import SpinnerSVG from 'src/assets/svg/spinner.svg';
import CircledCheckmarkSVG from 'src/assets/svg/circled-checkmark.svg';
import STYLE from 'src/constants/style';

const {
  TASK_TYPES,
  FEEDBACK_TYPE,
  TASK_TOOLTIP_LABELS,
  ACCESS,
  REPORT_STATUS
} = COMMON_CONSTANTS;

export const renderTask = (
  { type, data },
  {
    rounded = '', border = '', tree, viewerAccount
  } = {},
  callback = () => {}
) => {
  const queryClient = useQueryClient();
  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = loggedUser._id || loggedUser.id;
  const isNewReportFlowEnabled = appUtils.isNewReportFlowEnabled();
  const params = {
    title: null,
    subtitle: null,
    buttonText: null,
    buttonCallback: () => {},
    secondaryButtonCallback: null,
    secondaryButtonText: null,
    onCloseModal: null
  };

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const { mutateAsync: updateReport, isLoading: isUpdatingReport } = updateReportQuery();

  const isLoadingModal = isUpdatingReport;

  const setReportStatusToFinished = async () => {
    try {
      const reportId = data._id;
      await updateReport({
        reportId,
        status: REPORT_STATUS.FINISHED
      });
      queryClient.invalidateQueries([REPORTS_QUERY_KEYS.REPORT, reportId]);
      queryClient.invalidateQueries(REPORTS_QUERY_KEYS.REPORTS);
      queryClient.invalidateQueries(ACCOUNT_QUERY_KEYS.TASKS);
      setIsConfirmModalOpen(false);
      toast.show('Report status updated');
    } catch (error) {
      console.error('Failed to update report status', error);
      return toast.error(error ?? 'Failed to update report status');
    }
  };

  if (type === TASK_TYPES.REVIEW_REQUESTS) {
    params.title = (
      <p className='p-0 m-0'>
        You have
        {' '}
        <strong>{data.length > 9 ? '9+' : data.length}</strong>
        {' '}
        open
        review requests to complete
      </p>
    );

    params.tooltipText = TASK_TOOLTIP_LABELS.REVIEW_REQUESTS;

    params.buttonText = 'Answer';

    params.buttonCallback = () => {
      route('/dashboard/inbox/reviews');
      callback();
    };
  }
  if (type === TASK_TYPES.REVIEW_DIRECT_REPORTS) {
    const { latestReviewOrFeedbackDate } = data;

    params.title = (
      <p className='p-0 m-0'>
        <strong>
          {commonUtils.getFullName(data.firstName, data.lastName, 'lastName')}
        </strong>
        {' '}
        has not received feedback from you in a while
      </p>
    );

    params.tooltipText = TASK_TOOLTIP_LABELS.REVIEW_DIRECT_REPORTS;

    params.subtitle = latestReviewOrFeedbackDate
      ? `The last time you provided feedback was ${commonDateUtils.dateToTimeAgo(
        latestReviewOrFeedbackDate
      )}`
      : 'You never provided feedback';

    params.buttonText = 'Provide Feedback';

    params.buttonCallback = () => callback();
  }
  if (type === TASK_TYPES.ADD_REVIEW_RELATIONSHIPS) {
    params.title = (
      <p className='p-0 m-0'>
        Add review relationships for
        {' '}
        <strong>
          {commonUtils.getFullName(data.firstName, data.lastName, 'lastName')}
        </strong>
      </p>
    );

    params.tooltipText = TASK_TOOLTIP_LABELS.ADD_REVIEW_RELATIONSHIPS;

    params.buttonText = 'Assign Reviewers';

    params.buttonCallback = () => {
      route(`/dashboard/profile/${data._id}/information/review-relationships`);
      callback();
    };
  }
  if (type === TASK_TYPES.INACTIVE_DIRECT_REPORTS) {
    params.title = (
      <p className='p-0 m-0'>
        Your direct report
        {' '}
        <strong>
          {commonUtils.getFullName(data.firstName, data.lastName, 'lastName')}
        </strong>
        {' '}
        is inactive
      </p>
    );

    params.tooltipText = TASK_TOOLTIP_LABELS.INACTIVE_DIRECT_REPORTS;

    params.buttonText = 'Change Status';

    params.buttonCallback = () => {
      route(`/dashboard/profile/${data._id}/information/access`);
      callback();
    };
  }
  if (type === TASK_TYPES.REPORT_CREATED) {
    const isReportee = data.user === loggedUserId;
    const isAdmin = loggedUser.access === ACCESS.ADMIN;
    const isAbove = commonTreeUtils.isNodeDirectlyAbove(
      tree,
      data.user,
      loggedUserId
    );
    const isAuthor = loggedUserId === data.user;

    const canManageAccount = commonPermissions.canManageAccounts(
      viewerAccount,
      [data.user]
    );

    const canManageUser = isAdmin || isAbove || isAuthor || canManageAccount;

    params.title = (
      <Fragment>
        <p className='p-0 m-0'>
          {' '}
          You have a performance review in progress
          {!isReportee ? (
            <Fragment>
              {' '}
              for
              {' '}
              <strong>{data.userName}</strong>
            </Fragment>
          ) : null}
        </p>

        <span className='text-gray-500 text-sm'>
          {TASK_TOOLTIP_LABELS.REPORT_CREATED}
        </span>
      </Fragment>
    );

    params.tooltipText = TASK_TOOLTIP_LABELS.REPORT_CREATED;

    params.buttonText = 'View';

    params.secondaryButtonText = 'Finalize';

    params.buttonCallback = () => {
      route(
        `/dashboard/profile/${data.user}/report/${data._id}${
          isNewReportFlowEnabled ? '/preview' : ''
        }`
      );
      callback();
    };

    if (canManageUser) {
      params.secondaryButtonCallback = () => setIsConfirmModalOpen(true);
      params.modalTitle = 'Finalize performance review';
      params.modalContent = 'The performance review status is used to determine when a review is complete or not. Finalizing will close all outstanding tasks associated with this review.';
      params.actionText = 'Finalize';
      params.modalType = 'success';
      params.onModalAction = () => setReportStatusToFinished();
      params.onCloseModal = () => setIsConfirmModalOpen(false);
    }
  }
  if (type === TASK_TYPES.REPORT_SHARED) {
    const reportIsForLoggedUser = data.user === loggedUser.id;
    const name = reportIsForLoggedUser ? 'you.' : data.userName;
    params.title = (
      <div className='p-0 m-0'>
        <p className='p-0 m-0'>
          You have been asked to collaborate on a performance review for
          {' '}
          <strong>{name}</strong>
        </p>
        <span className='text-gray-500 text-sm'>
          {TASK_TOOLTIP_LABELS.REPORT_SHARED}
        </span>
      </div>
    );

    params.tooltipText = TASK_TOOLTIP_LABELS.REPORT_SHARED;

    params.buttonText = 'View';

    params.buttonCallback = () => {
      route(
        `/dashboard/profile/${data.user}/report/${data._id}${
          isNewReportFlowEnabled ? '/preview' : ''
        }`
      );
      callback();
    };
  }
  if (type === TASK_TYPES.UPDATE_GOAL) {
    const latestUpdate = data.updates.pop();

    params.title = (
      <p className='p-0 m-0'>
        It's time to update your goal
        {' '}
        <strong>{data.title}</strong>
      </p>
    );

    params.tooltipText = TASK_TOOLTIP_LABELS.UPDATE_GOAL;

    params.subtitle = latestUpdate
      ? `The last time you updated this goal was ${commonDateUtils.unixToTimeAgo(
        latestUpdate.timestamp
      )}`
      : 'You never updated this goal';

    params.buttonText = 'Update';

    params.buttonCallback = () => {
      route(
        `/dashboard/goal/view/${data.assignee}/${data._id}?redir=/dashboard/inbox/tasks`
      );
      callback();
    };
  }
  if (type === TASK_TYPES.OPEN_PULSE) {
    const pulseCount = data.length;

    params.title = (
      <p className='p-0 m-0'>
        You have
        {' '}
        <strong>{pulseCount > 9 ? '9+' : pulseCount}</strong>
        {' '}
        open
        pulse review
        {' '}
        {pulseCount === 1 ? 'request' : 'requests'}
        {' '}
        to complete
      </p>
    );

    params.buttonText = 'Answer';

    params.tooltipText = TASK_TOOLTIP_LABELS.OPEN_PULSE;

    params.buttonCallback = () => {
      route('/dashboard/inbox/pulse');
      callback();
    };
  }

  return (
    <Fragment>
      <AlertModal
        isOpen={isConfirmModalOpen}
        close={params.onCloseModal}
        onAction={params.onModalAction}
        title={params.modalTitle}
        content={params.modalContent}
        actionText={params.actionText}
        type={params.modalType}
        isLoading={isLoadingModal}
      />
      <div
        className={`flex items-center justify-between w-full h-auto md:h-[85px] grow-0 shrink-0 border-stone-gray px-4 2xl:py-4 2xl:px-6  
      ${rounded.includes('top') ? 'border-t rounded-t-lg' : ''} ${
          rounded.includes('bottom') ? 'border-b rounded-b-lg' : ''
        } ${border.includes('sides') ? 'border-r border-l' : ''} ${
          border.includes('bottom') ? 'border-b' : ''
        } ${border.includes('top') ? 'border-t' : ''}`}
      >
        <Tooltip
          containerClasses='h-fit w-1/20 shrink-0 pr-2 mini:pr-4'
          textClasses='bg-black text-white'
          infoClasses='h-4 w-4 mini:h-6 mini:w-6 text-purple'
          iconType='alter'
        >
          <span className='tooltip-text bg-black text-white -ml-28 -mt-18 rounded px-6 w-64'>
            {params.tooltipText}
          </span>
        </Tooltip>
        <div className='w-14/20 shrink-0 text-left'>
          <div className='m-0 text-md truncate *:truncate'>{params.title}</div>
          <p className='m-0 text-sm text-gray-500 truncate *:truncate'>
            {params.subtitle}
          </p>
        </div>
        <div className='flex gap-1 w-5/20 shrink-0 flex-wrap md:flex-nowrap'>
          <Button
            type='button'
            onClick={params.buttonCallback}
            variant={`${params.secondaryButtonCallback ? 'empty-with-border-sm' : 'purple-with-border-sm'}`}
            classes={`${params.secondaryButtonCallback ? 'w-full md:w-1/2' : 'w-full'} !px-1 md:py-3`}
          >
            {params.buttonText}
          </Button>

          {params.secondaryButtonCallback ? (
            <Button
              type='button'
              onClick={params.secondaryButtonCallback}
              variant='purple-with-border-sm'
              classes={`${params.secondaryButtonCallback ? 'w-full md:w-1/2' : 'w-full'} !px-1 md:py-3`}
            >
              {params.secondaryButtonText}
            </Button>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

const TaskList = () => {
  const {
    data: viewerAccount,
    isFetching: isFetchingViewerAccount,
    isError: isErrorViewerAccount
  } = useAccount('me');
  const {
    data: { tasks },
    isFetching: isFetchingTasks,
    isError: isErrorTasks,
    refetch: refetchAccountTasks
  } = useAccountTasks('me');
  const {
    reviews: reviewsDue,
    isFetching: isFetchingReviewsDue,
    isError: isErrorReviewsDue
  } = useReviewsDue();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    pulseReviews,
    isFetching: isFetchingPulseReviewsDue,
    isError: isErrorPulseReviewsDue
  } = usePulseReviewsDue();

  const queryClient = useQueryClient();
  useEffect(
    async () => queryClient.refetchQueries([ACCOUNT_QUERY_KEYS.TASKS]),
    []
  );

  const [revieweeId, setRevieweeId] = useState(null);

  if (isFetchingTasks) {
    return (
      <Fragment>
        <HeaderV2 overtitle='Inbox' title='Tasks' />
        <div className='w-full h-full flex flex-col gap-3 items-center justify-center'>
          <SpinnerSVG className='w-8 h-8' />
        </div>
      </Fragment>
    );
  }

  const isFetching = isFetchingCompany
    || isFetchingTree
    || isFetchingTasks
    || isFetchingReviewsDue
    || isFetchingPulseReviewsDue
    || isFetchingViewerAccount;
  const isError = isErrorCompany
    || isErrorTree
    || isErrorTasks
    || isErrorReviewsDue
    || isErrorPulseReviewsDue
    || isErrorViewerAccount;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const tasksToDo = [];

  if (reviewsDue.length > 0) {
    tasksToDo.push({
      type: TASK_TYPES.REVIEW_REQUESTS,
      data: reviewsDue
    });
  }

  if (pulseReviews.length > 0) {
    tasksToDo.push({
      type: TASK_TYPES.OPEN_PULSE,
      data: pulseReviews
    });
  }

  tasksToDo.push(...tasks);
  return (
    <Fragment>
      <HeaderV2 overtitle='Inbox' title='Tasks' />
      <Base classes={STYLE.BASE}>
        {tasksToDo.length ? (
          <Fragment>
            {revieweeId ? (
              <GiveFeedbackModal
                view={FEEDBACK_TYPE.FEEDBACK}
                revieweeId={revieweeId}
                close={async () => {
                  toast.show('Feedback submitted!');
                  await refetchAccountTasks();
                  setRevieweeId(null);
                }}
              />
            ) : null}
            <div className='w-full mini:w-18/20 full:w-14/20 mx-auto'>
              <Base
                classes={
                  STYLE.CONTAINER_WHITE_PADDINGLESS_MARGINLESS_SHADOWLESS
                }
              >
                {tasksToDo.map((task, index) => {
                  const isTopRounded = index === 0;
                  const isBottomRounded = index === tasksToDo.length - 1;

                  let rounded;
                  if (isTopRounded) rounded = 'top';
                  if (isBottomRounded) rounded = 'bottom';
                  if (isTopRounded && isBottomRounded) rounded = 'top bottom';

                  const border = 'sides bottom';

                  let callback = () => {};
                  if (task.type === TASK_TYPES.REVIEW_DIRECT_REPORTS) {
                    const { _id } = task.data;
                    callback = () => route(
                      `/dashboard/submit-feedback/feedback?revieweeId=${_id}`
                    );
                  }

                  return renderTask(
                    task,
                    {
                      rounded,
                      border,
                      tree,
                      viewerAccount
                    },
                    callback
                  );
                })}
              </Base>
            </div>
          </Fragment>
        ) : (
          <div className='w-full h-full flex flex-col gap-3 items-center justify-center'>
            <CircledCheckmarkSVG className='w-12 h-12' color='#5B7FFF' />
            <p className='text-purple text-xl font-medium leading-8'>
              You're up to date!
            </p>
          </div>
        )}
      </Base>
    </Fragment>
  );
};

export default TaskList;
