import uuidv4 from 'uuid/v4';
import { get, isEmpty } from 'lodash';
import datadog from 'src/utils/datadog';
import { appUtils, toast } from 'src/components';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonUtils from 'common/commonUtils';
import api from 'src/services/api';
import { queryClient } from 'src/index';
import { APP_CONSTANTS, CONSTANTS } from '../constants';
import { sharedDateUtils } from '../common';

const {
  LOGIN_TYPES,
  PUBLIC_PROFILE_IMAGES_URL,
  APP_SIZES,
  MOBILE_SIZES,
  ONBOARDING_STATUS,
  WORKSTORY_LOGO_WHITE_PATH,
  WORKSTORY_LOGO_BLACK_PATH
} = COMMON_CONSTANTS;

const utils = {};

const LOCAL_STORAGE_USER_LOGGED_KEY = 'speedbackUserLogged';

const bucketName = commonUtils.getCloudStorageImagesBucketName(process.env.ENV);

const DEFAULT_PROFILE_PICTURE = `${PUBLIC_PROFILE_IMAGES_URL}/${bucketName}/${'profileImages/ws-circle-logo-colors.png'}`;
const DEFAULT_COMPANY_LOGO = `${PUBLIC_PROFILE_IMAGES_URL}/${bucketName}/${'profileImages/ws-circle-logo-colors.png'}`;

function getCookie(name) {
  const dc = document.cookie;
  const prefix = `${name}=`;
  let begin = dc.indexOf(`; ${prefix}`);
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) return null;
  } else {
    begin += 2;
    var end = document.cookie.indexOf(';', begin);
    if (end == -1) {
      end = dc.length;
    }
  }
  // because unescape has been deprecated, replaced with decodeURI
  // return unescape(dc.substring(begin + prefix.length, end));
  return decodeURI(dc.substring(begin + prefix.length, end));
}

utils.getQueryClient = () => queryClient;

utils.isGoalSettingEnabled = (companyid) => {
  const enabledForCompanies = process.env.GOAL_SETTING_ENABLED.split(',');
  if (enabledForCompanies.includes('*')) {
    return true;
  }
  if (companyid && enabledForCompanies.includes(companyid)) {
    return true;
  }

  return false;
};

utils.removeQueryParametersFromUrl = () => {
  if (window && window.history && window.history.replaceState) {
    window.history.replaceState(null, null, window.location.pathname);
  }
};

utils.isSuperUser = (userLogged = false) => {
  const user = userLogged || utils.getLoggedUser();
  return user && user.isSuperUser;
};

utils.showOrganizationIntroSection = (userLogged) => {
  if (!userLogged) {
    return false;
  }

  if (
    userLogged
    && userLogged.onboarding
    && userLogged.onboarding.setupCompany === ONBOARDING_STATUS.PENDING
  ) {
    return true;
  }
  return false;
};

utils.identifyFSUser = (myTreeRow) => {
  if (window.FS && myTreeRow) {
    FS.identify(myTreeRow.id, {
      ...myTreeRow,
      displayName: myTreeRow.name
    });
    return true;
  }
  return false;
};

utils.anonymizeFSUser = () => {
  if (window.FS && window.FS.anonymize) {
    FS.anonymize();
  }
};

utils.notionRedirectFS = (name) => {
  if (window.FS) {
    FS.event('Notion Redirect Hit', {
      name
    });
  }
};

utils.customEventLoggedUserFS = (eventName, myTreeRow) => {
  if (window.FS) {
    FS.event(eventName, {
      email: myTreeRow.email,
      myTreeRow
    });
  }
};

utils.customEventStartWalkthroughFS = (pageName) => {
  if (window.FS) {
    FS.event('Clicked Walkthrough', {
      page: pageName
    });
  }
};

utils.customEventFS = (eventName, eventObject) => {
  const data = eventObject || {};
  if (window.FS) {
    const result = window.FS.event(eventName, data);
  }
};

utils.encodeURIString = (str) => encodeURIComponent(str.replace(/\./g, '&#46;'));

utils.decodeURIString = (str) => decodeURIComponent(str.replace(/&#46;/g, '.'));

// gets profile information from googleUser object (which is equal to auth2.currentUser.get())
utils.getUserProfileFromGoogleSSO = (googleUser) => {
  const profile = googleUser.getBasicProfile();
  const { id_token } = googleUser.getAuthResponse();
  return {
    loginType: CONSTANTS.LOGIN_TYPE.GOOGLE,
    googleId: profile.getId(),
    fullName: profile.getName(),
    givenName: profile.getGivenName(),
    familyName: profile.getFamilyName(),
    imageUrl: profile.getImageUrl(),
    email: profile.getEmail(),
    id_token
  };
};

utils.getImageUrl = (imageUrl) => {
  if (imageUrl) {
    if (!imageUrl.startsWith('https://lh3.googleusercontent.com/')) imageUrl = `${PUBLIC_PROFILE_IMAGES_URL}/${bucketName}/${imageUrl}`;
    return imageUrl;
  }
  return DEFAULT_PROFILE_PICTURE;
};

utils.getRandomString = (length) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

utils.isFullSize = () => /\/dashboard\/*/.test(window.location.href);

utils.isOnReviewPage = () => /\/dashboard\/email-review\//.test(window.location.href);

utils.isSidebarMinimized = () => {
  const isOnEmailReviewPage = /\/dashboard\/review\/[a-zA-Z0-9]+\/[0-9]+/.test(
    window.location.href
  );
  if (window.innerWidth < APP_CONSTANTS.FORCE_MINIMUM_DASH_SIDEBAR_AT_WIDTH) {
    return true;
  }
  if (isOnEmailReviewPage) {
    return true;
  }
  return false;
};

window.autoLogoutEnabled = false;
utils.setAutologout = (secondsLeft) => {
  try {
    if (window.autoLogoutEnabled) {
      return;
    }
    setTimeout(
      () => {
        toast.show('Session expired, logging out!');
        setTimeout(() => {
          console.log('session expired, logging user out..');
          utils.logUserOut();
          window.autoLogoutEnabled = false;
        }, 2000);
      },
      (secondsLeft - 6) * 1000
    );
    window.autoLogoutEnabled = true;
  } catch (error) {
    console.error('appUtils.setAutologout error', error, {
      secondsLeft
    });
    throw error;
  }
};

utils.initPendo = (user) => {
  try {
    if (!window.pendo) {
      return;
    }
    const isUserImpersonated = get(user, 'isSuperUser.email') === user.email;
    if (isUserImpersonated) {
      console.log('Pendo not recording impersonated users.');
      return true;
    }
    console.log('Initializing Pendo');
    window.pendo.initialize({
      visitor: {
        id: user.id,
        email: user.email,
        full_name: user.name,
        role: user.access,
        companyid: user.companyid,
        isSuperUser: user.isSuperUser,
        loginType: user.loginType,
        status: user.status,
        env: process.env.ENV
      },
      account: {
        // account id represents the companyid
        id: user.companyid
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
      }
    });
  } catch (error) {
    console.error('initPendo', error);
    throw error;
  }
};

utils.initClaritySession = (user) => {
  try {
    if (!window.clarity) {
      return;
    }
    const {
      id: userId,
      companyid,
      email,
      title,
      firstName,
      lastName,
      msTeamsEmail,
      access,
      preferredCommunication
    } = user;
    window.claritySessionId = uuidv4();
    window.clarity('set', 'userId', userId);
    window.clarity('set', 'companyid', companyid);
    window.clarity('set', 'email', email);
    window.clarity('set', 'title', title);
    window.clarity('set', 'firstName', firstName);
    window.clarity('set', 'lastName', lastName);
    window.clarity('set', 'msTeamsEmail', msTeamsEmail);
    window.clarity('set', 'access', access);
    window.clarity('set', 'preferredCommunication', preferredCommunication);
    window.clarity('set', 'claritySessionId', window.claritySessionId);
  } catch (error) {
    console.error('appUtils.initClaritySession', error);
    throw error;
  }
};

utils.saveLoggedUser = (user) => {
  localStorage.setItem(LOCAL_STORAGE_USER_LOGGED_KEY, JSON.stringify(user));
  const {
    jwt: { expires }
  } = user;
  utils.initClaritySession(user);
  utils.initPendo(user);
  datadog.setUser(user);
};

// gets logged in user data from local storage
utils.getLoggedUser = () => {
  try {
    const localStorageUserLogged = localStorage.getItem(
      LOCAL_STORAGE_USER_LOGGED_KEY
    );
    let result = {};
    if (
      localStorageUserLogged
      && localStorageUserLogged !== 'undefined'
      && localStorageUserLogged !== 'null'
      && localStorageUserLogged !== 0
      && localStorageUserLogged !== null
    ) {
      result = JSON.parse(localStorageUserLogged);
      if (result) {
        const { loginType } = result;
        if (
          loginType === LOGIN_TYPES.SPEEDBACK
          || loginType === LOGIN_TYPES.SPEEDBACK_INVITED
        ) {
          const {
            jwt: { expires }
          } = result;
          const unixNow = sharedDateUtils.getUnixDateNow();
          if (unixNow > expires) {
            console.log('***** token expired, log user out *****');
            utils.logUserOut();
            return null;
          }
          const secondsLeft = expires - unixNow;
          utils.setAutologout(secondsLeft);
        }
        return result;
      }
    }
    return result;
  } catch (error) {
    console.error('appUtils.getLoggedUser error', error);
    return null;
  }
};

utils.isLoggedIn = () => {
  const loggedUser = utils.getLoggedUser();
  return !isEmpty(loggedUser);
};

utils.getStrippedLoggedUser = () => {
  try {
    const isLoggedIn = utils.isLoggedIn();
    const loggedUser = utils.getLoggedUser();
    if (!isLoggedIn) {
      return null;
    }
    return {
      id: get(loggedUser, 'id'),
      companyid: get(loggedUser, 'companyid'),
      email: get(loggedUser, 'email'),
      access: get(loggedUser, 'access'),
      name: get(loggedUser, 'name'),
      loginType: get(loggedUser, 'loginType'),
      preferredCommunication: get(loggedUser, 'preferredCommunication'),
      isSuperUser: get(loggedUser, 'isSuperUser')
    };
  } catch (error) {
    console.error('appUtils.getStrippedLoggedUser error', error);
    throw error;
  }
};

utils.isOnOrganizationPage = () => {
  if (
    window
    && window.location
    && window.location.pathname
    && window.location.pathname === '/dashboard/organization/chart'
  ) {
    return true;
  }

  return false;
};

utils.isOnDashboardPage = () => {
  if (
    window
    && window.location
    && window.location.pathname
    && window.location.pathname === '/dashboard/'
  ) {
    return true;
  }

  return false;
};

utils.scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

utils.scrollToIntroVideo = () => {
  const feedbackSectionEl = document.getElementById('intro-video');
  if (feedbackSectionEl) {
    feedbackSectionEl.scrollIntoView({ behavior: 'smooth' });
    // setTimeout(() => {
    //   history.replaceState(null, null, ' ');
    // }, 2000)
    return true;
  }
  return false;
};

utils.scrollToTestimonials = () => {
  const feedbackSectionEl = document.getElementById('testimonials');
  if (feedbackSectionEl) {
    feedbackSectionEl.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      history.replaceState(null, null, ' ');
    }, 2000);
    return true;
  }
  return false;
};

utils.scrollToDashboardFeedbackSection = () => {
  const feedbackSectionEl = document.getElementById('feedback-section');
  if (feedbackSectionEl) {
    feedbackSectionEl.scrollIntoView({ behavior: 'smooth' });
    return true;
  }
  return false;
};

utils.getOffsetOfElement = (el) => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

// removes logged in user data from local storage
utils.logUserOut = (redir = null) => {
  api.abort(['get']);

  // localStorage.setItem(LOCAL_STORAGE_USER_LOGGED_KEY, null);
  localStorage.removeItem(LOCAL_STORAGE_USER_LOGGED_KEY);
  const { pathname, search } = window.location;
  if (pathname !== '/login') {
    window.location.href = redir || `/login?redirect=${pathname}${search}`;
  }
};

window.addEventListener('storage', (e) => {
  try {
    const isLocalStorage = e.storageArea === localStorage;
    if (!isLocalStorage) {
      return;
    }
    const isAuthToken = e.key === 'speedbackUserLogged';
    const { newValue } = e;
    const { oldValue } = e;

    // validate that the new value is a valid JSON
    // try {
    //   console.log('parsing....')
    //   JSON.parse(newValue);
    // } catch (err) {
    //   console.error('appUtils.addEventListener(storage) caught invalid JSON', err);
    //   return utils.logUserOut();
    // }

    // Something on another page changed the stored value.
    if (isAuthToken && !newValue) {
      console.log('event listener storage - log user out', {
        oldValue,
        newValue
      });
      utils.logUserOut();
    }

    if (isAuthToken) {
      if (newValue && oldValue && newValue !== oldValue) {
        const newParsed = JSON.parse(newValue);
        const oldParsed = JSON.parse(oldValue);
        const newCompanyid = newParsed.companyid;
        const oldCompanyid = oldParsed.companyid;
        if (newCompanyid !== oldCompanyid) {
          console.log('superuser switched company, redirecting to /dashboard');
          window.location.replace(appUtils.getHomeRoute());
        }
      }
    }
  } catch (error) {
    console.error('appUtils.window.addEventListener(storage)', error);
    utils.logUserOut();
  }
});

utils.setUserImageUrl = (imageUrl) => {
  const user = utils.getLoggedUser();
  user.imageUrl = imageUrl;
  return user;
};

utils.getTimezoneOffset = () => -new Date().getTimezoneOffset();

utils.copyTextToClipboard = (text) => {
  try {
    const fallbackCopyTextToClipboard = (text) => {
      const textArea = document.createElement('textarea');
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        // console.log('Fallback: Copying text command was ' + msg);
        toast.show('Copied!');
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    };

    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      () => {
        // console.log('Async: Copying to clipboard was successful!');
        toast.show('Copied!');
      },
      (err) => {
        console.error('Async: Could not copy text: ', err);
      }
    );
  } catch (error) {
    console.error(
      'appUtils.copyTextToClipboard failed to copy to clip',
      error,
      text
    );
  }
};

const getSubdomain = () => {
  try {
    const { host } = window.location;
    const subdomain = host.split('.')[0];
    return subdomain;
  } catch (error) {
    return null;
  }
};

const getDomain = () => {
  try {
    const { host } = window.location;
    const subdomain = host.split('.')[1];
    return subdomain;
  } catch (error) {
    return null;
  }
};

utils.isOnGetSpeedback = () => {
  const subdomain = getSubdomain();
  const domain = getDomain();
  return domain === 'com' && subdomain === 'getspeedback';
};

utils.canSeeKudos = () => {
  const { companyid } = utils.getLoggedUser();
  if (process.env.ENV === 'prod') {
    if (companyid === '73b63e03-3bcd-4360-8a19-ec0b489a4d7f') return true;
    return false;
  }
  return true;
};

utils.canSendTestReviews = (companyid) => {
  try {
    if (process.env.ENV === 'prod') {
      return [
        '73b63e03-3bcd-4360-8a19-ec0b489a4d7f', // Company 'Demo Organization'
        '117ec32c-a16e-437c-a29f-a68ec770a8bd', // Company 'Slack Testing'
        '25f65752-08ec-4d12-a78a-3d3181b0ac11' // Company 'Webex Testing'
      ].includes(companyid);
    }
    return true;
  } catch (error) {
    console.error('appUtils.canSendTestReviews', error);
    return false;
  }
};

utils.getLoggedUserId = () => {
  const loggedUser = utils.getLoggedUser();
  if (!isEmpty(loggedUser)) return loggedUser.id || loggedUser._id;
  return null;
};

utils.getIsFeedbackTabEnabled = () => true;

utils.getIsOverviewTabEnabled = () => {
  const isLoggedIn = utils.isLoggedIn();
  if (!isLoggedIn) {
    return false;
  }
  const loggedUser = utils.getLoggedUser();
  const { OVERVIEW_TAB_ENABLED_COMPANIES } = process.env;
  const companiesWithOverviewTabEnabled = OVERVIEW_TAB_ENABLED_COMPANIES !== '*'
    ? OVERVIEW_TAB_ENABLED_COMPANIES.split(',')
    : ['*'];
  return companiesWithOverviewTabEnabled.some(
    (companyId) => companyId === '*' || companyId === loggedUser.companyid
  );
};

utils.getDashRoute = (userId = null, tab = null) => {
  const isLoggedIn = utils.isLoggedIn();
  if (!isLoggedIn) {
    return false;
  }

  const isOverviewTabEnabled = utils.getIsOverviewTabEnabled();
  const isMe = userId === utils.getLoggedUserId();

  let tabParam = '';
  if (isOverviewTabEnabled) {
    tabParam = tab || 'overview';
  } else if (tab === 'overview') tabParam = 'feedback';
  else tabParam = tab || 'feedback';

  if (!userId || isMe) return `/dashboard/me/${tabParam}`;
  return `/dashboard/profile/${userId}/${tabParam}`;
};

utils.getHomeRoute = () => {
  const isLoggedIn = utils.isLoggedIn();
  if (!isLoggedIn) {
    return false;
  }

  return '/dashboard/home';
};

utils.getBaseUrl = () => process.env.URL;

utils.getLandingPageUrl = () => process.env.LANDING_PAGE_URL;

utils.getAppSize = () => {
  if (window.innerWidth <= 430) {
    return APP_SIZES.PHONE;
  }
  if (window.innerWidth <= 1024) {
    return APP_SIZES.TABLET;
  }
  if (window.innerWidth <= 1600 || window.innerHeight <= 800) {
    return APP_SIZES.MINI;
  }
  return APP_SIZES.FULL;
};

utils.getMobileSize = () => {
  if (window.innerWidth <= 500 && window.innerHeight < 511) {
    return MOBILE_SIZES.MINI;
  }
  if (window.innerWidth <= 500 && window.innerHeight <= 770) {
    return MOBILE_SIZES.SMALL;
  }
  if (window.innerWidth <= 500 && window.innerHeight <= 800) {
    return MOBILE_SIZES.MEDIUM;
  }
  if (window.innerWidth <= 500 && window.innerHeight > 800) {
    return MOBILE_SIZES.LARGE;
  }
  return false;
};

utils.getAppSizeIndex = () => {
  if (window.innerWidth <= 430) {
    return 0;
  }
  if (window.innerWidth <= 1024) {
    return 1;
  }
  if (window.innerWidth <= 1600 || window.innerHeight <= 800) {
    return 2;
  }
  return 3;
};

utils.getSlackState = () => sessionStorage.getItem('slack-state');

utils.setSlackState = (state) => sessionStorage.setItem('slack-state', state);

utils.getImageSrc = (imagePath) => `${PUBLIC_PROFILE_IMAGES_URL}/${bucketName}/${imagePath}`;

utils.getProfilePictureSrc = (imageUrl) => (imageUrl ? utils.getImageSrc(imageUrl) : DEFAULT_PROFILE_PICTURE);

utils.getCompanyLogoSrc = (logoUrl, defaultVariant = 'white') => {
  if (logoUrl) return utils.getImageSrc(logoUrl);

  if (defaultVariant === 'black') return utils.getImageSrc(WORKSTORY_LOGO_BLACK_PATH);

  return utils.getImageSrc(WORKSTORY_LOGO_WHITE_PATH);
};

utils.getStaticSizes = ({ w = 12, h = 12 }, important = true) => {
  let className = '';

  if (w === h) {
    className += `${important ? '!' : ''}aspect-square`;
  } else {
    className += ` ${important ? '!' : ''}min-w-${w} ${important ? '!' : ''}w-${w} ${important ? '!' : ''}max-w-${w}`;
    className += ` ${important ? '!' : ''}min-h-${h} ${important ? '!' : ''}h-${h} ${important ? '!' : ''}max-h-${h}`;
  }

  return className;
};

export default utils;
