import commonQuestions from 'common/commonQuestions';
import COMMON_CONSTANTS from 'common/commonConstants';
import REVIEW_QUESTIONS from './reviewQuestions';
import SHARED_CONSTANTS from './sharedConstants';
import SHARED_QUESTIONS from './sharedQuestions';

const {
  QUESTIONS, BASE_CATEGORIES, ROLES, ROLES_LIST
} = SHARED_QUESTIONS;
const { SHARE_REVIEW_WITH, EMAIL_FREQUENCY } = COMMON_CONSTANTS;

const utils = {};

utils.randomNumber = (min, max) => Math.random() * (max - min) + min;

utils.randomProperty = (obj) => {
  const keys = Object.keys(obj);
  return obj[keys[(keys.length * Math.random()) << 0]];
};

utils.getRandomReviewQuestion = () => {
  const randomIndex = Math.floor(
    utils.randomNumber(0, REVIEW_QUESTIONS.length)
  );
  const question = REVIEW_QUESTIONS[randomIndex];
  return question;
};

utils.getFilledRandomReviewQuestion = (name) => {
  const randomIndex = Math.floor(
    utils.randomNumber(0, REVIEW_QUESTIONS.length)
  );
  const question = REVIEW_QUESTIONS[randomIndex];
  const html = question.replace('@name', `<b>${name}</b>`);
  return html;
};

utils.getCompanyFrequencyAddition = (companyFrequency) => {
  const frequencyObj = Object.values(EMAIL_FREQUENCY).find(
    (obj) => obj.value === companyFrequency
  );
  if (!frequencyObj) {
    return ' in the last week';
  }
  return ` ${frequencyObj.textForm}`;
};

utils.filloutReviewQuestion = (question, name, frequency = false) => {
  let html = question.replace('@name', `<b>${name}</b>`);
  if (frequency) {
    const text = utils.getCompanyFrequencyAddition(frequency);
    html = html.replace('@frequency', `${text}`);
  }

  return html;
};

utils.getFilledRandomReviewQuestionObject = (name, companyFrequency) => {
  const randomIndex = Math.floor(
    utils.randomNumber(0, REVIEW_QUESTIONS.length)
  );
  const questionObjectSample = REVIEW_QUESTIONS[randomIndex];

  const questionObject = { ...questionObjectSample };
  const { question } = questionObject;

  const frequencyText = utils.getCompanyFrequencyAddition(companyFrequency);
  let html = question.replace('@name', `${name}`);
  html = html.replace('@frequency', `${frequencyText}`);

  questionObject.question = html;
  return questionObject;
};

utils.renderShareCommentsVisibilityOptions = (
  revieweeName,
  isReviewer = true
) => [
  {
    title: 'Open to everyone',
    value: SHARE_REVIEW_WITH.OPEN_TO_EVERYONE,
    comments: `${revieweeName} can see that you’ve written the comment. Comment is shared in public feedback feed.`
  },
  {
    title: `Share with ${revieweeName}`,
    value: SHARE_REVIEW_WITH.OPENLY_WITH_REVIEWEE,
    comments: `${revieweeName} can see ${
      isReviewer ? "you've" : "whoever's"
    } shared this feedback.`
  },
  {
    title: 'Share just the feedback',
    value: SHARE_REVIEW_WITH.REVIEWEE,
    comments: `${revieweeName} can only see your comment.`
  },
  {
    title: `Share with ${revieweeName}'s manager`,
    value: SHARE_REVIEW_WITH.REVIEWEE_MANAGER,
    comments: `${revieweeName}'s manager can see ${
      isReviewer ? "you've" : "whoever's"
    } shared this feedback.`
  },
  {
    title: 'Share anonymously',
    value: SHARE_REVIEW_WITH.ANONYMOUS,
    comments: `${revieweeName}'s manager can only see your comment.`
  }
];

utils.getShareCommentOptions = (
  reviewVisibilitySettings,
  visibilityOptions
) => {
  const options = [];

  if (reviewVisibilitySettings[SHARE_REVIEW_WITH.OPEN_TO_EVERYONE]) {
    options.push(visibilityOptions[0]);
  }
  if (reviewVisibilitySettings[SHARE_REVIEW_WITH.OPENLY_WITH_REVIEWEE]) {
    options.push(visibilityOptions[1]);
  }
  if (reviewVisibilitySettings[SHARE_REVIEW_WITH.REVIEWEE]) {
    options.push(visibilityOptions[2]);
  }
  if (reviewVisibilitySettings[SHARE_REVIEW_WITH.REVIEWEE_MANAGER]) {
    options.push(visibilityOptions[3]);
  }
  if (reviewVisibilitySettings[SHARE_REVIEW_WITH.ANONYMOUS]) {
    options.push(visibilityOptions[4]);
  }

  return options;
};

export default utils;
