import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import { Base } from 'src/components';
import TrendSVG from 'src/assets/svg/trend.svg';
import Category from 'src/containers/Dash/UserFeedback/mobile/Category';
import { useTree } from 'src/queries/tree';
import { useMyProfile } from 'src/pagesDashboard/Dash/queries';
import STYLE from 'src/constants/style';

const MobileUserAnalytics = () => {
  const navigate = useNavigate();
  const {
    bundledCategories,
    isFetching: isFetchingMyProfile,
    isError: isErrorMyProfile
  } = useMyProfile();
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const isFetching = isFetchingMyProfile || isFetchingTree;
  const isError = isErrorMyProfile || isErrorTree;
  const isReady = myTreeRow && myTreeRow.id && !isFetching && !isError;

  if (!isReady) {
    return <Base classes='w-full h-40' loading />;
  }

  return (
    <Base classes={STYLE.MOBILE_CONTAINER}>
      <NavigationItem
        goBack={() => navigate('/dashboard/home')}
        title='My Performance'
      >
        <TrendSVG />
      </NavigationItem>
      <div className='overflow-y-scroll h-65vh'>
        {bundledCategories.map((category, index) => (
          <div>
            <Category category={category} />
            {bundledCategories.length === index + 1
            && window.innerHeight >= 665
            && window.innerHeight <= 783 ? (
              <div className='h-10' />
              ) : null}
            {bundledCategories.length === index + 1
            && window.innerHeight < 665 ? (
              <div className='h-20' />
              ) : null}
          </div>
        ))}
      </div>
    </Base>
  );
};

export default MobileUserAnalytics;
