import React from 'react';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import FlagSVG from 'src/assets/svg/flag.svg';
import Goal from 'src/containers/UserProfile/Goals/components/mobile/Goal';
import {
  Pagination, Button, appUtils, Base
} from 'src/components/';
import { useNavigate, useLocation } from 'react-router-dom';
import STYLE from 'src/constants/style';

const MobileUserGoals = ({
  userId,
  goals,
  currentPage,
  setCurrentPage,
  totalPages
}) => {
  const loggedUserId = appUtils.getLoggedUserId();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <Base classes={STYLE.MOBILE_CONTAINER}>
      <NavigationItem
        goBack={() => navigate('/dashboard/home')}
        title='My Goals'
      >
        <FlagSVG />
      </NavigationItem>
      <div>
        {goals && goals.length > 0 ? (
          <div>
            <div className='overflow-y-scroll h-65vh'>
              {goals.map((goal, index) => (
                <div key={goal._id}>
                  <Goal goal={goal} userId={userId} redirectUrl={pathname} />
                  {goals.length === index + 1
                  && window.innerHeight >= 665
                  && window.innerHeight <= 783 ? (
                    <div className='h-10' />
                    ) : null}
                  {goals.length === index + 1 && window.innerHeight < 665 ? (
                    <div className='h-20' />
                  ) : null}
                </div>
              ))}
            </div>
            <div className='w-full flex flex-col items-center px-6 pb-2 gap-6 mt-1 fixed bottom-0 bg-white'>
              <Pagination
                name={`Dash-goals-${userId}`}
                totalPages={totalPages}
                currentPage={currentPage}
                selectPage={setCurrentPage}
              />
              <Button
                variant='black'
                classes='w-full'
                onClick={() => navigate(`/dashboard/goal/new/${loggedUserId}`)}
              >
                Set New Goal
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <p className='text-center pt-10'>No goals created yet.</p>
            <div className='w-full items-center px-6 pb-2 mt-1 fixed bottom-0 bg-white'>
              <Button
                variant='black'
                classes='w-full'
                onClick={() => navigate(`/dashboard/goal/new/${loggedUserId}`)}
              >
                Set New Goal
              </Button>
            </div>
          </div>
        )}
      </div>
    </Base>
  );
};

export default MobileUserGoals;
