import React, { forwardRef } from 'react';
import './Base.scss';

const Base = forwardRef(
  (
    {
      variant = null,
      children,
      classes = '',
      loading,
      id,
      relative = false,
      ...rest
    },
    ref
  ) => {
    let myClasses = classes;
    if (!variant) {
      // myClasses += ' bg-white';
    }
    if (variant === 'transparent') {
      myClasses += ' no-border bg-transparent shadow-none';
    }

    if (relative) {
      myClasses += ' !relative';
    }

    if (loading) {
      myClasses += ' sectionbox-loading-content';
    }

    return (
      <div className={myClasses} {...(loading ? {} : { ref, id })} {...rest}>
        {children}
      </div>
    );
  }
);

export default Base;
