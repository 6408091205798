import { h, Fragment } from 'preact';
import { useContext, useRef } from 'preact/hooks';
import filterTree from 'src/containers/Organization/Tree/filterTree';
import { Input, XButton } from 'src/components/';
import { OrganizationContext } from 'src/pagesDashboard/Organization/context/OrganizationProvider';
import { updateData } from 'src/pagesDashboard/Organization/context/actions';
import { useTree } from 'src/queries/tree';

const TreeSearch = () => {
  const {
    data: { tree, treeList },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const { state, dispatch } = useContext(OrganizationContext);
  const searchRef = useRef(null);

  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const searchTree = (search) => {
    const displayTree = filterTree({ search, tree, treeList });
    dispatch(updateData({ search, displayManagerId: null, displayTree }));
  };

  const isEmpty = !state.search || state.search === '';

  if (searchRef && searchRef.current && searchRef.current.focus) {
    searchRef.current.focus();
  }

  return (
    <Fragment>
      <Input
        classes={`${
          isEmpty ? '' : ''
        } align-top inline-block ml-2 h-10 align-top`}
        value={state.search}
        onChange={(e) => searchTree(e.target.value)}
        placeholder='Search..'
        ref={searchRef}
      />
      {!isEmpty ? (
        <div className='inline-block align-top'>
          <XButton onClick={() => searchTree('')} />
        </div>
      ) : null}
    </Fragment>
  );
};

export default TreeSearch;
