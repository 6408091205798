import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import COMMON_CONSTANTS from 'common/commonConstants';
import appUtils from 'src/components/appUtils';

const { MOBILE_SIZES } = COMMON_CONSTANTS;

const NavigationItem = ({
  children,
  title = '',
  subtitle = '',
  url = '',
  goBack = false,
  variant = 'default'
}) => {
  const navigate = useNavigate();
  const mobileSize = appUtils.getMobileSize();
  let variantClasses = '';
  const iconClasses = 'h-full';

  if (variant === 'default') {
    variantClasses = 'py-2 h-78px';
  }
  if (variant === 'home') {
    variantClasses = 'py-2 h-78px';
  }

  return (
    <div className='w-full'>
      {goBack ? (
        <button
          type='button'
          onClick={() => goBack()}
          className={`flex w-full items-center text-left mobile-border align-middle ${variantClasses}`}
        >
          <div className='h-4 rounded-sm mr-2 ml-1'>
            <ChevronLeftIcon className='w-5 h-5 text-black' />
          </div>
          <div className='w-14 h-full rounded-lg bg-gray flex items-center justify-center text-black'>
            {children}
          </div>
          <div className='grow'>
            <p className='font-bold mb-1 pl-3 truncate'>{title}</p>
          </div>
        </button>
      ) : (
        <button
          type='button'
          onClick={() => navigate(url)}
          className={`flex px-3 w-full items-center text-left mobile-border ${variantClasses}`}
        >
          <div
            className={`w-14 rounded-lg bg-gray flex items-center justify-center text-black ${iconClasses}`}
          >
            {children}
          </div>
          <div className='grow pl-2'>
            <p className='font-bold mb-0.5'>{title}</p>
            <p className='mb-1 leading-4 text-sm'>{subtitle}</p>
          </div>
          <div className='h-4 rounded-sm'>
            <ChevronRightIcon className='w-5 h-5 text-black' />
          </div>
        </button>
      )}
    </div>
  );
};

export default NavigationItem;
