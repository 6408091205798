import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { Base, Button } from 'src/components/';
import STYLE from 'src/constants/style';
import SpeechBubbleOvalSVG from 'src/assets/svg/speech-bubble-oval.svg';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';

const MobileFeedbackForm = ({ control, isValid, isSubmitting }) => {
  const navigate = useNavigate();
  return (
    <Base classes={STYLE.MOBILE_CONTAINER}>
      <NavigationItem
        goBack={() => navigate('/dashboard/home')}
        title='Give Feedback'
      >
        <SpeechBubbleOvalSVG />
      </NavigationItem>
      <div className='w-full px-3'>
        <p className='mb-1 mt-3'>Share Your Feedback</p>
        <Controller
          name='feedbackText'
          control={control}
          rules={{
            required: {
              value: true,
              message:
                'Please provide a longer comment to answer this question.'
            },
            validate: (value) => {
              if (!value) return false;
              const valueWithNoSpaces = value.replaceAll(' ', '');
              if (valueWithNoSpaces.length < 10) return false;
              return true;
            }
          }}
          render={({ field }) => (
            <textarea
              className='w-full bg-white border-0 rounded-md ring-1 ring-inset ring-gray-300 px-3 h-40vh'
              {...field}
            />
          )}
        />
        <div className='w-full flex flex-col pr-6 items-center pb-2 gap-3 mt-auto fixed bottom-0 mb-0 bg-white'>
          <Button
            classes='w-full'
            disabled={!isValid || isSubmitting}
            variant='black'
            type='submit'
          >
            Submit
          </Button>
          <Button
            variant='custom'
            disabled={isSubmitting}
            classes='bg-white w-full text-black font-bold text-sm px-4 pt-2 pb-2 border rounded-md border-gray-300'
            onClick={() => navigate('/dashboard/home')}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Base>
  );
};

export default MobileFeedbackForm;
