import React, {
  useEffect, useState, useMemo, useCallback
} from 'react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import { get } from 'lodash';
import appUtils from 'src/components/appUtils';
import featureFlags from 'src/utils/featureFlags';
import COMMON_CONSTANTS from 'common/commonConstants';
import { STYLE } from 'src/constants/style';
import { useAllCompanies } from 'src/queries/admin';
import { useAccount } from 'src/queries/account';
import { useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';
import NoticeBanner from 'src/pages/Dashboard/NoticeBanner';
import { Navigate, useParams } from 'react-router-dom';
import { Sidebar, MobileSidebar } from '../../containers';
import { toast, Base } from '../../components';
import { appThunks } from '../../thunks';
import './Dashboard.scss';

const { APP_SIZES, ONBOARDING_STATUS } = COMMON_CONSTANTS;

const Dashboard = (props) => {
  const params = useParams();
  const { deployInProgress: isDeploymentInProgress } = useFlags();
  const { data: userData, refetch: refetchAccount } = useAccount('me');
  const ldClient = useLDClient();
  const { refetch: refetchTree } = useTree();
  const { data: company, refetch: refetchCompany } = useCompany();
  const refetchSidebar = async () => Promise.all([refetchAccount(), refetchTree(), refetchCompany()]);

  const {
    component: RightComponent = null,
    app: { userLogged },
    page,
    dispatch,
    sidebar = true
  } = props;

  useAllCompanies({
    enabled: Boolean(appUtils.isSuperUser(userLogged))
  });

  useEffect(() => {
    refetchSidebar();
  }, []);

  const [isMobile, setIsMobile] = useState(
    appUtils.getAppSize() === APP_SIZES.PHONE || appUtils.getMobileSize()
  );
  useEffect(() => {
    function handleResize() {
      const appSize = appUtils.getAppSize();
      setIsMobile(appSize === APP_SIZES.PHONE || appSize === APP_SIZES.TABLET);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const isValid = get(userData, '_id', false) && get(company, 'id', false);
    if (isValid) {
      featureFlags.identify(ldClient, userData, company);
    }
  }, [userData, company]);

  const [isMobileSidebarExpanded, setIsMobileSidebarExpanded] = useState(false);
  const renderSidebar = useCallback(() => {
    if (isMobile) {
      return (
        <MobileSidebar
          page={page}
          state={{
            isExpanded: isMobileSidebarExpanded,
            setIsExpanded: setIsMobileSidebarExpanded
          }}
        />
      );
    }
    return <Sidebar page={page} />;
  }, [isMobile, isMobileSidebarExpanded, page]);

  const userOnboardingSetupCompany = get(
    userData,
    'onboarding.setupCompany',
    null
  );
  if (
    userOnboardingSetupCompany
    && userData.onboarding.setupCompany === ONBOARDING_STATUS.PENDING
  ) {
    <Navigate to='/dashboard/get-started' />;
  }

  const isLoggedIn = appUtils.isLoggedIn();

  if (!isLoggedIn) {
    const { url } = props;
    appThunks.logOut()(dispatch);
    toast.show('Please login', toast.TYPES.INFO, true);

    if (url) {
      <Navigate to={`/login/?redirect=${url}`} />;
    }
      <Navigate to='/login' />;
  }

  return useMemo(
    () => (
      <div
        className={`min-h-screen h-full flex ${
          isMobile ? 'flex-col' : 'justify-start'
        } w-full`}
      >
        {sidebar && renderSidebar()}
        {(!isMobile || !isMobileSidebarExpanded) && (
          <Base classes={STYLE.CONTENT}>
            {isDeploymentInProgress ? (
              <NoticeBanner message='New features are on the way! You may experience a temporary slowdown over the next 10 minutes.' />
            ) : null}
            <RightComponent
              parentProps={{
                ...props,
                ...params
              }}
            />
          </Base>
        )}
      </div>
    ),
    [
      isDeploymentInProgress,
      isMobile,
      isMobileSidebarExpanded,
      params,
      props,
      renderSidebar,
      sidebar
    ]
  );
};

export const mapStateToProps = (state) => ({
  app: state.appReducer,
  company: state.companyReducer
});

export const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
