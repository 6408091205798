import { h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import { Button, Base } from 'src/components';
import {
  formatNoteText,
  isNoteEmpty
} from 'src/pagesDashboard/NewUserReport/utils';
import Quill from 'src/components/Quill/Quill';

const EditNoteNewReport = ({
  reportId,
  noteId,
  onSave,
  noteText,
  noteType,
  includeText = '',
  clearIncludeText = () => {},
  updateEditNote,
  updateNoteLoading
}) => {
  const noteRef = useRef();

  const [text, setText] = useState(noteText);

  const handleUpdate = () => {
    updateEditNote(false);
  };

  useEffect(() => {
    if (includeText.length) {
      const newText = text + includeText;
      setText(newText);
    }
  }, [includeText]);

  useEffect(() => {
    if (noteRef.current) {
      const { x, y } = noteRef.current.getBoundingClientRect();
      scrollTo(x, window.scrollY + y - 40);
    }
  }, [noteRef.current]);

  return (
    <Base loading={updateNoteLoading} ref={noteRef} id={`edit-note-${noteId}`}>
      <div
        className={`flex flex-col gap-8 w-full bg-hover-gray text-black
        transition-colors duration-300 rounded my-5 h-35rem`}
      >
        <Quill value={text} onChange={setText} className='h-30rem' />
        <div className='mt-10 md:mt-6'>
          <div className='w-1/2 text-left inline-block'>
            <Button
              variant='transparent'
              onClick={() => {
                setText('');
                handleUpdate();
                clearIncludeText();
              }}
            >
              Close
            </Button>
          </div>

          <div className='w-1/2 text-right inline-block'>
            <Button
              classes={`${
                isNoteEmpty(text)
                  ? ''
                  : 'bg-success-green hover:bg-success-green-hover'
              } text-white text-lg px-4 py-2 rounded-sm font-bold answer transition-colors duration-300`}
              variant='custom'
              onClick={() => {
                onSave(formatNoteText(text), noteId, reportId, noteType);
                clearIncludeText();
              }}
              disabled={isNoteEmpty(text)}
            >
              Save note
            </Button>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default EditNoteNewReport;
