import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Base, Modal, Button, toast, appUtils
} from 'src/components';
import {
  QUERY_KEYS as FEEDBACK_QUERY_KEYS,
  useFeedbackUpdate
} from 'src/queries/feedback';

const DeleteFeedbackModal = ({ data: feedback, close }) => {
  const navigate = useNavigate();
  const [reason, setReason] = useState('');
  const queryClient = useQueryClient();

  const { update: updateFeedback, isLoading: isLoadingDeleteFeedback } = useFeedbackUpdate(feedback._id || feedback.id);

  const deleteFn = async () => {
    if (!reason || !reason.length) {
      return toast.show('Reason is required');
    }
    const response = await updateFeedback({
      data: { enabled: false, reason }
    });
    if (!response || !response.success) {
      console.error('Failed to delete a feedback', {
        response,
        feedback
      });
      return toast.error("Oops, we've run into an issue. Try again later!");
    }

    toast.show('Feedback deleted');

    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK);
    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK_FEED);
    return navigate(appUtils.getHomeRoute());
  };

  return (
    <Modal innerClasses='w-34rem pt-64' variant='custom' close={close}>
      <Base variant='transparent' loading={isLoadingDeleteFeedback}>
        <div className='text-left'>
          <h5 className='font-bold'>
            Are you sure you want to delete this feedback?
          </h5>
          <p className='mb-0'>
            Deleted feedback is saved and visible to organization admins.
          </p>
          <div className='mt-5'>
            <span className='inline-block mt-2 mr-4'>Reason:</span>
            <input
              className='inline-block w-52'
              type='text'
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          <div className='mt-8'>
            <Button variant='yellow' onClick={deleteFn}>
              Delete feedback
            </Button>
            <button
              onClick={close}
              className='float-right text-black text-lg px-4 py-2 mr-0 font-bold'
            >
              Close
            </button>
          </div>
        </div>
      </Base>
    </Modal>
  );
};

export default DeleteFeedbackModal;
