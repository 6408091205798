import React, { useContext } from 'react';
import { Base } from 'src/components';
import PreviewInfo from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewInfo';
import PreviewReviews from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewReviews';
import PreviewSummary from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewSummary';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';

const ReportPreview = () => {
  const {
    data: { page }
  } = useContext(PerformanceBuilderContext);
  const isViewPage = page === 'view';

  return (
    <Base
      classes={`${isViewPage ? 'w-full' : 'w-1/2'} h-[calc(100vh-3.5rem)] max-h-32 overflow-y-scroll`}
    >
      {isViewPage ? null : (
        <div className='h-10 text-[#797979] text-center py-2 text-lg bg-[#D9D9D9]'>
          Performance Review Preview
        </div>
      )}
      <div className='px-8 py-8 mb-20'>
        <PreviewInfo />
        <PreviewReviews />
        <PreviewSummary />
      </div>
    </Base>
  );
};

export default ReportPreview;
