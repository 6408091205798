import { h, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import { isEmpty, isNumber } from 'lodash';
import DOMPurify from 'dompurify';
import appUtils from 'src/components/appUtils';
import { Circle, toast } from 'src/components/';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import ClipboardListSVG from 'src/assets/svg/clipboardList.svg';
import AnnotationSVG from 'src/assets/svg/annotation.svg';
import commonQuestionsUtils from 'common/commonQuestionsUtils';
import commonQuestions from 'common/commonQuestions';
import DeleteNoteModal from 'src/containers/Feedback/FeedbackList/components/DeleteNoteModal';
import EditNoteModal from 'src/containers/Feedback/FeedbackList/components/EditNoteModal';
import { useAccount } from 'src/queries/account';
import { sendMessageToAdminsQuery } from 'src/queries/admin';
import { route } from 'preact-router';

const { FEEDBACK_TYPE } = COMMON_CONSTANTS;

const BookmarkSVG = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    stroke='currentColor'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z'
    />
  </svg>
);

const actions = {
  UPDATE: 'update',
  DELETE: 'delete'
};

// feedback/notes/review comments for user 'userData'
const FeedbackList = ({
  list,
  view,
  userId,
  emptyPlaceholder = '',
  onAction,
  actionText,
  showActionRule,
  showShareBtn = true,
  showViewBtn = true
}) => {
  const { mutateAsync: sendMessage } = sendMessageToAdminsQuery();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: userData,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount(userId);

  const loggedUser = appUtils.getLoggedUser();
  const isFetching = isFetchingAccount || isFetchingCompany || isFetchingTree;
  const isError = isErrorAccount || isErrorCompany || isErrorTree;
  const isReady = company
    && company.id
    && tree
    && tree.id
    && !isEmpty(userData)
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  const [selectedItem, setSelectedItem] = useState({
    id: null,
    action: null
  });

  const isMe = userId === loggedUser.id;

  if (!list.length) {
    return (
      <div className='cursor-default mt-16 mb-8 text-center font-bold'>
        {emptyPlaceholder ? (
          <Fragment>
            <div className='w-10 h-10 mx-auto mb-2'>
              <AnnotationSVG />
            </div>
            {emptyPlaceholder}
          </Fragment>
        ) : null}
        {!emptyPlaceholder && view === FEEDBACK_TYPE.REVIEW ? (
          <Fragment>
            <div className='w-10 h-10 mx-auto mb-2'>
              <AnnotationSVG />
            </div>
            {isMe
              ? 'You have no review comments'
              : `${userData.name} has no review comments`}
          </Fragment>
        ) : null}
        {!emptyPlaceholder && view === FEEDBACK_TYPE.NOTE ? (
          <Fragment>
            <div className='w-10 h-10 mx-auto mb-2'>
              <ClipboardListSVG />
            </div>
            {isMe
              ? 'You have no notes'
              : `You have no notes for ${userData.name}`}
          </Fragment>
        ) : null}
        {!emptyPlaceholder && view === FEEDBACK_TYPE.FEEDBACK ? (
          <Fragment>
            <div className='w-10 h-10 mx-auto mb-2'>
              <BookmarkSVG />
            </div>
            {isMe ? 'You have no feedback' : `${userData.name} has no feedback`}
          </Fragment>
        ) : null}
      </div>
    );
  }

  const confirmShare = (reviewRow) => {
    console.error('User clicked Share button', reviewRow, {
      email: loggedUser.email,
      name: loggedUser.name
    });
    const confirm = window.confirm(
      'We will generate a public link for you to share on LinkedIn or other platforms. It will be delivered to your email in a few minutes. Would you like to proceed?'
    );
    if (!confirm) {
      return;
    }
    sendMessage({
      source: 'FeedbackList',
      name: `LOGGED user name: ${loggedUser.name}, user id: ${loggedUser.id}, email: ${loggedUser.email}`,
      email: `Review id: ${reviewRow._id}, review text: ${reviewRow.text}, reviewedDate: ${reviewRow.reviewedDate.toString()}`
    });
    toast.show('We will contact you shortly!');
  };

  const handleShowFeedback = (feedbackId) => route(`/dashboard/feedback/view/${feedbackId}`);

  const handleShowReview = (reviewId) => route(`/dashboard/reviews/view/${reviewId}`);

  return (
    <div className='flex flex-col'>
      {view === FEEDBACK_TYPE.NOTE && selectedItem.action === actions.UPDATE ? (
        <EditNoteModal
          feedbackId={selectedItem.id}
          close={() => setSelectedItem({
            id: null,
            action: null
          })}
        />
      ) : null}
      {view === FEEDBACK_TYPE.NOTE && selectedItem.action === actions.DELETE ? (
        <DeleteNoteModal
          feedbackId={selectedItem.id}
          close={() => setSelectedItem({
            id: null,
            action: null
          })}
        />
      ) : null}
      {list.map((row) => {
        const isNote = row.type === FEEDBACK_TYPE.NOTE;
        const categoryObj = row.categoryId
          ? commonQuestionsUtils.findCategory(company.questions, row.categoryId)
          : null;
        const roleObj = row.roleId
          ? commonQuestions.getRoleById(row.roleId, company.questions)
          : null;

        if (!isNumber(row.date)) row.date = commonDateUtils.dateToUnix(row.date);

        const sanitizedText = () => ({
          __html: DOMPurify.sanitize(row.text)
        });

        return (
          <div className='flex flex-wrap md:flex-nowrap m-3 my-6 items-center'>
            <div className='md:w-1/12 inline-block align-top'>
              <Circle size='sm' imageUrl={row.reviewerImage} />
            </div>
            <div className='md:w-9/12 inline-block align-top text-left'>
              <div className='flex justify-between'>
                <div
                  className='ql-editor w-4/5 p-0 m-0'
                  dangerouslySetInnerHTML={sanitizedText()}
                />
                {isNote ? (
                  <div className='flex items-start justify-end gap-3 max-w-2/20'>
                    <button
                      className='text-purple focus:outline-none hover:underline'
                      onClick={() => setSelectedItem({
                        id: row._id ?? row.id,
                        action: actions.UPDATE
                      })}
                    >
                      Edit
                    </button>
                    <button
                      className='text-purple focus:outline-none hover:underline'
                      onClick={() => setSelectedItem({
                        id: row._id ?? row.id,
                        action: actions.DELETE
                      })}
                    >
                      Delete
                    </button>
                  </div>
                ) : null}
              </div>
              <div className='mb-0 text-light-gray'>
                <span>
                  {commonDateUtils
                    .unixToMonthDayYearTimeFormat(row.date)
                    .toString()}
                </span>
                {row.visibleText ? (
                  <Fragment>
                    <span className='mx-2 text-5xl'>&middot;</span>
                    <span>{row.visibleText}</span>
                  </Fragment>
                ) : null}

                {categoryObj ? (
                  <Fragment>
                    <span className='mx-2 text-5xl'>&middot;</span>
                    <span className='mb-0 text-sm'>{`Category: ${categoryObj.label}`}</span>
                  </Fragment>
                ) : null}
                {roleObj ? (
                  <Fragment>
                    <span className='mx-2 text-5xl'>&middot;</span>
                    <span className='mb-0 text-sm'>{`Role: ${roleObj.label}`}</span>
                  </Fragment>
                ) : null}
                {row.question ? (
                  <p className='italic mb-0 text-sm'>{`Question: ${row.question}`}</p>
                ) : null}
                {row.revieweeName ? (
                  <p className='italic font-bold mb-0 text-sm'>
                    {`Reviewee: ${row.revieweeName}`}
                  </p>
                ) : null}
                <div className='flex gap-2'>
                  {row.reviewerName ? (
                    <p className='italic font-bold mb-0 text-sm'>
                      {`Author: ${row.reviewerName}`}
                    </p>
                  ) : null}
                  {showShareBtn ? (
                    <button
                      className='hover:underline focus:outline-none font-bold mb-0 text-sm underline'
                      onClick={() => confirmShare(row)}
                    >
                      Share
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='md:w-2/12 flex justify-center shrink-0 grow-0'>
              {showViewBtn ? (
                <button
                  className='hover:underline focus:outline-none'
                  onClick={() => {
                    if (row.collection === 'reviews') return handleShowReview(row._id || row.id);
                    handleShowFeedback(row._id || row.id);
                  }}
                >
                  View
                </button>
              ) : null}
            </div>
            {onAction && showActionRule(row) ? (
              <div className='inline-block align-top text-left w-auto'>
                <button
                  type='button'
                  onClick={() => onAction(row._id || row.id)}
                  className='bg-success-green text-xs hover:bg-success-green-hover whitespace-nowrap transition-colors duration-300 px-2 py-2 rounded text-white bold'
                >
                  {actionText}
                </button>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default FeedbackList;
