import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import PAGE_ROUTES from 'src/constants/pageRoutes';
import FlagSVG from 'src/assets/svg/flag.svg';
import DOMPurify from 'dompurify';

const MobileGoal = ({ goal, redirectUrl }) => {
  const navigate = useNavigate();
  return (
    <button
      type='button'
      onClick={() => navigate(
        `/dashboard/goal/view/${goal.assignee}/${goal._id}?redir=${
          redirectUrl || PAGE_ROUTES.ORG_GOALS_ROUTE
        }`
      )}
      className='flex w-full items-center text-left mobile-border px-3 py-2 h-22'
    >
      <div className='flex w-full h-14 items-center text-left'>
        <div className='w-59px h-full rounded-lg bg-gray flex items-center justify-center text-black shrink-0'>
          <FlagSVG />
        </div>
        <div className='w-76% pl-3'>
          <p className='font-bold mb-0.5 truncate'>{goal.title}</p>
          <div
            className='mb-0 mobile-paragraph truncate'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(goal.description)
            }}
          />
        </div>
        <div className='h-6 rounded-sm mr-2'>
          <ChevronRightIcon className='w-5 h-5 text-black' />
        </div>
      </div>
    </button>
  );
};

export default MobileGoal;
