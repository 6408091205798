import { h } from 'preact';
import DOMPurify from 'dompurify';
import { route } from 'preact-router';
import { useRef, useEffect } from 'preact/hooks';
import { Base, appUtils } from 'src/components/index';
import { isEmpty } from 'lodash';
import { useReviews, useReviewsV2 } from 'src/queries/reviews';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonDateUtils from 'common/commonDateUtils';
import Stars2SVG from 'src/assets/svg/stars-2.svg';
import STYLE from 'src/constants/style';

const { REVIEW_STATUS } = COMMON_CONSTANTS;

const HomeHighlights = () => {
  const loggedUserId = appUtils.getLoggedUserId();

  const reviewedDateStart = useRef(commonDateUtils.getUnixDateFromDaysAgo(30));
  useEffect(() => {
    reviewedDateStart.current = commonDateUtils.getUnixDateFromDaysAgo(30);
  }, []);

  // const {
  //   data: reviews,
  //   isFetching: isFetchingReviews,
  //   isError: isErrorReviews
  // } = useReviewsV2(
  //   {
  //     reviewees: [loggedUserId],
  //     status: [REVIEW_STATUS.REVIEWED],
  //     reviewedDate: {
  //       start: reviewedDateStart.current,
  //       end: null
  //     },
  //     commentsLength: {
  //       min: 10
  //     }
  //   },
  //   {
  //     sort: {
  //       field: 'sentiment.score',
  //       order: 'desc'
  //     },
  //     page: {
  //       size: 1
  //     }
  //   }
  // );

  const {
    data: { reviews },
    isFetching: isFetchingReviews,
    isError: isErrorReviews
  } = useReviews({
    userId: loggedUserId,
    filters: {
      start: reviewedDateStart.current
    },
    options: {
      page: 1,
      size: 1,
      sortBy: 'sentiment.score',
      sortOrder: 'desc'
    }
  });

  const isFetching = isFetchingReviews;
  const isError = isErrorReviews;
  const isReady = !isFetching && !isError;
  if (!isReady) return null;

  const review = !isEmpty(reviews) ? reviews[0] : null;

  if (!review || review.sentiment.score < 50) return null;

  const sanitizedText = () => ({
    __html: DOMPurify.sanitize(review.comments)
  });

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <div className='w-full'>
        <div className='flex gap-2'>
          <Stars2SVG className='h-6 w-6 my-auto' />
          <p className='my-2 font-semibold text-xl text-black leading-8'>
            Highlights
          </p>
        </div>
      </div>
      <div className='flex flex-col w-full bg-[#F9F9F9] rounded-md py-2 px-4 my-2'>
        <p className='mt-1 mb-[2px] text-md text-purple font-semibold'>
          Feedback worth checking out
        </p>
        <div
          className='ql-editor p-0 text-md font-medium'
          dangerouslySetInnerHTML={sanitizedText()}
        />
        <div className='flex w-full justify-between items-center p-1 mt-2'>
          <p className='text-md font-semibold text-[#6B7280] m-0'>
            {commonDateUtils.dateToMonthDayYearFormat(
              new Date(review.reviewedDate)
            )}
          </p>
          <button
            type='button'
            onClick={() => route(appUtils.getDashRoute())}
            className='p-1 text-[#6B7280] rounded-md border border-[#6B7280] py-1 text-sm font-bold place-self-end'
          >
            Go to Feedback
          </button>
        </div>
      </div>
    </Base>
  );
};
export default HomeHighlights;
