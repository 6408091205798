import React, { useContext, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import {
  Button, toast, PickDate, Select
} from 'src/components';
import {
  useCreateBuilderReport,
  useUpdateBuilderReport,
  QUERY_KEYS as BUILDER_QUERY_KEYS
} from 'src/queries/builder';
import BuilderContainer from 'src/pages/PerformanceBuilder/containers/BuilderContainer';
import appUtils from 'src/components/appUtils';
import commonUtils from 'common/commonUtils';
import commonDateUtils from 'common/commonDateUtils';
import builderUtils from 'src/pages/PerformanceBuilder/builderUtils';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useNavigate } from 'react-router-dom';

const { USER_STATE } = COMMON_CONSTANTS;

const excludedReviewerIds = [
  'ffd02c31-10d0-4114-9f92-3ce510ac1a4c',
  'fdc1e432-249d-442d-ab63-237dc47dfc97',
  'faf6a1fa-f4ab-4bed-886d-e772e5fe4338',
  'f71ce286-68d5-4cf1-a891-93d90ca44756',
  'ee0a02bb-3598-4c2e-aa5f-6eb1f0fe9b11',
  'ed596761-1281-408d-b862-a99d5e3d244f',
  'ec29be17-32cb-433d-b599-b1a5844ccdac',
  'eadf4844-566f-4145-b8b7-f5a9100d7c15',
  'e984cc9e-23e6-497a-be14-a897bba2bf48',
  'e901b984-f17f-485e-ae4a-219ba80ea2fb',
  'e8a5b76d-7ced-472a-8b82-883385ab5779',
  'e6711834-f946-45fa-a824-6684f9bd25fb',
  'e66cffbb-df0e-44ec-b332-49acebfa596e',
  'e1075f3b-8705-4a0e-a3aa-633baa050ebe',
  'dc388cff-f942-4319-aac4-8b436244ae85',
  'dacdeefd-fcac-4ec0-9992-200a369b333e',
  'd70dc5be-e569-4803-95d1-2b11eb50c4a1',
  'd44eae76-1b8d-47b7-88f3-8c1aea601a87',
  'd430e158-3871-4861-92ed-a573b843e535',
  'd3badf08-e6e8-4f51-9976-0cadc987f44c',
  'cd9069fa-8551-4ef2-b088-e3b5076fd44c',
  'c9ede8be-8ca6-4749-a310-0df41a8f1f95',
  'c9e0d7be-372a-44c3-8c52-c952034bf33e',
  'c8e0ffd3-63bc-4122-823f-a7170afa8d20',
  'c71d832c-6606-4d62-83bc-d7d396da0d35',
  'c6a42245-0fe1-421d-a063-442aae815c06',
  'bf4e81d1-a41a-4970-bc8a-58733436c61a',
  'bcc1e661-8b20-423e-a12a-14e33559f0bc',
  'bb083c80-3f4f-4a2b-8ee7-fd3cbca33e79',
  'b9f12c0f-a15a-4899-8371-cd8a0a9276c5',
  'b932fc2c-4bc3-4f4f-a1dc-bc35ee46a571',
  'b645f848-d6df-45e0-812f-cbab72a2d563',
  'b30fb4db-947e-440b-a308-ae64371f6649',
  'b21f7b30-9497-4f1a-b3db-1a5bb44da1bb',
  'ac1a7457-9f77-44cb-9934-aeccc10fa5ba',
  'ab1ef1d8-a16a-48b2-9dd0-beb84e1feca8',
  'ab192fc0-9e7e-4744-a0ad-a1ef1db8edf9',
  'a4720a76-89ef-4325-97f2-d033dc582807',
  'a3c2716c-3b24-4034-b66f-682e7e33dc24',
  'a2473b89-1f94-4811-8d89-e0fe7bbec482',
  '9f145e35-f565-4058-a37f-eac57ead4701',
  '99ae67b5-bbf4-4a82-97c2-c64406e95eff',
  '996f2ca4-bf85-4ef2-980d-0e2748753d1f',
  '947573a1-3624-46c1-9c4b-490d9b658dc8',
  '92f1ce5c-7fa5-49a8-8cd0-ed31899149db',
  '86463389-d71a-4f47-bee4-66807722424c',
  '84d0cb55-161c-4c62-8ad0-9ec3f6a5b812',
  '84a13263-9246-4768-954c-0551ab0a606b',
  '843ecac8-f04a-4ba6-ab33-158f94d19172',
  '837b7ebc-f399-4f85-b669-ceae6731ca69',
  '82d24c5e-842d-41fe-850e-374965552276',
  '820dfb3a-127e-4012-b50a-1c71d44792d4',
  '81bda13a-bf0f-4994-b37e-4661a9055122',
  '7ed1c721-d68e-4c0a-8e6f-c2498dae5c57',
  '7d6344a4-402e-4f20-910b-497fdf034c98',
  '7c8d68a1-3011-4c74-a904-1a6f592c6a84',
  '7c24bcb4-c053-477c-9644-cf34059dfe38',
  '7aa4e53e-ce7d-48c9-8a23-fba2dd63891c',
  '797be142-27de-4042-af08-85201d34200b',
  '7818f37e-720f-4e70-a2cc-3e3b2974a6a9',
  '766a361f-4cfc-46a9-ac9c-1ab7866686b3',
  '760e90e0-237c-444b-8d78-d18335598496',
  '71e8c704-445a-4b5f-87ee-b89a7475a3c8',
  '70d4b5ca-872d-4639-a1f9-03ca23095349',
  '6dab3fa3-eba4-4d75-a4fb-2a94e9bc7f83',
  '6d8e30ec-d040-4ceb-b036-cd24865bb197',
  '67f1d354-8b97-4b96-ab26-887a7abb396f',
  '61830c5f-0d47-4812-93a0-d48ebc6efa2f',
  '5742740d-7f70-49a5-9504-c55d50c5a965',
  '53de5a4e-925f-49d6-b000-776526023719',
  '53493bbc-7754-4f5c-bb37-9222ba8846db',
  '51bd1ea8-da4d-498c-9cfd-8866d3c66303',
  '51aafbb4-6407-4d8e-95d7-296e518de324',
  '510100a1-8277-47f4-85d7-9ffc3af072ae',
  '50c48668-ca12-4fb7-b965-3947c700fc83',
  '50b2a901-4bea-4bd2-96ef-38a19a866414',
  '4f6bfa93-2b1d-4b94-b0f5-d069cb0f2504',
  '4bbe9e89-7aa3-4821-802e-a928f45b8013',
  '4b496d23-d413-4c01-8850-70563b08094d',
  '4b287f2b-5ef2-43a8-adc9-46a534b643cb',
  '4a596d15-8fa3-4344-903e-754893031dd0',
  '4304703b-9110-4caa-b0b8-06b03d946505',
  '3f80e705-a306-4358-88e1-c539adbb080f',
  '3d167dc6-30a4-4099-abb6-950ec70e2baf',
  '3bee3f3b-5c57-4693-a015-aa0e81347437',
  '39a72f14-b874-493f-b13c-b18c18e20ec7',
  '355aac06-7945-4b4b-b60e-d339246b8752',
  '333ae08d-6632-40cf-b377-c6b0b4431ca9',
  '332e5b04-f04d-4130-b1e3-045de0ea3f11',
  '3134ab82-d3d6-42e6-a9ea-ba45bdbc2eef',
  '30f2ecd9-928d-40de-810c-00faca7ff816',
  '2588815b-0a19-47c2-a048-523e12b8200f',
  '22f63667-fdca-435b-9286-519c12bc8bf3',
  '210e5190-4d26-4d67-8f7a-974ebe4cbc77',
  '1fa84bb7-4e2b-40ef-bd45-4484b137e3cf',
  '18ed886b-088b-4e3f-bd5f-5bf542f142ab',
  '168d1b5b-bf0b-45b2-8d30-40ead80ddd87',
  '13775100-505c-4f18-b168-2acd12736d2b',
  '13665187-2620-4784-97f2-2e8feb37b495',
  '0f6b1734-f271-479b-8508-3de0f19daaaf',
  '0d3483ce-88bc-4399-bbac-2543193e71e5',
  '0d24e4e1-f467-4399-b993-a243820376f6',
  '0acaeec9-5240-4eb1-9ab5-67e99d5141bd',
  '09da22a0-afad-402c-b077-82a2b9bedb4d',
  '089ac00e-86c5-4b28-b3e5-1636eecad3cc',
  '06378727-e9c1-4b44-a055-29ce58b7dfb2',
  '03d38e2b-b49b-443b-9523-5b5792f58efc',
  '00ec7722-509b-4d18-9964-bfdf899b2fc8'
];
const excludedRevieweeIds = [
  'ffd02c31-10d0-4114-9f92-3ce510ac1a4c',
  'e984cc9e-23e6-497a-be14-a897bba2bf48',
  'e6711834-f946-45fa-a824-6684f9bd25fb',
  'e50e7518-78dd-47b0-a319-6b3d1cf6a29a',
  'e2b7f41f-d65d-4896-8042-1bc084e5ca9a',
  'd430e158-3871-4861-92ed-a573b843e535',
  'cd9069fa-8551-4ef2-b088-e3b5076fd44c',
  'bf4e81d1-a41a-4970-bc8a-58733436c61a',
  'bb083c80-3f4f-4a2b-8ee7-fd3cbca33e79',
  'a4dc7e6f-3073-4369-9a02-95a4fd121680',
  '9f145e35-f565-4058-a37f-eac57ead4701',
  '947573a1-3624-46c1-9c4b-490d9b658dc8',
  '86c11444-400e-4b84-b226-6102350bae82',
  '85957cb9-16fe-4fab-b164-5d9822cf3c97',
  '827c507c-d709-4643-8ffd-3692d5aed0c1',
  '797be142-27de-4042-af08-85201d34200b',
  '70d4b5ca-872d-4639-a1f9-03ca23095349',
  '67f1d354-8b97-4b96-ab26-887a7abb396f',
  '6333758b-c83a-4af4-8e0e-71aa3bc49668',
  '53de5a4e-925f-49d6-b000-776526023719',
  '4bbe9e89-7aa3-4821-802e-a928f45b8013',
  '4b287f2b-5ef2-43a8-adc9-46a534b643cb',
  '4304703b-9110-4caa-b0b8-06b03d946505',
  '3d167dc6-30a4-4099-abb6-950ec70e2baf',
  '3bee3f3b-5c57-4693-a015-aa0e81347437',
  '30f2ecd9-928d-40de-810c-00faca7ff816',
  '13775100-505c-4f18-b168-2acd12736d2b',
  '0d24e4e1-f467-4399-b993-a243820376f6',
  '09b7e8e8-8cd3-4f7e-aa27-12acf7b6055f',
  '06378727-e9c1-4b44-a055-29ce58b7dfb2',
  '03d38e2b-b49b-443b-9523-5b5792f58efc',
  '0195c699-aa48-488c-beb0-b6c5624746bc',
  'a8a85936-c148-49a5-a4ec-cfd1fe164115',
  '731afde1-a1bc-449c-b5a2-b50e805aca9e',
  '6cfa2150-b648-4e1b-ab62-71863dc57213',
  'f5708d7d-87ed-49d9-a8a2-eb56c84b071c',
  'a43460f2-6aa4-49e4-ac50-3d8041a1d5e0',
  'adaa3503-5a10-429f-a237-aa3b98c0cf77',
  '45dce9d8-f9bc-4e14-bbac-ddf61dec4abc',
  '589a6e8b-861e-4a6c-aa71-c8557bd46736',
  '3d180a9a-a9d0-4f35-a4bc-2744ddf3da0b',
  '9d5a971e-2a6d-4cba-b069-e3128bbf905d',
  'e62047c1-6f32-4b60-9169-7da621e5c1c0',
  '5dc6dbd7-00f0-4b87-a93f-d15dd763a24f',
  'f6bccc16-90fb-4a5a-9703-f02ed0a28fd5',
  'ba97e6c7-05d6-4a0f-a88b-fc3160967821',
  '9640e1f4-2b6c-4b43-8624-7b617d903c8a',
  '97e5580d-8e20-47a9-920a-8146a1304205',
  'c5fa3968-2c37-4b72-b589-a9e5f5f374ad',
  '5ed5c944-748d-443b-a5a1-d9edc294d76c',
  '1ce53b5c-7222-408e-84ba-56781ce45996',
  'db122720-e34c-41ff-9331-7e701233e6f1',
  '3a392456-5dfa-4a6b-a2b9-f5fd946a6846',
  'dff9e094-71b1-41f1-b12b-970ceaa54c2a',
  'a1de25aa-24ea-41d9-b540-3226b6e60adc',
  'cbd5dc4c-9d6f-49b5-a78f-080902bd9a9e',
  'bd1fe8f7-b88a-4d93-8b18-0bfce030ae2b',
  '153287b8-bab2-41c2-8cf2-a0e8efc1b6de',
  '2caeaf88-2a14-447d-8c5a-0264877c9123',
  'e8fde1aa-a569-4866-bc6f-138f7922b84b',
  '486e6451-82f9-48f3-90e7-d0752ea7e6fb',
  '9834bb52-62cc-4a8c-aca6-3129c52158bf',
  '4fe6fa43-df8f-44a8-a7f5-7e5f2b5aa7b2',
  '90a79906-f367-4a4e-8b48-7dbe0bcbcb2c',
  '155429cc-2e92-4e12-b01d-9e2e53ce0416',
  '19d47c7c-c5e8-4499-a440-3c6e74b085e6',
  'ac2eb079-6a3d-4540-b426-f5f6f671c175',
  'dccc64ca-5ea6-496c-b5e1-ef958c0f4ed3',
  '6b576004-3db7-4aaf-9ebf-a989a98d56c8',
  'b63b384b-b86e-4745-a12f-ef32a4ea34f0',
  'dab078f1-8c81-4799-b937-ca106de6f10e',
  'c49e0870-4ed5-4c71-87fd-429dada627f7',
  '5f349086-e441-4229-a995-59adc2d9df7a',
  '8db06191-35ce-4527-bdaa-48f40e3908ce',
  '0890077e-9143-45b5-871d-68111e30d1e6',
  '52672b0f-d525-4f04-af88-3e17622b338d',
  'de9c0fcb-d449-41a4-a741-491a98b5a71f',
  '9c22bc04-695f-4159-8147-1a87291cd286',
  '4979978b-9a14-4e19-986b-609f5755c3e1',
  '89237088-a301-49cd-b0a7-b87b15b6f773',
  '72e72859-a07c-4871-8036-32852b25c30c',
  '32505963-6d93-4564-bcff-16efb8983781'
];

const ReviewInfo = () => {
  const {
    data: {
      companyid,
      reportId,
      editToken,
      form,
      page,
      dataset,
      canReviewAnyone,
      companyMode,
      prevDataRef,
      mode
    },
    setDataObject
  } = useContext(PerformanceBuilderContext);
  const isEditPage = page === 'edit';
  const isBuilderPage = page === 'builder';

  const isCompanySpecific = companyMode === 'company';

  const queryClient = useQueryClient();
  const {
    register, control, watch, setValue, getValues
  } = form;
  const prevData = prevDataRef.current;
  const {
    reviewerId,
    reviewerFirstName,
    reviewerLastName,
    reviewerEmail,
    revieweeId,
    revieweeFirstName,
    revieweeLastName,
    revieweeTitle,
    companyName,
    title,
    reviewDate
  } = watch();
  const {
    create: createBuilderReport,
    isLoading: isCreateBuilderReportLoading
  } = useCreateBuilderReport();
  const {
    update: updateBuilderReport,
    isLoading: isUpdateBuilderReportLoading
  } = useUpdateBuilderReport(editToken);

  const [reviewerSearchText, setReviewerSearchText] = useState('');
  let reviewerOptions = [];
  const reviewerFullName = commonUtils.getFullName(
    reviewerFirstName,
    reviewerLastName
  );

  const [revieweeSearchText, setRevieweeSearchText] = useState('');
  let revieweeOptions = [];
  const revieweeFullName = commonUtils.getFullName(
    revieweeFirstName,
    revieweeLastName
  );

  const isJoeleFrank = builderUtils.getIsJoeleFrank(companyid);
  const isPublicSchoolForum = builderUtils.getIsPublicSchoolForum(companyid);
  if (isCompanySpecific) {
    const [{ accounts }] = dataset;

    reviewerOptions = accounts
      .filter((account) => {
        const common = reviewerId !== account._id
          && (!reviewerSearchText
            || account.name
              .toLowerCase()
              .includes(reviewerSearchText.toLowerCase()));

        if (isJoeleFrank) {
          return common && !excludedReviewerIds.includes(account._id);
        }

        return common;
      })
      .map((account) => ({
        id: account._id,
        label: commonUtils.getFullName(account),
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.email
      }));

    if (reviewerId) {
      let validReviewees = [];
      if (canReviewAnyone) validReviewees = accounts;
      else {
        const reviewerAccount = accounts.find(
          (account) => account._id === reviewerId
        );
        const { validRevieweeIds } = reviewerAccount;
        validReviewees = accounts.filter((account) => validRevieweeIds.includes(account._id));
      }

      revieweeOptions = validReviewees
        .filter((account) => {
          const common = revieweeId !== account._id
            && (!revieweeSearchText
              || account.name
                .toLowerCase()
                .includes(revieweeSearchText.toLowerCase()));

          if (isJoeleFrank) {
            return (
              common
              && reviewerId !== account._id
              && !excludedRevieweeIds.includes(account._id)
            );
          }

          if (isPublicSchoolForum) {
            return common && !excludedRevieweeIds.includes(account._id);
          }

          return common;
        })
        .map((account) => {
          const { settings } = account;
          return {
            id: account._id,
            label: commonUtils.getFullName(account),
            firstName: account.firstName,
            lastName: account.lastName,
            title: account.title,
            defaultTemplateId: settings.defaultTemplateId
          };
        });
    }
  }

  const onChangeReviewer = (option) => {
    const isSelectingADifferentOption = reviewerId && reviewerId !== option.id;
    if (isSelectingADifferentOption) {
      const answer = confirm(
        'Changing and saving the selected reviewer will erase your progress. Are you sure you want to proceed?'
      );
      if (!answer) return;
    }

    const {
      id, firstName, lastName, email
    } = option;
    setValue('reviewerId', id);
    setValue('reviewerFirstName', firstName);
    setValue('reviewerLastName', lastName);
    setValue('reviewerEmail', email);
    setValue('revieweeId', null);
    setValue('revieweeFirstName', '');
    setValue('revieweeLastName', '');
    setValue('revieweeTitle', '');
    if (!isCompanySpecific) setValue('industry', builderUtils.getEmptyOption());
    setValue('role', builderUtils.getEmptyOption());
    setValue('reviews', []);
    setValue('summary', '');
    setValue('summaryTemplate', null);
  };

  const onChangeReviewee = (option) => {
    const isSelectingADifferentOption = revieweeId && revieweeId !== option.id;
    if (isSelectingADifferentOption) {
      const answer = confirm(
        'Changing and saving the selected reviewee will erase your progress. Are you sure you want to proceed?'
      );
      if (!answer) return;
    }

    const {
      id, firstName, lastName, title: newRevieweeTitle
    } = option;
    setValue('revieweeId', id);
    setValue('revieweeFirstName', firstName);
    setValue('revieweeLastName', lastName);
    setValue('revieweeTitle', newRevieweeTitle);
    if (!isCompanySpecific) setValue('industry', builderUtils.getEmptyOption());
    setValue('role', builderUtils.getEmptyOption());
    setValue('reviews', []);
    setValue('summary', '');
    setValue('summaryTemplate', null);
  };

  const isEmailInvalid = !commonUtils.isEmailValid(reviewerEmail);

  const navigate = useNavigate();
  const save = async () => {
    const reviewerData = {
      reviewerId: reviewerId || null,
      firstName: reviewerFirstName,
      lastName: reviewerLastName,
      email: reviewerEmail
    };

    const revieweeData = {
      revieweeId: revieweeId || null,
      firstName: revieweeFirstName,
      lastName: revieweeLastName,
      title: revieweeTitle
    };

    const data = {
      reviewee: revieweeData,
      reviewer: reviewerData,
      companyName,
      title,
      reviewDate,
      companyMode: isCompanySpecific ? 'company' : 'public'
    };

    // we only reset data when changing reviewer or reviewee if we are in company mode
    // they're just text fields otherwise
    if (isCompanySpecific) {
      const [{ industryId, accounts }] = dataset;
      const revieweeAccount = accounts.find(
        (account) => account._id === revieweeId
      );
      const hasChangedReviewee = revieweeAccount._id !== prevData.revieweeId;
      if (hasChangedReviewee) {
        const [firstRoleId] = revieweeAccount.roles;
        data.industryId = industryId;
        data.roleId = firstRoleId;
        data.reviews = [];
        data.summary = '';
      }
    }

    let callback = () => {};

    const scrollToChooseReviewTemplate = () => {
      // interval to scroll to element
      let reviewTemplateSection;
      const refreshIntervalId = setInterval(() => {
        reviewTemplateSection = document.getElementById(
          'review-template-section'
        );
        if (!reviewTemplateSection) {
          return;
        }
        reviewTemplateSection.scrollIntoView({ behavior: 'smooth' });
        clearInterval(refreshIntervalId);
      }, 300);
    };

    if (mode === 'test') {
      data.mode = 'test';
    }

    let reportData = null;
    if (isBuilderPage) {
      const loggedUser = appUtils.getLoggedUser();
      const isLoggedIn = appUtils.isLoggedIn();
      if (isLoggedIn) data.companyid = loggedUser.companyid;
      else if (isCompanySpecific) data.companyid = companyid;
      const {
        data: { data: createdReport }
      } = await createBuilderReport({ data });
      reportData = createdReport;
      toast.show('Performance review created!');
      const encodedBuilderId = appUtils.encodeURIString(
        createdReport.editToken
      );
      callback = () => {
        navigate(`/builder/${encodedBuilderId}/edit`);
        scrollToChooseReviewTemplate();
      };
    }

    if (isEditPage) {
      const { data: updatedReport } = await updateBuilderReport({ data });
      reportData = updatedReport;
      toast.show('Performance review updated!');
      scrollToChooseReviewTemplate();
    }

    queryClient.invalidateQueries([BUILDER_QUERY_KEYS.BUILDER, reportData._id]);
    setDataObject({
      reportId: reportData._id,
      editToken: reportData.editToken,
      companyid: reportData.companyid,
      reviewMode: reportData.reviewMode,
      companyMode: reportData.companyMode,
      mode: reportData.mode
    });

    if (isCompanySpecific) {
      const { industry: populatedIndustry, role: populatedRole } = builderUtils.getPopulatedIndustryAndRole(dataset, reportData);
      setValue('industry', populatedIndustry);
      setValue('role', populatedRole);
      const populatedReviews = builderUtils.getPopulatedReviews(
        dataset,
        reportData
      );
      setValue('reviews', populatedReviews);
      setValue('summary', reportData.summary);
      setValue('summaryTemplate', null);
    }

    queryClient.invalidateQueries(BUILDER_QUERY_KEYS.BUILDER);
    prevDataRef.current = JSON.parse(JSON.stringify(getValues()));

    if (isCompanySpecific) {
      const [{ industryId, accounts }] = dataset;
      const revieweeAccount = accounts.find(
        (account) => account._id === revieweeId
      );
      const {
        settings: { defaultTemplateId }
      } = revieweeAccount;
      const templateOptions = builderUtils.getTemplateOptions(
        industryId,
        dataset
      );
      const defaultTemplate = templateOptions.find(
        (template) => template.id === defaultTemplateId
      );
      if (defaultTemplate) {
        setValue('summary', defaultTemplate.content);
        setValue('summaryTemplate', defaultTemplate.label);
      }
    }

    callback();
  };

  const autofillInfo = async () => {
    setValue('reviewerFirstName', 'George');
    setValue('reviewerLastName', 'Costanza');
    setValue('reviewerEmail', 'george_costanza@workstory.team');
    setValue('revieweeFirstName', 'Cosmo');
    setValue('revieweeLastName', 'Kramer');
    setValue('revieweeTitle', 'Model');
    setValue('companyName', 'Test WorkStory');
    setValue('title', 'Test Report');
    setValue('reviewDate', new Date('July 5, 1989'));
  };

  const getIsButtonDisabled = () => {
    if (
      isEmailInvalid
      || isCreateBuilderReportLoading
      || isUpdateBuilderReportLoading
    ) return true;

    const newData = {
      reviewerFirstName,
      reviewerLastName,
      reviewerEmail,
      revieweeFirstName,
      revieweeLastName,
      revieweeTitle,
      companyName,
      title
    };

    const areFieldsEmpty = commonUtils.isAnyFalsy(newData);
    if (areFieldsEmpty) return true;

    const doesReportExist = Boolean(reportId);
    if (!doesReportExist) return false;

    const oldData = {
      reviewerFirstName: prevData.reviewerFirstName,
      reviewerLastName: prevData.reviewerLastName,
      reviewerEmail: prevData.reviewerEmail,
      revieweeFirstName: prevData.revieweeFirstName,
      revieweeLastName: prevData.revieweeLastName,
      revieweeTitle: prevData.revieweeTitle,
      companyName: prevData.companyName,
      title: prevData.title
    };

    const oldReviewDate = prevData.reviewDate;
    const newReviewDate = reviewDate;

    const canSubmitForm = !commonUtils.isSame(oldData, newData)
      || !commonDateUtils.isSameDay([oldReviewDate, newReviewDate]);

    return !canSubmitForm;
  };

  const reportTitleOptions = [
    { id: 'self-evaluation', label: 'Self Evaluation' },
    { id: 'performance-review', label: 'Performance Review' }
  ];

  const shouldBreakPageTitle = window.innerWidth <= 1612;
  return (
    <BuilderContainer
      loading={isCreateBuilderReportLoading || isUpdateBuilderReportLoading}
    >
      <>
        <h1 className='text-3xl'>
          Self-Evaluation and
          {shouldBreakPageTitle ? <br /> : ' '}
          Performance Review Builder
        </h1>
        <p className='text-gray-500 text-lg mb-5'>
          Follow the steps to generate a performance review for yourself or a
          team member.
        </p>
        <h4 className='text-xl'>Review Info</h4>
        <p className='text-gray-500 mb-5'>
          Provide the team member’s information. Click save when you're done.
        </p>
        <div className='flex flex-col w-1/2'>
          <label className='mb-3 flex flex-col gap-2'>
            <span className='text-base font-bold'>Company Name</span>
            <input
              type='text'
              placeholder='ACME Inc.'
              className='bg-white rounded-lg border border-slate-300 h-10'
              disabled={isCompanySpecific}
              value={companyName}
              {...register('companyName', {
                required: {
                  value: true,
                  message: 'Please provide the company name.'
                }
              })}
            />
          </label>
          <label className='mb-3 flex flex-col gap-2'>
            <span className='text-base font-bold'>Report Title</span>
            {isJoeleFrank || isPublicSchoolForum ? (
              <Select
                customVariantClasses='bg-white rounded-lg border border-slate-300 h-10'
                scrollStyle='purple'
                options={reportTitleOptions}
                title={title || 'Select a Report Title'}
                onChange={(option) => setValue('title', option.label)}
                disabled
              />
            ) : null}
            {!isJoeleFrank && !isPublicSchoolForum ? (
              <input
                type='text'
                placeholder='Performance Review Report'
                className='bg-white rounded-lg border border-slate-300 h-10'
                value={title}
                {...register('title', {
                  required: {
                    value: true,
                    message: 'Please provide a title for the report.'
                  }
                })}
              />
            ) : null}
          </label>
          {isCompanySpecific ? (
            <div className='mb-3 flex flex-col gap-2'>
              <span className='text-base font-bold'>Reviewer</span>
              <Select
                customVariantClasses='bg-white rounded-lg border border-slate-300 h-10'
                scrollStyle='purple'
                options={reviewerOptions}
                title={reviewerFullName || 'Select a Reviewer'}
                onChange={onChangeReviewer}
                showSearch
                onSearch={(value) => setReviewerSearchText(value)}
                onDropdownClose={() => setReviewerSearchText('')}
              />
            </div>
          ) : (
            <>
              <label className='mb-3 flex flex-col gap-2'>
                <span className='text-base font-bold'>Reviewer First Name</span>
                <input
                  type='text'
                  placeholder='John'
                  className='bg-white rounded-lg border border-slate-300 h-10'
                  {...register('reviewerFirstName', {
                    required: {
                      value: true,
                      message: 'Please provide your first name.'
                    }
                  })}
                />
              </label>
              <label className='mb-3 flex flex-col gap-2'>
                <span className='text-base font-bold'>Reviewer Last Name</span>
                <input
                  type='text'
                  placeholder='Smith'
                  className='bg-white rounded-lg border border-slate-300 h-10'
                  {...register('reviewerLastName', {
                    required: {
                      value: true,
                      message: 'Please provide your last name.'
                    }
                  })}
                />
              </label>
            </>
          )}
          <label className='mb-2 flex flex-col gap-2'>
            <span className='text-base font-bold'>Reviewer Email</span>
            <input
              type='text'
              placeholder={isCompanySpecific ? 'Email' : 'John@example.com'}
              className='bg-white rounded-lg border border-slate-300 h-10'
              disabled={isCompanySpecific}
              value={reviewerEmail}
              {...register('reviewerEmail', {
                required: {
                  value: true,
                  message: 'Please provide your email.'
                }
              })}
            />
          </label>
          {reviewerEmail && isEmailInvalid ? (
            <span className='text-red-400'>Please provide a valid email.</span>
          ) : null}
          {isCompanySpecific ? (
            <div className='mb-3 flex flex-col gap-2'>
              <span className='text-base font-bold'>Reviewee</span>
              <Select
                customVariantClasses='bg-white rounded-lg border border-slate-300 h-10'
                scrollStyle='purple'
                options={revieweeOptions}
                title={revieweeFullName || 'Select a Reviewee'}
                onChange={onChangeReviewee}
                disabled={!reviewerId}
                showTitle={false}
                showSearch
                onSearch={(value) => setRevieweeSearchText(value)}
                onDropdownClose={() => setRevieweeSearchText('')}
              />
            </div>
          ) : (
            <>
              <label className='mb-3 flex flex-col gap-2'>
                <span className='text-base font-bold'>Reviewee First Name</span>
                <input
                  type='text'
                  placeholder='Jane'
                  className='bg-white rounded-lg border border-slate-300 h-10'
                  {...register('revieweeFirstName', {
                    required: {
                      value: true,
                      message: "Please provide the employee's first name."
                    }
                  })}
                />
              </label>
              <label className='mb-3 flex flex-col gap-2'>
                <span className='text-base font-bold'>Reviewee Last Name</span>
                <input
                  type='text'
                  placeholder='Smith'
                  className='bg-white rounded-lg border border-slate-300 h-10'
                  {...register('revieweeLastName', {
                    required: {
                      value: true,
                      message: "Please provide the employee's last name."
                    }
                  })}
                />
              </label>
            </>
          )}
          <label className='mb-3 flex flex-col gap-2'>
            <span className='text-base font-bold'>Reviewee Title</span>
            <input
              type='text'
              placeholder={
                isCompanySpecific ? 'Job Title' : 'Software Engineer'
              }
              className='bg-white rounded-lg border border-slate-300 h-10'
              disabled={isCompanySpecific}
              value={revieweeTitle}
              {...register('revieweeTitle', {
                required: {
                  value: true,
                  message: 'Please provide a job title.'
                }
              })}
            />
          </label>
          <div className='h-16 mb-10 flex flex-col gap-2'>
            <span className='text-base font-bold'>Review Date</span>
            <Controller
              name='reviewDate'
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const curr = field.value ? new Date(field.value) : null;
                return (
                  <PickDate
                    classes='float-none rounded-lg w-full bg-white border border-slate-300 h-10'
                    placeholder='Review Date'
                    date={curr}
                    onSelect={field.onChange}
                    {...field}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className='flex justify-start gap-4'>
          <Button
            type='button'
            disabled={getIsButtonDisabled()}
            variant='custom'
            classes='w-[7rem] bg-[#0F172A] text-white text-base px-4 py-2 rounded-md transition-colors duration-300'
            onClick={save}
          >
            Save
          </Button>
          {mode === 'test' ? (
            <Button
              type='button'
              variant='custom'
              classes='w-[7rem] bg-red text-white text-base px-4 py-2 rounded-md transition-colors duration-300'
              onClick={autofillInfo}
            >
              Autofill
            </Button>
          ) : null}
        </div>
      </>
    </BuilderContainer>
  );
};

export default ReviewInfo;
