import { h } from 'preact';
import Quill from './Quill';

const FeedbackQuill = (props) => (
  <Quill
    className='flex flex-col h-20rem pb-10'
    modules={{
      toolbar: [
        [{ header: [false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' }
        ],
        [],
        ['clean']
      ],
      maxlength: { maxLength: 2000 }
    }}
    {...props}
  />
);

export default FeedbackQuill;
