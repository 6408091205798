import { h, Fragment } from 'preact';
import { get, isNumber } from 'lodash';
import {
  Button, Base, Circle, Select, Radio, toast
} from 'src/components';
import { useTree } from 'src/queries/tree';
import commonTreeUtils from 'common/commonTreeUtils';
import STYLE from 'src/constants/style';
import {
  useReviewsV2,
  patchReviewQuery,
  QUERY_KEYS as REVIEWS_QUERY_KEYS
} from 'src/queries/reviews';
import { QUERY_KEYS as SCORE_QUERY_KEYS } from 'src/queries/score';
import { QUERY_KEYS as FEEDBACK_QUERY_KEYS } from 'src/queries/feedback';
import FeedbackQuill from 'src/components/Quill/FeedbackQuill';
import commonQuestions from 'common/commonQuestions';
import commonUtils from 'common/commonUtils';
import { getAvgSentimentLabel } from 'common/commonNlpUtils';
import { useCompany } from 'src/queries/company';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useForm, Controller } from 'react-hook-form';
import { sharedReviewUtils } from 'src/common';
import { useQueryClient } from 'react-query';
import CrossSVG from 'src/assets/cross.svg';
import appUtils from 'src/components/appUtils';
import { route } from 'preact-router';
import { useAccount } from 'src/queries/account';
import {
  formatNoteText,
  limitNoteText
} from 'src/pagesDashboard/NewUserReport/utils';
import commonPermissions from 'common/commonPermissions';
import commonReviewUtils from 'common/commonReviewUtils';

const {
  REVIEW_ANSWER_TYPE,
  REVIEW_STATUS,
  IM_NOT_SURE,
  ACCESS,
  SHARE_REVIEW_WITH
} = COMMON_CONSTANTS;

const displayFormErrors = (errors) => {
  Object.keys(errors).forEach((key) => {
    const val = errors[key];
    const message = get(val, 'message');
    if (!message) {
      return;
    }
    toast.error(message);
  });
};

const EditReview = ({ parentProps: { reviewId } }) => {
  const close = () => window.history.back();
  const queryClient = useQueryClient();
  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: reviews,
    isFetching: isFetchingReviews,
    isError: isErrorReviews
  } = useReviewsV2(
    {
      ids: [reviewId]
    },
    {
      page: {
        size: 1
      },
      include: {
        reviewer: true,
        reviewee: true
      }
    }
  );

  const isFetching = isFetchingReviews
    || isFetchingCompany
    || isFetchingTree
    || isFetchingLoggedAccount;
  const isError = isErrorCompany || isErrorTree || isErrorReviews || isErrorLoggedAccount;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const isAdmin = loggedAccount.access === ACCESS.ADMIN;
  const [review] = reviews;
  const { reviewee } = review;

  const isAbove = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    reviewee._id,
    loggedAccount._id
  );
  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    reviewee._id
  ]);
  if (!isAbove && !isAdmin && !canManageAccount) {
    toast.error('You do not have enough permissions to edit this review');
    route(appUtils.getDashRoute());
  }

  const COMPANY_QUESTIONS = company.questions;
  const questionObj = commonQuestions.getQuestion(
    review.questionId,
    COMPANY_QUESTIONS.QUESTIONS
  );
  const answerOptions = [
    ...questionObj.answers.custom
      .sort((a, b) => (a.score < b.score ? 1 : -1))
      .map((a) => ({
        id: a.id,
        label: a.value
      })),
    {
      id: REVIEW_ANSWER_TYPE.NOT_AVAIL,
      label: IM_NOT_SURE
    }
  ];

  const visibilityOptions = sharedReviewUtils.renderShareCommentsVisibilityOptions(
    reviewee.name,
    false
  );
  const validVisibilityOptions = sharedReviewUtils.getShareCommentOptions(
    company.settings.reviewVisibilityOptions,
    visibilityOptions
  );

  const { mutateAsync: updateReview, isLoading: isLoadingUpdateReview } = patchReviewQuery();

  const isFreeText = questionObj.answers.type === REVIEW_ANSWER_TYPE.FREE_TEXT;
  const isAnonymous = review.commentVisible === SHARE_REVIEW_WITH.ANONYMOUS;
  const { sentiment } = review;
  const hasSentiment = isNumber(sentiment.score);
  const emptySentiment = {
    id: 'N/A',
    label: 'N/A',
    value: null
  };

  const sentimentScoreOptions = Array.from(
    { length: (100 - -100) / 5 + 1 },
    (_, i) => i * 5 - 100
  ).map((i) => ({
    id: i.toString(),
    label: i.toString(),
    value: i
  }));
  if (!hasSentiment) sentimentScoreOptions.unshift(emptySentiment);

  const emptyAnswer = {
    id: null,
    label: null
  };

  const formattedReviewComments = review.comments
    ? review.comments.replaceAll('\n', '<br/>')
    : review.comments;

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      sentimentScore: hasSentiment
        ? {
          id: sentiment.score,
          label: sentiment.score,
          value: sentiment.score
        }
        : emptySentiment,
      sentimentRange: getAvgSentimentLabel(sentiment.score),
      sentimentReason: hasSentiment ? sentiment.reason : null,
      answer: isFreeText
        ? emptyAnswer
        : answerOptions.find((a) => a.label === review.answer),
      comments: formattedReviewComments,
      commentVisible: review.commentVisible,
      reason: ''
    }
  });

  const formValues = watch();

  const error = () => displayFormErrors(errors);

  const saveUpdate = async () => {
    const {
      reason, comments, commentVisible, answer
    } = formValues;

    let formattedComments = comments;
    const plainComments = commonReviewUtils
      .getPlainTextFromHTML(formattedComments)
      .replace(' ', '');
    if (!plainComments.length) formattedComments = null;

    const newIsNA = answer.id && answer.id === REVIEW_ANSWER_TYPE.NOT_AVAIL;
    const updateData = {
      reviewId: review._id,
      reason,
      comments: formattedComments,
      commentVisible,
      answerId: answer.id,
      isNA: newIsNA
    };

    const { sentimentScore, sentimentReason: newSentimentReason } = formValues;
    const newSentimentScore = sentimentScore.value;
    const newSentimentRange = isNumber(newSentimentScore)
      ? getAvgSentimentLabel(newSentimentScore)
      : null;
    const updatedSentiment = {
      ...review.sentiment,
      score: newSentimentScore,
      range: newSentimentRange,
      reason: newSentimentReason
    };

    if (!commonUtils.isSame(updatedSentiment, review.sentiment)) updateData.sentiment = updatedSentiment;

    const response = await updateReview({
      action: 'UPDATE_REVIEW',
      revieweeId: reviewee._id,
      data: updateData
    });

    if (!response || !response.success) {
      console.error('Failed to edit a review', { response, review });
      return toast.error("Oops, we've run into an issue. Try again later!");
    }

    toast.show('Review updated');

    queryClient.invalidateQueries(SCORE_QUERY_KEYS.SCORE);
    queryClient.invalidateQueries(REVIEWS_QUERY_KEYS.REVIEWS);
    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK);
    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK_FEED);
    close();
  };

  const isSaveDisabled = () => {
    if (isLoadingUpdateReview) return true;
    const {
      comments,
      commentVisible,
      sentimentScore,
      sentimentReason,
      answer,
      reason
    } = formValues;

    const plainComments = commonReviewUtils.getPlainTextFromHTML(comments);
    const plainPreviousComments = commonReviewUtils.getPlainTextFromHTML(
      formattedReviewComments
    );

    const isReviewDataChanged = plainComments !== plainPreviousComments
      || commentVisible !== review.commentVisible
      || sentimentScore.value !== review.sentiment.score
      || sentimentReason !== review.sentiment.reason
      || (answer.id && answer.label !== review.answer);

    const isReasonEmpty = reason === '';
    if (!isReasonEmpty && isReviewDataChanged) return false;
    return true;
  };

  return (
    <Base
      variant='transparent'
      classes={STYLE.BASE}
      loading={isFetchingReviews || isLoadingUpdateReview}
    >
      <Base classes={`${STYLE.CONTAINER_WHITE_PADDINGLESS}`}>
        <div>
          <div className='h-32 -mb-18 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-t-md relative'>
            <div className='absolute right-0'>
              <button className='mr-2 mt-2' onClick={close}>
                <CrossSVG class='w-12 h-12 cursor-pointer' />
              </button>
            </div>
          </div>
          <div className='pb-6 flex flex-col items-center'>
            <Circle
              size='xxl'
              imageUrl={reviewee.imageUrl}
              classes='mb-4 user-setting-profile-image relative h-131 w-131'
            />
            <div className='text-center'>
              <div className='text-2xl bold'>{reviewee.name}</div>
              <div className='text-base text-gray-400'>{reviewee.title}</div>
            </div>
          </div>
        </div>
        <div className='p-4'>
          <div className='inline-block w-2/5 align-top text-left pl-2'>
            <h5 className='text-black focus:outline-none text-2xl mr-5 mb-2 inline-block font-bold mt-2'>
              Edit Review
            </h5>
          </div>
        </div>
        <div className='inline-block w-4/5 pr-16 pl-6 pt-8'>
          {!isFreeText ? (
            <Fragment>
              <div className='flex justify-between h-10 my-10'>
                <p className='mb-0 font-bold text-base text-gray-500'>
                  CURRENT ANSWER
                </p>
                <div className='pl-8 pr-6 w-4/6'>
                  <p>{review.answer}</p>
                </div>
              </div>
              <div className='flex justify-between h-10 my-10'>
                <p className='mb-0 font-bold text-base text-gray-500'>
                  NEW ANSWER
                </p>
                <div className='pl-8 pr-6 w-4/6'>
                  <Controller
                    control={control}
                    name='answer'
                    rules={{ required: true }}
                    render={({ field }) => {
                      const title = field?.value ? field.value.label : null;
                      return (
                        <Select
                          placeholder='Select answer'
                          classes='w-full'
                          options={answerOptions}
                          title={title}
                          {...field}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </Fragment>
          ) : null}
          <div className='flex justify-between h-auto my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>COMMENTS</p>
            <div className='pl-8 pr-6 w-4/6'>
              <Controller
                control={control}
                name='comments'
                rules={{
                  required: false
                }}
                render={({ field }) => (
                  <FeedbackQuill
                    value={field.value}
                    className='flex flex-col h-20rem pb-10'
                    onChange={(value) => {
                      field.onChange(formatNoteText(value));
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className='flex justify-between h-40 my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>
              COMMENT VISIBILITY
            </p>
            <div className='w-4/6 pl-8'>
              {visibilityOptions.map((option) => {
                const isValid = validVisibilityOptions.some(
                  (ao) => ao.value === option.value
                );
                const isCurrent = review.commentVisible === option.value;
                if (!isValid && !isCurrent) return null;

                return (
                  <div className='mb-2 font-bold'>
                    <Controller
                      name='commentVisible'
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { value, onChange } }) => (
                        <Radio
                          name='commentVisible'
                          checked={option.value === formValues.commentVisible}
                          value={value}
                          title={option.title}
                          onClick={() => onChange(option.value)}
                          disabled={isAnonymous || !isValid}
                        />
                      )}
                    />
                  </div>
                );
              })}
              {formValues.commentVisible === SHARE_REVIEW_WITH.ANONYMOUS ? (
                <p className='text-xs text-red'>
                  Note! Anonymous reviews cannot have their visibility updated.
                </p>
              ) : null}
            </div>
          </div>
          {/* Sentiment Score start */}
          <div className='flex justify-between h-10 my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>
              SENTIMENT SCORE
            </p>
            <div className='pl-8 pr-6 w-4/6'>
              <Controller
                control={control}
                name='sentimentScore'
                rules={{
                  required: false,
                  onChange: (e) => {
                    const { value } = e.target.value;
                    if (!value) {
                      setValue(
                        'sentimentReason',
                        hasSentiment ? sentiment.reason : null
                      );
                    }
                  }
                }}
                render={({ field }) => {
                  const title = field?.value ? field.value.label : null;
                  return (
                    <Select
                      placeholder='Select answer'
                      classes='w-full'
                      options={sentimentScoreOptions}
                      title={title}
                      {...field}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className='flex justify-between h-10 my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>
              SENTIMENT RANGE
            </p>
            <div className='pl-8 pr-6 w-4/6'>
              <p>{getAvgSentimentLabel(formValues.sentimentScore.value)}</p>
            </div>
          </div>
          {formValues.sentimentScore.value !== null ? (
            <div className='flex justify-between h-40 my-10'>
              <p className='mb-0 font-bold text-base text-gray-500'>
                SENTIMENT REASON
              </p>
              <div className='pl-8 pr-6 w-4/6 h-20'>
                <Controller
                  control={control}
                  name='sentimentReason'
                  rules={{
                    required: false
                  }}
                  render={({ field }) => (
                    <textarea
                      disabled={formValues.sentimentScore.value === null}
                      className='block w-full resize-none h-40'
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          ) : null}
          {/* Sentiment Score end */}
          <div className='flex justify-between h-40 my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>REASON *</p>
            <div className='pl-8 pr-6 h-16 w-4/6'>
              <Controller
                control={control}
                name='reason'
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <textarea
                    className='block w-full resize-none h-40'
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className='p-4 flex w-full justify-end'>
          <Button
            onClick={handleSubmit(saveUpdate, error)}
            variant='yellow'
            disabled={isSaveDisabled()}
          >
            Done
          </Button>
        </div>
      </Base>
    </Base>
  );
};

export default EditReview;
