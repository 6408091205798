import { useQuery, useMutation } from 'react-query';
import { isEmpty, get } from 'lodash';
import qs from 'qs';
import { toast } from 'src/components/index';
import api from '../services/api';
import questionsUtils from '../common/questionsUtils';

export const QUERY_KEYS = {
  COMPANY: 'company',
  COMPANY_QUESTIONS: 'companyQuestions',
  GROUP_MEMBERS: 'groupMembers',
  POPULATED_GROUPS: 'populatedGroups',
  PUBLIC_COMPANY: 'publicCompany'
};

export const useCompanyAsDataset = (companyid, viewerId = null) => {
  const { data, ...rest } = useQuery(
    [
      QUERY_KEYS.PUBLIC_COMPANY,
      companyid,
      ...(!isEmpty(viewerId) ? [viewerId] : [])
    ],
    () => api
      .get(
        `/company/public/${companyid}${viewerId ? `?viewerId=${viewerId}` : ''}`
      )
      .then((resp) => {
        const {
          success, error, message, warning
        } = resp;
        if (!success) {
          if (warning) return toast.show(message);
          console.error(
            `GET /company/public/${companyid}${viewerId ? `?viewerId=${viewerId}` : ''}`,
            error
          );
          return toast.error(
            'Uh oh, we ran into an issue. Please try again later!'
          );
        }

        return resp;
      }),
    {
      enabled: Boolean(companyid)
    }
  );

  return {
    data: get(data, 'data', {}),
    success: get(data, 'success'),
    ...rest
  };
};

export const useCompany = (queryOptions = {}) => useQuery(
  [
    QUERY_KEYS.COMPANY,
    ...(!isEmpty(queryOptions) ? [JSON.stringify(queryOptions)] : [])
  ],
  () => api
    .get('/company/company', {})
    .then((resp) => {
      if (!resp || !resp.success) {
        return;
      }
      const { company } = resp;
      if (!company) {
        return;
      }
      const companyQuestions = questionsUtils.questionsDbToApp(
        JSON.parse(company.questions)
      );
      company.questions = companyQuestions;
      return company;
    })
    .catch((error) => {
      console.error('CATCH useCompany', error);
      throw error;
    }),
  {
    placeholderData: {
      settings: {
        contentEvents: false
      }
    }
  }
);

export const uploadOrgQuery = () => useMutation((formData) => api.postForm('/company/upload', formData));

export const useQuestions = () => useQuery([QUERY_KEYS.COMPANY_QUESTIONS], () => api.get('/company/questions', {}).then((resp) => {
  if (!resp.success) {
    console.error('GET /admin/questions error');
    return;
  }
  const companyQuestions = questionsUtils.questionsDbToApp(
    JSON.parse(resp.questions)
  );
  return companyQuestions;
}));

export const resetCompanyQuery = () => useMutation(() => api.post('/company/reset'));

export const updateCompanyQuery = () => useMutation((data) => api.patch('/company/settings', { data }));

export const connectSlackQuery = () => useMutation(async ({ code, state, source }) => api.post('/company/connectSlack', {
  code,
  state,
  source
}));

export const createGroupQuery = () => useMutation(({ name }) => api.post('/company/groups', { name }));

// update existing group's name (group id is required)
export const deleteGroupQuery = () => useMutation(({ id }) => api.delete('/company/groups', { id }));

export const useGroupMembers = (groupId) => useQuery(
  [QUERY_KEYS.GROUP_MEMBERS, groupId],
  () => {
    const stringified = qs.stringify({ groupId }, { skipNulls: true });
    return api
      .get(`/company/groupMembers?${stringified}`)
      .then((resp) => {
        if (!resp?.success) {
          console.error('GET /company/groupMembers error', resp);
          return null;
        }
        return resp;
      })
      .catch((error) => {
        console.error('useGroupMembers', error);
        throw error;
      });
  },
  {
    enabled: Boolean(groupId)
  }
);

export const usePopulatedGroups = () => useQuery([QUERY_KEYS.POPULATED_GROUPS], () => api.get(`/company/populatedGroups`).then((resp) => {
  if (!resp.success) {
    console.error('GET /company/populatedGroups error', resp);
    return null;
  }
  return resp;
}));

export const disconnectSlack = () => useMutation(() => api.delete('/company/slack'));

export const uploadImage = () => useMutation((formData) => api.postForm(`/company/uploadImage`, formData));
