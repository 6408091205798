/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  cloneElement,
  useRef,
  useState,
  useEffect,
  forwardRef
} from 'react';
import useEvent from 'src/hooks/useEvent/useEvent';
import './Select.scss';
import XButton from 'src/components/XButton/XButton';
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/24/outline';

export const OPTIONS_WIDTH = {
  STANDARD: 'standard',
  WIDE: 'wide',
  FIT: 'fit'
};

const Select = forwardRef(
  (
    {
      id,
      classes: containerClasses = '',
      wrapperClasses = '',
      customVariantClasses: topClasses = '',
      topTextClasses = '',
      arrowClasses = '',
      optionClasses = '',
      optionTextClasses = '',
      placeholder = 'Select',
      options = [],
      title = 'Select',
      variant = null,
      onChange,
      disabled = false,
      scrollStyle = '',
      multiselect = false,
      onSearch = null,
      showSearch = false,
      loading = false,
      icon,
      onDropdownClose,
      onDropdownOpen,
      hideArrow = false,
      showTitle = true,
      showUnderlabel = true,
      drop = 'down',
      showXButton = false,
      optionsWidth = OPTIONS_WIDTH.STANDARD,
      maxOptionWidth = null,
      onClickXButton = () => {}
    },
    ref
  ) => {
    const selectRef = useRef(null);
    const searchRef = useRef(null);

    options.sort((opt1, opt2) => Boolean(opt2.checked) - Boolean(opt1.checked));

    const [state, setState] = useState({ newLabel: '', show: false });

    useEffect(() => {
      if (searchRef && searchRef.current) {
        searchRef.current.focus();
      }
    }, [searchRef]);

    useEffect(() => {
      if (state.show && onDropdownOpen) onDropdownOpen();
      else if (!state.show && onDropdownClose) onDropdownClose();
    }, [state.show]);

    const handler = (e) => {
      if (selectRef && !selectRef.current.contains(e.target) && state.show) {
        setState({ newLabel: '', show: false });
      }
    };
    useEvent('click', handler);

    let variantClass = '';

    if (variant === 'black') {
      variantClass = 'text-black border rounded-md ring-1 ring-inset ring-black sm:text-sm sm:leading-6';
    } else {
      variantClass = 'text-black border rounded-md text-gray-900 ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6';
    }

    if (variant === 'minimal') {
      variantClass = 'border-0';
    }

    if (variant === 'shadow') {
      variantClass = 'shadow-sm text-black border rounded-md text-gray-900 ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6';
    }

    if (variant === 'empty-with-border') {
      variantClass = 'text-black text-lg px-4 py-2 rounded-sm font-bold border border-black transition-colors duration-300 hover:bg-hover-indigo-600';
    }

    if (disabled) {
      variantClass += ' border-light-gray';
    }

    let dropdownUpMargin = 18; // base distance and padding compensation
    dropdownUpMargin += 39.6; // clickable input height

    if (showSearch) dropdownUpMargin += 38 - 6; // search input height - pb-1 (from py-1) value

    if (options.length > 7) dropdownUpMargin += 44 * 7; // 7 items (max per scroll)
    else if (options.length > 0) dropdownUpMargin += 44 * options.length; // n items

    let scrollStyleClass = '';
    if (scrollStyle === 'purple') scrollStyleClass = 'purple-thumb-scrollbar';

    const hasCustomContainerWidth = containerClasses.includes('w-');

    const getOptionsWithClasses = () => {
      let classes = '';

      if (optionsWidth === OPTIONS_WIDTH.STANDARD) classes = '!w-[260px] !max-w-[260px]';
      if (optionsWidth === OPTIONS_WIDTH.WIDE) classes = '!w-[300px] !max-w-[300px]';
      if (optionsWidth === OPTIONS_WIDTH.FIT) classes = '!w-fit';

      return classes;
    };

    return (
      <div
        id={id}
        className={`relative ${hasCustomContainerWidth ? '' : 'max-w-[260px]'} ${containerClasses}`}
        ref={selectRef || ref}
      >
        <div className={`w-full max-w-[100%] flex gap-2 ${wrapperClasses}`}>
          <div
            onClick={() => {
              if (disabled) {
                return;
              }
              setState({ ...state, show: !state.show });
            }}
            className={`flex justify-start items-center gap-2 text-left ${
              state.show
                ? 'bg-hover-gray ring-2 ring-inset ring-indigo-600'
                : 'bg-white'
            } hover:bg-hover-gray py-1.5 px-2.5 border-0 cursor-pointer w-full truncate ${variantClass} ${topClasses}`}
          >
            {icon ? cloneElement(icon, { className: 'w-4 h-4' }) : null}
            <p
              className={`inline-block text-left truncate text-sm m-0 ${disabled ? 'text-light-gray' : ''} ${topTextClasses}`}
            >
              {title ?? placeholder}
            </p>
            {!hideArrow ? (
              <span
                className={`ml-auto flex justify-center items-center h-full ${variant === 'black' ? 'text-black' : 'text-gray-500'} ${disabled ? '!text-light-gray' : ''} w-4 h-4 stroke-2 my-auto ${arrowClasses} text-decoration: none`}
              >
                <ChevronUpDownIcon className='w-6 h-6' />
              </span>
            ) : null}
          </div>
          {showXButton ? <XButton onClick={onClickXButton} centered /> : null}
        </div>
        {!disabled && state.show ? (
          <div
            className={`w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm z-50 absolute cursor-pointer min-w-[100%] 
          ${showSearch ? 'max-h-[354px]' : 'max-h-[316px]'} 
          ${getOptionsWithClasses()}`}
            style={{
              marginTop: drop === 'up' ? `-${dropdownUpMargin}px` : '8px',
              ...(maxOptionWidth ? { maxWidth: maxOptionWidth } : {})
            }}
          >
            {showSearch && drop === 'down' ? (
              <div>
                <input
                  autoFocus
                  placeholder='Search'
                  className='w-full rounded-t -mt-1'
                  type='text'
                  onChange={(e) => onSearch && onSearch(e.target.value)}
                  style={{
                    boxShadow: 'none',
                    WebkitBoxShadow: 'none'
                  }}
                />
              </div>
            ) : null}
            {loading ? (
              <div className='pr-4 py-2 pb-2 pl-2 text-mid-gray cursor-default'>
                Loading...
              </div>
            ) : null}
            <div
              className={`w-full max-h-[308px] overflow-y-auto ${scrollStyleClass}`}
            >
              {!loading
                && options.map((option, index) => {
                  if (option.id === 'separator') {
                    return (
                      <div
                        key={`separator-${index}`}
                        className='border-b border-transparent-grey mx-1'
                      />
                    );
                  }
                  return (
                    <div
                      key={`option-${index}`}
                      onClick={() => {
                        onChange(option);
                        if (!multiselect) {
                          setState({ ...state, show: false });
                        }
                      }}
                      className={`modal-ignore-clickaway flex gap-1 justify-between text-black text-left hover:font-bold hover:text-white hover:bg-indigo-600 w-full bg-white pr-4 py-2 pb-2 pl-2 ${optionClasses} ${option.classes || ''}`}
                      data-cy={option.label}
                    >
                      <span
                        className={`truncate text-left ${optionTextClasses}`}
                      >
                        {showTitle && option.title?.length > 0 && (
                          <p className='text-sm sm:leading-6 italic m-0'>
                            {option.title}
                          </p>
                        )}
                        {option.label}
                        {showUnderlabel && option.underlabel?.length > 0 && (
                          <p className='m-0 leading-3 text-[10px] font-medium truncate'>
                            {option.underlabel}
                          </p>
                        )}
                      </span>
                      {option.checked ? (
                        <div className='float-right w-4 h-4 grow-0 shrink-0 inline-block text-indigo-600 my-auto'>
                          <CheckIcon />
                        </div>
                      ) : null}
                    </div>
                  );
                })}
            </div>
            {showSearch && drop === 'up' ? (
              <div>
                <input
                  autoFocus
                  placeholder='Search'
                  className='w-full rounded-b -mb-1'
                  type='text'
                  onChange={(e) => onSearch && onSearch(e.target.value)}
                  style={{
                    boxShadow: 'none',
                    WebkitBoxShadow: 'none'
                  }}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
);

export default Select;
