import React, { useState } from 'react';
import TopScoreBox from 'src/componentsAdvanced/TopScoreBox/TopScoreBox';
import { Base } from 'src/components';
import STYLE from 'src/constants/style';
import UserParticipationStatsModal from 'src/containers/Dash/TopScores/UserParticipationStatsModal';
import { useActivityTab } from 'src/containers/UserProfile/Activity/queries';
import { isFinite, round } from 'lodash';

const TopScores = ({ userId }) => {
  const [showModal, setShowModal] = useState(false);
  const {
    topScores: {
      data: topScores,
      isFetching: isFetchingTopScores,
      isError: isErrorTopScores
    }
  } = useActivityTab(userId);

  const isFetching = isFetchingTopScores;
  const isError = isErrorTopScores;
  const isReady = topScores && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const {
    avgScoreGiven,
    avgSentimentGiven,
    reviewedGivenCount,
    scoredGivenCount
  } = topScores;

  return (
    <Base classes={STYLE.CONTAINER_BLUE} loading={isFetchingTopScores}>
      {showModal && (
        <UserParticipationStatsModal
          userId={userId}
          close={() => setShowModal(false)}
        />
      )}
      <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-3'>
        <TopScoreBox
          loading={isFetchingTopScores}
          title='Avg. Score Given'
          value={scoredGivenCount ? avgScoreGiven : '-'}
          tooltip='Overall score given'
        />
        <TopScoreBox
          loading={isFetchingTopScores}
          title='Reviews Completed'
          value={reviewedGivenCount || '-'}
          tooltip='Reviews given'
        />
        <TopScoreBox
          loading={isFetchingTopScores}
          title='Avg. Sentiment Given'
          value={isFinite(avgSentimentGiven) ? round(avgSentimentGiven) : '-'}
          tooltip='(Negative) -100 to 100 (Positive)'
        />
        <TopScoreBox
          title='View participation stats'
          classes='cursor-pointer'
          tooltip='Participation Rate'
          onClick={() => setShowModal(true)}
        />
      </div>
    </Base>
  );
};

export default TopScores;
