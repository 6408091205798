import React, { useState, useContext } from 'react';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import WorkStoryTitle from 'src/assets/svg/workstory-title.svg';
import { Button, appUtils } from 'src/components';
import SignFlyout from 'src/pages/PerformanceBuilder/BuilderComponents/SignFlyout';
import CircledCheckmarkSVG from 'src/assets/svg/circled-checkmark.svg';
import PricingFlyout from 'src/pages/PerformanceBuilder/BuilderComponents/PricingFlyout';
import ShareFlyout from 'src/pages/PerformanceBuilder/BuilderComponents/ShareFlyout';
import commonUtils from 'common/commonUtils';
import { AlertModal } from 'src/componentsTailwind/index';
import ProfileCircleSVG from 'src/assets/svg/profileCircle.svg';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';

const BuilderHeader = () => {
  const [isSignFlyoutOpen, setIsSignFlyoutOpen] = useState(false);
  const [isCreateNewReportModalOpen, setIsCreateNewReportModalOpen] = useState(false);
  const [isPricingFlyoutOpen, setIsPricingFlyoutOpen] = useState(false);
  const [isShareFlyoutOpen, setIsShareFlyoutOpen] = useState(false);

  const {
    data: { reportId, companyid, page }
  } = useContext(PerformanceBuilderContext);

  const isDashboardPage = page === 'dashboard';
  const isViewPage = page === 'view';

  const isReportCreated = Boolean(reportId);
  const loggedUser = appUtils.getLoggedUser();
  const isLoggedIn = appUtils.isLoggedIn();

  const currentCompanyId = get(loggedUser, 'companyid') || companyid;

  const navigate = useNavigate();
  const routeToNewReport = (reload = false) => {
    const url = `/builder/new${currentCompanyId ? `?companyid=${currentCompanyId}` : ''}`;

    if (reload) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  return (
    <>
      <SignFlyout
        width={40}
        offset='60px'
        isOpen={isSignFlyoutOpen}
        setIsOpen={setIsSignFlyoutOpen}
        onOpen={() => {
          setIsCreateNewReportModalOpen(false);
          setIsShareFlyoutOpen(false);
          setIsPricingFlyoutOpen(false);
          const appDiv = document.getElementById('app');
          appDiv.inert = false;
        }}
      />
      <AlertModal
        onOpen={() => {
          setIsSignFlyoutOpen(false);
          setIsPricingFlyoutOpen(false);
          setIsShareFlyoutOpen(false);
          const appDiv = document.getElementById('app');
          appDiv.inert = false;
        }}
        isOpen={isCreateNewReportModalOpen}
        close={() => setIsCreateNewReportModalOpen(false)}
        onAction={() => {
          setIsCreateNewReportModalOpen(false);
          routeToNewReport(true);
        }}
        title='Create a New Report'
        content='Unless you would like to start fresh, make sure to complete your current review to not lose your work.'
      />
      <PricingFlyout
        width={80}
        offset='60px'
        isOpen={isPricingFlyoutOpen}
        setIsOpen={setIsPricingFlyoutOpen}
        onOpen={() => {
          setIsSignFlyoutOpen(false);
          setIsCreateNewReportModalOpen(false);
          setIsShareFlyoutOpen(false);
          const appDiv = document.getElementById('app');
          appDiv.inert = false;
        }}
      />
      <ShareFlyout
        width={40}
        offset='60px'
        isOpen={isShareFlyoutOpen}
        setIsOpen={setIsShareFlyoutOpen}
        SignUpCallback={() => {
          setIsSignFlyoutOpen(false);
          setIsShareFlyoutOpen(false);
          setIsPricingFlyoutOpen(true);
        }}
        onOpen={() => {
          setIsSignFlyoutOpen(false);
          setIsCreateNewReportModalOpen(false);
          setIsPricingFlyoutOpen(false);
          const appDiv = document.getElementById('app');
          appDiv.inert = false;
        }}
      />
      <div className='bg-black h-14 flex items-center justify-between w-full z-20 fixed'>
        <WorkStoryTitle className='w-44' />
        <div className='flex gap-4 items-center mx-6'>
          {isLoggedIn ? (
            <>
              {!isDashboardPage ? (
                <Button
                  variant='transparent'
                  classes='px-4 py-tiny rounded text-sm text-white border border-white'
                  onClick={() => navigate('/builder/dashboard')}
                >
                  Reviews
                </Button>
              ) : null}
            </>
          ) : (
            <>
              <Button
                variant='transparent'
                classes='px-4 py-tiny rounded text-sm text-white border border-white'
                disabled={isSignFlyoutOpen}
                onClick={() => setIsSignFlyoutOpen(true)}
              >
                Sign in
              </Button>
              <Button
                variant='transparent'
                classes='px-4 py-tiny rounded text-sm text-white border border-white'
                disabled={isPricingFlyoutOpen}
                onClick={() => setIsPricingFlyoutOpen(true)}
              >
                Pricing
              </Button>
            </>
          )}
          {isDashboardPage ? (
            <Button
              variant='transparent'
              classes='px-4 py-tiny rounded text-sm text-white border border-white'
              disabled={isCreateNewReportModalOpen}
              onClick={routeToNewReport}
            >
              + Create New
            </Button>
          ) : (
            <>
              {!isViewPage && isReportCreated ? (
                <>
                  <Button
                    variant='transparent'
                    classes='px-4 py-tiny rounded text-sm text-white border border-white'
                    disabled={isCreateNewReportModalOpen}
                    onClick={() => setIsCreateNewReportModalOpen(true)}
                  >
                    + Create New
                  </Button>
                  <Button
                    variant='purple'
                    classes='px-16 py-tiny rounded text-sm flex gap-2 items-center'
                    disabled={isShareFlyoutOpen}
                    onClick={() => setIsShareFlyoutOpen(true)}
                  >
                    <CircledCheckmarkSVG />
                    Complete Review
                  </Button>
                </>
              ) : null}
            </>
          )}
          {isLoggedIn ? (
            <div className='flex items-center gap-4'>
              <Button
                variant='transparent'
                classes='px-4 py-tiny rounded text-sm text-white border border-white'
                onClick={() => appUtils.logUserOut('/builder')}
              >
                Sign out
              </Button>
              <div className='flex gap-2 text-white items-center'>
                <ProfileCircleSVG className='h-full w-auto aspect-square' />
                {commonUtils.shortenFullName(loggedUser)}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default BuilderHeader;
