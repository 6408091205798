import React, { useState, useEffect } from 'react';
import { get, isEqual } from 'lodash';
import {
  Base,
  Select,
  Button,
  Checkbox,
  Placeholder,
  toast
} from 'src/components/';
import { useCompany, updateCompanyQuery } from 'src/queries/company';
import STYLE from 'src/constants/style';
import { useAccount, useAccountUpdate } from 'src/queries/account';

const options = [
  { id: 1, label: '2', depth: 2 },
  { id: 2, label: '3', depth: 3 },
  { id: 3, label: '4', depth: 4 },
  { id: 4, label: '5', depth: 5 }
];

const TreeSettings = () => {
  const {
    data: account,
    refetch: refetchAccount,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');
  const {
    data: company,
    refetch: refetchCompany,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const { mutateAsync: updateCompany, isLoading: isUpdatingCompany } = updateCompanyQuery();
  const { update, isLoading: isLoadingAccountUpdate } = useAccountUpdate(
    get(account, '_id')
  );

  const [depthOption, setDepthOption] = useState(options[0]);
  const [viewPermissions, setViewPermissions] = useState();

  useEffect(() => {
    if (account && account.settings) {
      const newOption = options.find(
        (option) => option.depth === parseInt(account.settings.visibleTreeDepth, 10)
      );
      setDepthOption(newOption);
    }
  }, [account]);

  useEffect(() => {
    if (company.settings.viewPermissions) {
      setViewPermissions(company.settings.viewPermissions);
    }
  }, [company]);

  const isFetching = isFetchingAccount || isFetchingCompany;
  const isError = isErrorAccount || isErrorCompany;
  const isReady = company
    && account
    && !isFetching
    && !isError
    && !isLoadingAccountUpdate
    && !isUpdatingCompany;
  if (!isReady) return <Placeholder />;

  const onChangeDepthOption = (newOption) => setDepthOption(newOption);
  const onChangeViewPermission = (role, value) => setViewPermissions({
    ...viewPermissions,
    [role]: {
      ...viewPermissions[role],
      canViewOrganizationPage: value
    }
  });

  const updateFn = async () => {
    try {
      if (account.settings.visibleTreeDepth !== depthOption.depth) {
        await update({
          data: {
            settings: { visibleTreeDepth: depthOption.depth }
          }
        });
        refetchAccount();
      }

      if (!isEqual(company.settings.viewPermissions, viewPermissions)) {
        await updateCompany({
          settings: {
            ...company.settings,
            viewPermissions
          }
        });
        refetchCompany();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const isSaveDisabled = account
    && account.settings
    && account.settings.visibleTreeDepth === depthOption.depth
    && isEqual(company.settings.viewPermissions, viewPermissions);

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <h5 className='mb-4'>Organization Tree</h5>
      <div className='mb-1'>
        <p className='inline-block mt-1'>Visible depth on Organization page</p>
        <div className='mb-2 w-32 ml-3 align-top inline-block'>
          <Select
            options={options}
            title={depthOption.label}
            onChange={(option) => {
              onChangeDepthOption(option);
            }}
          />
        </div>
        <div className='mb-2'>
          <Checkbox
            label='Users with basic access can view Organization page'
            value={get(viewPermissions, 'Basic.canViewOrganizationPage', false)}
            name='basicUserCanViewOrganization'
            onChange={(v) => onChangeViewPermission('Basic', v)}
          />
          <div className='mb-2' />
          <Checkbox
            label='Users with manager access can view Organization page'
            value={get(
              viewPermissions,
              'Manager.canViewOrganizationPage',
              false
            )}
            name='managerUserCanViewOrganization'
            onChange={(v) => onChangeViewPermission('Manager', v)}
          />
        </div>
        <div className='mb-1 mt-2 block'>
          <Button disabled={isSaveDisabled} variant='yellow' onClick={updateFn}>
            Update
          </Button>
        </div>
      </div>
    </Base>
  );
};

export default TreeSettings;
