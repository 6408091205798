/* eslint-disable react/no-array-index-key */
import { h } from 'preact';
import { Base } from 'src/components/index';
import STYLE from 'src/constants/style';
import FileSVG from 'src/assets/svg/file.svg';
import CircledIcon from 'src/components/CircledIcon/CircledIcon';

const ReportPDFLink = ({ pdfLink }) => {
  return (
    <Base classes={`${STYLE.CONTAINER_WHITE_MARGINLESS} !p-4 mt-3`} relative>
      <CircledIcon svg={<FileSVG className='w-4 h-4 text-purple' />} />
      <p className='text-xl text-black font-bold mb-0 mr-8'>
        Performance Review
      </p>
      <p className='text-md font-normal mt-2 mb-3 leading-5 text-bluish-gray'>
        The latest performance review available for this team member.
      </p>
      <div className='border-b border-mid-gray mt-3 mb-4 transform -translate-x-[16px] w-[calc(100%+32px)]' />
      <a
        className='text-purple underline underline-offset-2 mb-2'
        href={pdfLink.link}
        target='_blank'
        rel='noreferrer'
      >
        {pdfLink.label}
      </a>
    </Base>
  );
};

export default ReportPDFLink;
