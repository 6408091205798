import React from 'react';
import { Select } from 'src/components/';

const defaultOption = {
  id: -6 * 60,
  label: '(GMT -6:00) Central Time (US & Canada), Mexico City',
  timezone: 'America/Mexico_City'
};

// https://www.ibm.com/docs/en/cloudpakw3700/2.3.0.0?topic=SS6PD2_2.3.0/doc/psapsys_restapi/time_zone_list.html
export const timezoneOptions = [
  {
    id: -12 * 60,
    label: '(GMT -12:00) Eniwetok, Kwajalein',
    timezone: 'Etc/GMT+12'
  },
  {
    id: -11 * 60,
    label: '(GMT -11:00) Midway Island, Samoa',
    timezone: 'Etc/GMT+11'
  },
  { id: -10 * 60, label: '(GMT -10:00) Hawaii', timezone: 'US/Hawaii' },
  {
    id: -9.5 * 60,
    label: '(GMT -9:30) Taiohae',
    timezone: 'Pacific/Marquesas'
  },
  { id: -9 * 60, label: '(GMT -9:00) Alaska', timezone: 'US/Alaska' },
  {
    id: -8 * 60,
    label: '(GMT -8:00) Pacific Time (US & Canada)',
    timezone: 'America/Los_Angeles'
  },
  {
    id: -7 * 60,
    label: '(GMT -7:00) Mountain Time (US & Canada)',
    timezone: 'America/Denver'
  },
  defaultOption,
  {
    id: -5 * 60,
    label: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
    timezone: 'America/New_York'
  },
  { id: -4.5 * 60, label: '(GMT -4:30) Caracas', timezone: 'America/Caracas' },
  {
    id: -4 * 60,
    label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
    timezone: 'America/Halifax'
  },
  {
    id: -3.5 * 60,
    label: '(GMT -3:30) Newfoundland',
    timezone: 'Canada/Newfoundland'
  },
  {
    id: -3 * 60,
    label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown',
    timezone: 'America/Mendoza'
  },
  { id: -2 * 60, label: '(GMT -2:00) Mid-Atlantic', timezone: 'Etc/GMT+2' },
  {
    id: -1 * 60,
    label: '(GMT -1:00) Azores, Cape Verde Islands',
    timezone: 'Etc/GMT+1'
  },
  {
    id: 0 * 60,
    label: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
    timezone: 'UTC'
  },
  {
    id: 1 * 60,
    label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris',
    timezone: 'Europe/Paris'
  },
  {
    id: 2 * 60,
    label: '(GMT +2:00) Kaliningrad, South Africa',
    timezone: 'Europe/Athens'
  },
  {
    id: 3 * 60,
    label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
    timezone: 'Etc/GMT-3'
  },
  { id: 3.5 * 60, label: '(GMT +3:30) Tehran', timezone: 'Asia/Tehran' },
  {
    id: 4 * 60,
    label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
    timezone: 'Etc/GMT-4'
  },
  { id: 4.5 * 60, label: '(GMT +4:30) Kabul', timezone: 'Asia/Kabul' },
  {
    id: 5 * 60,
    label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
    timezone: 'Etc/GMT-5'
  },
  {
    id: 5.5 * 60,
    label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
    timezone: 'Asia/Calcutta'
  },
  {
    id: 5.75 * 60,
    label: '(GMT +5:45) Kathmandu, Pokhara',
    timezone: 'Asia/Kathmandu'
  },
  {
    id: 6 * 60,
    label: '(GMT +6:00) Almaty, Dhaka, Colombo',
    timezone: 'Etc/GMT-6'
  },
  {
    id: 6.5 * 60,
    label: '(GMT +6:30) Yangon, Mandalay',
    timezone: 'Asia/Rangoon'
  },
  {
    id: 7 * 60,
    label: '(GMT +7:00) Bangkok, Hanoi, Jakarta',
    timezone: 'Etc/GMT-7'
  },
  {
    id: 8 * 60,
    label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
    timezone: 'Etc/GMT-8'
  },
  { id: 8.75 * 60, label: '(GMT +8:45) Eucla', timezone: 'Australia/Eucla' },
  {
    id: 9 * 60,
    label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
    timezone: 'Asia/Tokyo'
  },
  {
    id: 9.5 * 60,
    label: '(GMT +9:30) Adelaide, Darwin',
    timezone: 'Australia/Adelaide'
  },
  {
    id: 10 * 60,
    label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
    timezone: 'Australia/Brisbane'
  },
  {
    id: 10.5 * 60,
    label: '(GMT +10:30) Lord Howe Island',
    timezone: 'Australia/Lord_Howe'
  },
  {
    id: 11 * 60,
    label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
    timezone: 'Etc/GMT-11'
  },
  {
    id: 11.5 * 60,
    label: '(GMT +11:30) Norfolk Island',
    timezone: 'Pacific/Norfolk'
  },
  {
    id: 12 * 60,
    label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
    timezone: 'Pacific/Auckland'
  },
  {
    id: 12.75 * 60,
    label: '(GMT +12:45) Chatham Islands',
    timezone: 'Pacific/Chatham'
  },
  {
    id: 13 * 60,
    label: '(GMT +13:00) Apia, Nukualofa',
    timezone: 'Etc/GMT-13'
  },
  {
    id: 14 * 60,
    label: '(GMT +14:00) Line Islands, Tokelau',
    timezone: 'Etc/GMT-14'
  }
];

const SelectTimezone = ({ value, onChange }) => {
  let selectedOption = defaultOption;
  if (value !== undefined && value !== null && value !== '') {
    selectedOption = timezoneOptions.find(
      (option) => parseInt(option.id, 10) === parseInt(value, 10)
    ) || defaultOption;
  }

  return (
    <div className='tooltip w-19rem'>
      <span className='tooltip-text p-1 bg-black text-white max-w-19rem -mt-12'>
        Your timezone determines when you receive reviews
      </span>
      <span className='inline-block w-full mt-1 mr-5 text-center'>
        Timezone
      </span>
      <div className='w-full'>
        <Select
          options={timezoneOptions}
          title={selectedOption.label}
          onChange={(option) => {
            onChange(option.id);
          }}
          classes='w-60 mx-auto'
        />
      </div>
    </div>
  );
};

export default SelectTimezone;
