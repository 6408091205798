import React, { useContext } from 'react';
import { Base } from 'src/components';
import { isEmpty } from 'lodash';
import ReviewInfo from 'src/pages/PerformanceBuilder/BuilderComponents/ReviewInfo';
import ReviewTemplate from 'src/pages/PerformanceBuilder/BuilderComponents/ReviewTemplate';
import PerformanceCategories from 'src/pages/PerformanceBuilder/BuilderComponents/PerformanceCategories';
import PerformanceSummary from 'src/pages/PerformanceBuilder/BuilderComponents/PerformanceSummary';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import commonUtils from 'common/commonUtils';

const Builder = () => {
  const {
    data: {
      form, reportId, editToken, companyMode, prevDataRef
    }
  } = useContext(PerformanceBuilderContext);

  const prevData = prevDataRef.current;
  const { watch } = form;
  const currData = watch();

  let areReviewerAndRevieweeSet = null;
  let areReviewerOrRevieweeChanged = null;
  if (companyMode === 'company') {
    areReviewerAndRevieweeSet = !isEmpty(currData.reviewerId) && !isEmpty(currData.revieweeId);
    areReviewerOrRevieweeChanged = !commonUtils.isSame(
      {
        reviewerId: prevData.reviewerId,
        revieweeId: prevData.revieweeId
      },
      {
        reviewerId: currData.reviewerId,
        revieweeId: currData.revieweeId
      }
    );
  } else {
    areReviewerAndRevieweeSet = Boolean(reportId || editToken);
    areReviewerOrRevieweeChanged = false;
  }

  const { industry, role } = prevData;
  const areIndustryAndRoleSet = !isEmpty(industry.id) && !isEmpty(role.id);
  const areIndustryOrRoleChanged = !commonUtils.isSame(
    {
      industryId: industry.id,
      roleId: role.id
    },
    {
      industryId: currData.industry.id,
      roleId: currData.role.id
    }
  );
  return (
    <Base classes='w-1/2 border-r border-black h-[calc(100vh-3.5rem)] max-h-32 overflow-y-scroll overflow-x-hidden px-8 pt-10'>
      <ReviewInfo />
      {areReviewerAndRevieweeSet && !areReviewerOrRevieweeChanged ? (
        <>
          <ReviewTemplate />
          {areIndustryAndRoleSet && !areIndustryOrRoleChanged ? (
            <>
              <PerformanceCategories />
              <PerformanceSummary />
            </>
          ) : null}
        </>
      ) : null}
    </Base>
  );
};

export default Builder;
