import React, { cloneElement, useEffect, useState } from 'react';
import {
  Button, Base, Radio, appUtils
} from 'src/components';
import STYLE from 'src/constants/style';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const RequestFeedbackContainer = (props) => {
  const {
    children,
    parentProps: { type }
  } = props;
  const [searchParams] = useSearchParams();
  const revieweeId = searchParams.get('revieweeId');
  const { '*': currentRoute } = useParams();

  const [selectionValue, setSelectionValue] = useState();

  const opts = [
    {
      title: 'Request a feedback comment',
      value: 'feedback',
      description:
        'Ask others for general feedback or prompt them to give you specific feedback'
    },
    {
      title: 'Request a review',
      value: 'review',
      description: 'Ask others to evaluate you by a specific category'
    }
  ];

  const navigate = useNavigate();
  useEffect(() => {
    const searchQ = revieweeId ? `?revieweeId=${revieweeId}` : '';

    if (selectionValue) {
      navigate(`/dashboard/request-feedback/${selectionValue}${searchQ}`);
    }
  }, [selectionValue, revieweeId]);

  useEffect(() => {
    if (type || currentRoute) {
      setSelectionValue(type ?? currentRoute);
    }
  }, [type, currentRoute]);

  const navigateBack = () => {
    const loggedUserId = appUtils.getLoggedUserId();
    const isMyProfile = revieweeId === loggedUserId;

    if (!revieweeId || isMyProfile) {
      return navigate('/dashboard/home');
    }

    return navigate(appUtils.getDashRoute(revieweeId));
  };

  const shouldDefaultToFeedback = !selectionValue && !type && !currentRoute;
  return (
    <Base classes={STYLE.STANDARD}>
      <div className='min-h-80 p-5 bg-white'>
        <div className='flex justify-between items-center mb-6'>
          <h1 className='font-bold text-xxl m-0 p-0 text-center'>
            Request Feedback
          </h1>
          <Button
            type='button'
            variant='empty-with-border'
            onClick={navigateBack}
          >
            Close
          </Button>
        </div>

        {opts.map((option, index) => (
          <div key={index} className='font-semibold'>
            <Radio
              name='option'
              value={selectionValue}
              title={option.title}
              checked={
                selectionValue === option.value
                || (option.value === 'feedback' && shouldDefaultToFeedback)
              }
              onChange={() => setSelectionValue(option.value)}
            />
            <div>
              <p className='text-dark-grey ml-9'>{option.description}</p>
            </div>
          </div>
        ))}

        <div className='mt-16'>
          {children
            ? cloneElement(children, {
              type: selectionValue
            })
            : null}
        </div>
      </div>
    </Base>
  );
};

export default RequestFeedbackContainer;
